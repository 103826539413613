import MergeControls from '../components/MergeControls'

export default {
  componentType: 'card',
  endpoint: '/merge_place',
  fetchInitialData: false,
  objectType: 'merge_place',
  url: '/merge-place',
  getUrl: data => '/merge-place',
  title: 'merge-place',
  customControls: MergeControls,
  tabs: [
    {
      title: 'empty',
      type: 'basis',
      children: [
        {
          title: 'merge.keep',
          icon: 'icons/location-icon.svg',
          components: [
            {name: 'Select', relationship: 'keep', placeholder: 'title.placeholder', kind: 'place', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'merge.delete',
          icon: 'icons/location-icon.svg',
          components: [
            {name: 'Select', relationship: 'delete', placeholder: 'title.placeholder', kind: 'place', style: {width: '100%'}}
          ],
          side: 'right'
        }
      ]
    }
  ]
}
