import React from 'react'
import SelectComponent from '../components/SelectComponent'
import { editField } from '../redux/actions/editingCard'
import jsonPointer from 'json-pointer'
import addData from '../utils/addData'

const Select = (props) => {
  const {label, placeholder, style} = props
  const {dispatch, data, tab, config} = props
  let {
    disabled,
    disableFilled,
    relationship, 
    kind, 
    endpoint, 
    endpointParams, 
    allowClear, 
    getOptionTitle, 
    getOptionTooltip,
    createNew,
    createObjectName
  } = props

  // return if there is no currentData yet
  if (!tab || !tab.currentData) return null

  // if type is not set it's same as ralationship field
  if (kind == null) kind = relationship

  // construct pointer to the value
  const objectPointer = '/' + config.objectName + '/' + data.id + '/relationships/' + relationship + '/data'

  const getValue = (dataType = 'currentData') => {
    if (!jsonPointer.has(tab[dataType], objectPointer)) return null
    return jsonPointer.get(tab[dataType], objectPointer)
  }

  const setValue = newValue => {
    const toSet = {...newValue, type: kind}
    dispatch(editField(tab.id, objectPointer, toSet))
  }  
  
  return <div className="CommonField" style={style}>
    <SelectComponent
      disabled={
        disabled || 
        Boolean(disableFilled && getValue('initialData'))
      }
      allowClear={allowClear}
      kind={kind}
      relationship={relationship}
      endpoint={endpoint || '/' + kind}
      endpointParams={endpointParams}
      getOptionTitle={getOptionTitle}
      getOptionTooltip={getOptionTooltip}
      value={getValue()}
      label={label}
      placeholder={placeholder}
      onChange={setValue}
      createNew={createNew}
      createObjectName={createObjectName}
    />
  </div>
}

export default addData(Select)
