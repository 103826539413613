export default {
  componentType: 'card',
  endpoint: '/research(/:guid)',
  objectName: 'research',
  url: '/research/:guid',
  getUrl: data => '/research/' + data.id,
  title: 'research',
  titleCreate: 'research.create',
  getValue: data => data.name,
  tabs: [
    {
      title: 'tab.basis',
      type: 'basis',
      children: [
        {
          title: 'researchSingular',
          icon: 'icons/comment.svg',
          required: true,
          components: [
            {name: 'nobr', fieldName: 'name', label: 'research.name', placeholder: 'research.name', style: {width: '100%'}},
            {name: 'nobr', fieldName: 'nameEn', label: 'research.name-en', placeholder: 'research.name-en', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'books.website-status',
          icon: 'icons/website-icon.svg',
          components: [
            {name: 'Switch', relationship: 'publishedOnWeb', kind: 'published_on_web', style: {float: 'none'}}
          ],
          side: 'right'
        },
        {
          title: 'research.slug',
          icon: 'icons/storage.svg',
          components: [
            {name: 'input', fieldName: 'slug', disabled: true, style: {width: '100%'}}
          ],
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.description-ru',
      type: 'description-ru',
      children: [
        {
          title: 'tab.description-ru',
          components: {name: 'CKEditor', fieldName: 'descriptionCkeditorRu', placeholder: 'research.description.placeholder', style: {width: '80%'}},
          side: 'both'
        }
      ]
    },
    {
      title: 'tab.description-en',
      type: 'description-en',
      children: [
        {
          title: 'tab.description-en',
          components: {name: 'CKEditor', fieldName: 'descriptionCkeditorEn', placeholder: 'research.description.placeholder', style: {width: '80%'}},
          side: 'both'
        }
      ]
    },
    {
      title: 'tab.related-people',
      type: 'participants',
      children: [
        {
          title: 'individuals',
          icon: 'icons/individual.svg',
          components: {name: 'Relation', pointer: '/relPersonResearch', filter: {'/person/type': 'individual'}, components: [
            {name: 'Select', relationship: 'person', endpointParams: {'filter[type]': 'individual'}, placeholder: 'individuals.placeholder', createNew: true, createObjectName: 'individual'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'research,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'left'
        },
        {
          title: 'groups',
          icon: 'icons/group.svg',
          components: {name: 'Relation', pointer: '/relPersonResearch', filter: {'/person/type': 'group'}, components: [
            {name: 'Select', relationship: 'person', endpointParams: {'filter[type]': 'group'}, placeholder: 'individuals.placeholder', createNew: true, createObjectName: 'group'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'research,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.library',
      type: 'library',
      children: [
        {
          title: 'events.field.books',
          icon: 'icons/book.svg',
          components: {name: 'Relation', pointer: '/relDocumentResearch', filter: {'/document/type': 'book'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'book', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty'}
          ]},
          side: 'left'
        },
        {
          title: 'events.field.articles',
          icon: 'icons/article.svg',
          components: {name: 'Relation', pointer: '/relDocumentResearch', filter: {'/document/type': 'article'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'article', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty'}
          ]},
          side: 'left'
        }
      ]
    },
    {
      title: 'tab.archive',
      type: 'archive',
      children: [
        {
          title: 'events.field.documents',
          icon: 'icons/documents.svg',
          components: {name: 'Relation', pointer: '/relDocumentResearch', filter: {'/document/type': 'archiveDocument'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'archive_document', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty'}
          ]},
          side: 'left'
        },
        {
          title: 'events.field.ephemerises',
          icon: 'icons/efemerida.svg',
          components: {name: 'Relation', pointer: '/relDocumentResearch', filter: {'/document/type': 'ephemeris'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'ephemeris', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty'}
          ]},
          side: 'right'
        },
        {
          title: 'events.field.images',
          icon: 'icons/photo.svg',
          components: {name: 'Relation', pointer: '/relDocumentResearch', filter: {'/document/type': 'image'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'image', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty'}
          ]},
          side: 'left'
        },
        {
          title: 'things',
          icon: 'icons/storage.svg',
          components: {name: 'Relation', pointer: '/relDocumentResearch', filter: {'/document/type': 'thing'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'thing', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty'}
          ]},
          side: 'left'
        }
      ]
    },
    {
      title: 'tab.media',
      type: 'media',
      children: [
        {
          title: 'events.field.audios',
          icon: 'icons/audio.svg',
          components: {name: 'Relation', pointer: '/relDocumentResearch', filter: {'/document/type': 'audio'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'audio', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty'}
          ]},
          side: 'left'
        },
        {
          title: 'events.field.videos',
          icon: 'icons/video.svg',
          components: {name: 'Relation', pointer: '/relDocumentResearch', filter: {'/document/type': 'video'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'video', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty'}
          ]},
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.files',
      type: 'files',
      children: [
        {
          title: 'files',
          wrapper: false,
          icon: 'icons/media-archive.svg',
          components: [
            {name: 'Files', relationship: 'files'}
          ],
          side: 'both'
        }
      ]
    }
  ]
}
