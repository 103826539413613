import React, { Component } from 'react'
// import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import SideBarMenu from './SideBarMenu'
import UserMenu from '../components/UserMenu'
import ApplicationTabs from '../components/ApplicationTabsComponent'
import Login from '../components/Login'
import TabLayout from './TabLayout'
import SearchBar from './SearchBar'

class MainLayout extends Component {

  render() {
    return (
      <div className="MainLayout">
        <Login />
        <div className="MainLayout__header">
          <ApplicationTabs />
        </div>
        <div className="MainLayout__body">
          <div className="MainLayout__sidebar">
            <div className="MainLayout__sidebar-top">
              <SideBarMenu />
            </div>
            <div className="MainLayout__sidebar-bottom">
              <UserMenu />
            </div>
          </div>
          <div className="MainLayout__content">
            {this.props.tabs.map(tab => {
              // if (!tab.active) return null
              return <div
                className="TabLayout__content-tab"
                key={tab.id}
                style={{ display: tab.active ? 'block' : 'none' }}
              >
                <SearchBar tabId={tab.id} />
                <TabLayout key={tab.id} tabId={tab.id} />
              </div>
            })}
          </div>
        </div>
      </div>
    )
  }

}

function mapStateToProps(state, params) {
  return { tabs: state.tabs }
}

export default connect(mapStateToProps)(MainLayout)
