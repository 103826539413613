import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import Label from '../components/Label'
import Radio from 'antd/lib/radio'
import { editField } from '../redux/actions/editingCard'
import addData from '../utils/addData'
const RadioButton = Radio.Button
const RadioGroup = Radio.Group

class NameDefault extends PureComponent {

  render () {
    const { data, label, intl } = this.props
    return <div className="NameDefault">
      <Label>{!label ? null : intl.formatMessage({id: label})}</Label>
      <RadioGroup value={data.defaultNameLang} onChange={this.onLangChange.bind(this)}>
        <RadioButton value="ru">{intl.formatMessage({id: 'groups.basis-title.ru'})}</RadioButton>
        <RadioButton value="en">{intl.formatMessage({id: 'groups.basis-title.en'})}</RadioButton>
      </RadioGroup>
    </div>
  }

  onLangChange (e) {
    const { data, fieldName, config, tab, dispatch } = this.props
    const newValue = e.target.value
    const pointer = '/' + config.objectName + '/' + data.id + '/attributes/' + fieldName
    dispatch(editField(tab.id, pointer, newValue))
  }

}

export default addData(injectIntl(NameDefault))
