import React from 'react'
import LabelWrapper from '../components/LabelWrapper'
import Radio from 'antd/lib/radio'
const RadioButton = Radio.Button
const RadioGroup = Radio.Group

const renderOption = (option) => {
  return <RadioButton key={option.id || option.value + option.name} value={option.value || option.id}>
    {option.name}
  </RadioButton>
}

const SwitchStyled = ({label, style, value, options, onChange}) => {
  return <LabelWrapper label={label} style={style} >
    <RadioGroup className={'SwitchStyled'} value={value} onChange={onChange}>
      {(options == null || options[0] == null) ? null : options.map(renderOption)}
    </RadioGroup>
  </LabelWrapper>
}

export default SwitchStyled
