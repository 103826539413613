import individualsTable from './individualsTable'
import individualsCard from './individualsCard'
import eventsTable from './eventsTable'
import eventsCard from './eventsCard'
import groupsTable from './groupsTable'
import groupsCard from './groupsCard'
import searchTable from './searchTable'
import booksTable from './booksTable'
import booksCard from './booksCard'
import booksDraftTable from './booksDraftTable'
import booksDraftCard from './booksDraftCard'
import periodicalsTable from './periodicalsTable'
import periodicalsCard from './periodicalsCard'
import issuesTable from './issuesTable'
import issuesCard from './issuesCard'
import issuesDraftTable from './issuesDraftTable'
import issuesDraftCard from './issuesDraftCard'
import organisationsTable from './organisationsTable'
import organisationsCard from './organisationsCard'
import documentsTable from './documentsTable'
import documentsCard from './documentsCard'
import ephemerisTable from './ephemerisTable'
import ephemerisCard from './ephemerisCard'
import thingsTable from './thingsTable'
import thingsCard from './thingsCard'
import videosTable from './videosTable'
import videosCard from './videosCard'
import audiosTable from './audiosTable'
import audiosCard from './audiosCard'
import imagesTable from './imagesTable'
import imagesCard from './imagesCard'
import articlesTable from './articlesTable'
import articlesCard from './articlesCard'
import mediaItemTable from './mediaItemTable'
import mediaItemCard from './mediaItemCard'
import bookSeriesTable from './bookSeriesTable'
import bookSeriesCard from './bookSeriesCard'
import keywordsTable from './keywordsTable'
import keywordsCard from './keywordsCard'
import placesTable from './placesTable'
import placesCard from './placesCard'
import collectionsTable from './collectionsTable'
import collectionsCard from './collectionsCard'
import usersTable from './usersTable'
import usersCard from './usersCard'
import mergePerson from './mergePerson'
import mergeOrganisation from './mergeOrganisation'
import mergePlace from './mergePlace'
import mergeEvent from './mergeEvent'
import researchTable from './researchTable'
import researchCard from './researchCard'
import chronicleTable from './chronicleTable'
import chronicleCard from './chronicleCard'
import ltoTable from './ltoTable'
import ltoCard from './ltoCard'
import storageLocationsTable from './storageLocationsTable'
import storageLocationsCard from './storageLocationsCard'
import kindTable from './kindTable.js'
import kindCard from './kindCard.js'
import institutionsTable from './institutionsTable'
import institutionsCard from './institutionsCard'
import branchesTable from './branchesTable'
import branchesCard from './branchesCard'
import actionFeedTable from './actionFeedTable'


const pages = {
  individualsTable,
  individualsCard,
  eventsTable,
  eventsCard,
  groupsTable,
  groupsCard,
  searchTable,
  booksDraftTable,
  booksDraftCard,
  booksTable,
  booksCard,
  periodicalsTable,
  periodicalsCard,
  issuesTable,
  issuesCard,
  issuesDraftTable,
  issuesDraftCard,
  organisationsTable,
  organisationsCard,
  documentsTable,
  documentsCard,
  ephemerisTable,
  ephemerisCard,
  thingsTable,
  thingsCard,
  videosTable,
  videosCard,
  audiosTable,
  audiosCard,
  imagesTable,
  imagesCard,
  articlesTable,
  articlesCard,
  mediaItemTable,
  mediaItemCard,
  bookSeriesTable,
  bookSeriesCard,
  keywordsTable,
  keywordsCard,
  placesTable,
  placesCard,
  collectionsTable,
  collectionsCard,
  usersTable,
  usersCard,
  mergePerson,
  mergeOrganisation,
  mergePlace,
  mergeEvent,
  researchTable,
  researchCard,
  chronicleTable,
  chronicleCard,
  ltoTable,
  ltoCard,
  storageLocationsTable,
  storageLocationsCard,
  kindTable,
  kindCard,
  institutionsTable,
  institutionsCard,
  branchesTable,
  branchesCard,
  actionFeedTable
}

const menu = [
  {
    title: 'search'
  },
  {
    title: 'all-records',
    link: {
      url: '/'
    }
  },
  {
    title: 'archive',
    children: [
      {
        title: 'documents',
        page: documentsTable
      },
      {
        title: 'images',
        page: imagesTable
      },
      {
        title: 'ephemeris',
        page: ephemerisTable
      },
      {
        title: 'things',
        page: thingsTable
      }
    ]
  },
  {
    title: 'media-archive',
    children: [
      {
        title: 'media-audio',
        page: audiosTable
      },
      {
        title: 'media-video',
        page: videosTable
      },
      {
        title: 'media-carriers',
        page: mediaItemTable
      },
      {
        title: 'lto.tapes',
        page: ltoTable
      }
    ]
  },
  {
    title: 'library',
    children: [
      {
        title: 'books-draft',
        page: booksDraftTable
      },
      {
        title: 'books-draft-issues',
        page: issuesDraftTable
      },
      {
        title: 'books',
        page: booksTable
      },
      {
        title: 'periodicals',
        page: periodicalsTable
      },
      {
        title: 'periodical-issues',
        page: issuesTable
      },
      {
        title: 'articles',
        page: articlesTable
      }
    ]
  },
  {
    title: 'persons',
    children: [
      {
        title: 'individuals',
        page: individualsTable
      },
      {
        title: 'groups',
        page: groupsTable
      }
    ]
  },
  {
    title: 'events',
    page: eventsTable
  },
  {
    title: 'organisations',
    page: organisationsTable
  },
  {
    title: 'research',
    page: researchTable
  },
  {
    title: 'chronicles',
    page: chronicleTable
  },
  {
    title: 'references',
    children: [
      {
        title: 'keywords',
        page: keywordsTable
      },
      {
        title: 'places',
        page: placesTable
      },
      {
        title: 'institutions',
        page: institutionsTable
      },
      {
        title: 'branches',
        page: branchesTable
      },
      {
        title: 'foundations',
        page: collectionsTable
      },
      {
        title: 'storage-locations',
        page: storageLocationsTable
      },
      {
        title: 'kind.editing',
        page: kindTable
      },
      {
        title: 'users',
        page: usersTable
      },
      {
        title: 'book-series',
        page: bookSeriesTable
      }
    ]
  },
  {
    title: 'instruments',
    children: [
      {
        title: 'merge-person',
        page: mergePerson
      },
      {
        title: 'merge-event',
        page: mergeEvent
      },
      {
        title: 'merge-organisation',
        page: mergeOrganisation
      },
      {
        title: 'merge-place',
        page: mergePlace
      }
    ]
  },
  {
    title: 'action-feed',
    page: actionFeedTable
  },
  {
    title: 'links-page',
    children: [
      { title: 'links-page-raan-site', externalLink: { url: 'https://russianartarchive.net' } },
      { title: 'links-page-howto', externalLink: { url: 'https://app.raan.garagemca.org/howto' } },
      { title: 'links-page-actionfeed', externalLink: { url: 'https://app.raan.garagemca.org/actionfeed' } },
      { title: 'links-page-stats', externalLink: { url: 'https://docs.google.com/spreadsheets/d/1FxVhHIx93_Vb6m0kZkm7xrV4nBctKWgeI0p3sWUpHf0' } }
    ]
  }
]

export default {
  pages,
  menu
}
