import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import build from 'redux-object'
import NewTabLink from './NewTabLink'
import { injectIntl } from 'react-intl'
import Select from '../fieldsStyled/SelectStyled'
import Tooltip from 'antd/lib/tooltip'
import 'antd/dist/antd.css'
import getCardURLByType from '../utils/getCardURLByType'
import unionBy from 'lodash/unionBy'
import { get } from '../redux/actions/apiActions'
import debounce from 'lodash/debounce'
import generateTooltip from '../utils/generateTooltip'
import CreateCard from '../components/CreateCard'
import getActiveTab from '../utils/getActiveTab'
import Spin from 'antd/lib/spin'
import Icon from 'antd/lib/icon'
import removeLazyLoading from '../utils/removeLazyLoading'

const Option = Select.Option

const renderOption = (option, getOptionTitle, getOptionTooltip) => {
  const tooltip = generateTooltip(option, getOptionTooltip)
  const title = typeof getOptionTitle === 'function' ? getOptionTitle(option) : option.name

  const cardUrl = getCardURLByType(option)
  const tooltipContent = cardUrl
    ? <NewTabLink url={cardUrl}>{tooltip}</NewTabLink>
    : tooltip

  return <Option key={option.id} value={option.id} title={title}>
    <Tooltip placement="left" title={tooltipContent}>
      <span style={{opacity: 0}}>{title}</span>
      <span className="FullTooltipFix" style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden', padding: '7px'}}>{title}</span>
    </Tooltip>
  </Option>
}

class SelectComponent extends PureComponent {

  constructor () {
    super()
    this.handleSearch = debounce(this.handleSearchRaw, 300)
    this.state = {
      showCreateModal: false
    }
    this.closeModalHandler = this.closeModalHandler.bind(this)
    this.handleCreanteNewItem = this.handleCreanteNewItem.bind(this)
  }

  render () {
    let {options} = this.props
    const {intl} = this.props
    const {
      value, 
      label, 
      placeholder, 
      allowClear, 
      style, 
      mode, 
      getOptionTitle, 
      getOptionTooltip, 
      showError, 
      loading
    } = this.props
    const { showCreateModal } = this.state
    const valueToPass = value == null
      ? undefined
      : Array.isArray(value)
        ? value.map(v => v.id)
        : value.id

    let loadingProps = {}
    if (loading) {
      loadingProps.notFoundContent = (
        <Spin indicator={<Icon type="loading" style={{ fontSize: 24, color: '#30BFB3' }} spin />} />
      )
    }

    return (
      <div style={{display: 'contents'}}>
        <Select
          style={style}
          showError={showError}
          label={label}
          allowClear={allowClear}
          mode={mode}
          disabled={this.props.disabled}
          value={valueToPass}
          placeholder={(placeholder && intl.formatMessage({id: placeholder})) || ' '}
          onFocus={this.handleFocus.bind(this)}
          onSearch={this.handleSearch.bind(this)}
          onChange={this.handleChange.bind(this)}
          {...loadingProps}
        >
          {(this.props.createNew && (!loading || options[0])) && (
            <Option key={'0'} value={'create-new-record'}>
              {intl.formatMessage({id: 'create-new-record'})}
            </Option>
          )}
          {options && options[0] && options.map(option => renderOption(option, getOptionTitle, getOptionTooltip))}
        </Select>
        {(this.props.createNew && showCreateModal) && (
          <CreateCard 
            type={this.props.createObjectName}
            tabId={this.props.activeTabId}
            hideButton
            openModal
            closeModalHandler={this.closeModalHandler}
            createHandler={this.handleCreanteNewItem}
          />
        )}
      </div>
    )
  }

  handleCreanteNewItem (response) {
    this.handleChange(response.data.id)
  }

  handleFocus () {
    if (this.props.onlySearchable) return
    this.props.dispatch(get(this.props.endpoint, this.props.endpointParams))
  }

  handleSearchRaw (value) {
    if (value == null || value.trim() === '') return
    const params = {...this.props.endpointParams, 'filter[name][infix]': value}
    this.props.dispatch(get(this.props.endpoint, params))
  }

  handleChange (value) {
    if (value === 'create-new-record') {
      this.setState({showCreateModal: true})
      return
    }
    
    const selected = Array.isArray(value)
      ? this.props.options.filter(option => value.indexOf(option.id) >= 0).map(item => removeLazyLoading(item))
      : this.props.options.filter(option => option.id === value)[0]
    
    if (this.props.onChange) this.props.onChange(selected)
  }

  closeModalHandler () {
    this.setState({showCreateModal: false})
  }
  
}


function getOptions (state, props) {
  const getRequestOptions = () => {
    const requestMeta = state.data.meta[props.endpoint]
    if (requestMeta == null) return []
    const latestRequest = Object.keys(requestMeta).sort((a, b) => {
      if (requestMeta[a].requestedAt < requestMeta[b].requestedAt) return 1
      if (requestMeta[a].requestedAt > requestMeta[b].requestedAt) return -1
      return 0
    })
    if (latestRequest == null || latestRequest[0] == null) return []
    const optionsData = requestMeta[latestRequest[0]].data
    if (optionsData == null) return []
    return optionsData
  }
  const requestOptions = getRequestOptions()
  const existingOptions = Array.isArray(props.value) ? props.value : [props.value]
  const mergedOptions = unionBy(existingOptions, requestOptions, 'id')
  const filteredOptions = mergedOptions.filter(option => option != null)
  const options = filteredOptions.map(option => {
    if (option.name) return option
    return build(state.data, option.type, option.id, {includeType: true})
  })

  let loading = false
  if (state.data.meta[props.endpoint]) {
    const endPointKeys = Object.keys(state.data.meta[props.endpoint])
    endPointKeys.forEach(item => {
      if (state.data.meta[props.endpoint][item].loading) {
        loading = true
      }
    })
  }

  return {
    options: options.filter(option => option != null),
    loading,
    activeTabId: getActiveTab(state.tabs).id
  }
}

export default connect(getOptions)(injectIntl(SelectComponent))
