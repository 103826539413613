import config from '../config'

const generateParams = function (term, params = {}, page) {
  let requestParams = {...config.pages.searchTable.endpointParams}
  if (term != null) {
    requestParams['search[query]'] = term
  }
  const { sort, filter, ...otherParams } = params
  if (filter != null && filter.trim() !== '0') {
    requestParams['filter[type]'] = filter
  }
  if (otherParams && Object.keys(otherParams).length > 0) {
    requestParams = {...requestParams, ...otherParams}
  }
  if (sort != null) {
    requestParams['sort'] = sort
  }
  requestParams['page[number]'] = page || 1
  return requestParams
}

export default generateParams
