import build from 'redux-object'
import formatParams from '../../utils/formatParams'

function getRowsByRequestParams (state, { endpoint, params }) {
  let ret = {rows: {}, meta: {}, loading: false}
  if (
    state.data == null ||
    state.data.meta == null ||
    state.data.meta[endpoint] == null
  ) return ret
  const requestRegexpString = formatParams({...params, 'page[number]': '\\d+'}).replace(/\[/g, '\\[').replace(/\]/g, '\\]').replace(/\?/g, '\\?')
  const requestRegexp = new RegExp('^' + requestRegexpString + '$', 'gi')
  const requestsMade = Object.keys(state.data.meta[endpoint]).filter(key => key.search(requestRegexp) === 0)
  if (requestsMade == null || requestsMade.length <= 0) return ret
  requestsMade.forEach(request => {
    const pageNumber = /page\[number\]=(\d+)/g.exec(request)[1]
    const results = state.data.meta[endpoint][request]
    if (results.loading === true) ret.loading = true
    if (results.meta) ret.meta = results.meta
    if (results == null || results.data == null) return
    results.data.forEach((result, index) => {
      let row = build(state.data, result.type, result.id, {includeType: true})
      row.type = result.type
      ret.rows[index + (pageNumber - 1) * 100] = row
    })
  })
  return ret
}

export default getRowsByRequestParams
