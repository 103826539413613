export default {
  componentType: 'card',
  endpoint: '/chronicle(/:guid)',
  objectName: 'chronicle',
  url: '/chronicle/:guid',
  getUrl: data => '/chronicle/' + data.id,
  title: 'chronicleSingular',
  titleCreate: 'chronicle.create',
  getValue: data => data.name,
  showReviewedByEditor: true,
  showNeedsVerificationByEditor: true,
  tabs: [
    {
      title: 'tab.basis',
      type: 'basis',
      children: [
        {
          title: 'chronicleSingular',
          icon: 'icons/comment.svg',
          required: true,
          components: [
            {name: 'Select', relationship: 'kind', endpointParams: {'filter[id][prefix]': 'chronicle.external'}, label: 'kind', placeholder: 'kind', style: {width: 'calc(35% - 20px)', marginRight: '20px'}},
            {name: 'nobr', fieldName: 'name', label: 'chronicle.name', placeholder: 'chronicle.name', style: {width: '65%'}},
            {name: 'nobr', fieldName: 'nameEn', label: 'chronicle.name-en', placeholder: 'chronicle.name-en', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'chronicle.websites',
          icon: 'icons/description-icon.svg',
          components: [
            {name: 'nobr', fieldName: 'websiteRu', label: 'chronicle.websiteRu', placeholder: 'events.website.placeholder', style: {width: '80%'}},
            {name: 'nobr', fieldName: 'websiteEn', label: 'chronicle.websiteEn', placeholder: 'events.website.placeholder', style: {width: '80%'}}
          ],
          side: 'left'
        },
        {
          title: 'chronicle.dates',
          icon: 'icons/calendar-icon.svg',
          getValue: data => data.dateFrom,
          components: [
            {name: 'FullDate', fieldName: 'dateFrom', label: 'event.dateFrom', style: {width: '100%'}},
            {name: 'FullDate', fieldName: 'dateTo', label: 'event.dateTo', style: {width: '100%'}}
          ],
          side: 'right'
        },
        {
          title: 'internal-id',
          icon: 'icons/storage.svg',
          components: [
            {name: 'input', fieldName: 'slug', disabled: true}
          ],
          side: 'right'
        },
        {
          title: 'website-status',
          icon: 'icons/website-icon.svg',
          components: [
            {name: 'Switch', relationship: 'publishedOnWeb', kind: 'published_on_web', style: {float: 'none'}}
          ],
          side: 'right'
        },
        {
          title: 'keywords',
          icon: 'icons/keywords-icon.svg',
          getValue: data => data.keywords,
          components: {name: 'keywords', pointer: '/keywords', endpointParams: {'filter[group]': 'garage-chronicles'}, placeholder: 'individuals.field.keywords.placeholder', style: {width: '100%'}}, // endpointParams: {'filter[group]': 'garage-chronicles'}
          side: 'right'
        },
        {
          title: 'comment',
          icon: 'icons/comment.svg',
          components: {name: 'text', fieldName: 'notes', placeholder: 'issues.comment.placeholder', style: {width: '100%'}},
          side: 'right'
        }
      ]
    }
  ]
}
