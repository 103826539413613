export default {
  componentType: 'card',
  endpoint: '/document(/:guid)',
  objectName: 'mediaItem',
  objectType: 'document',
  url: '/media-items/:guid',
  getUrl: data => '/media-items/' + data.id,
  title: 'media-carriers',
  titleCreate: 'media-item.create',
  getValue: data => data.name,
  helpLink: 'https://app.raan.garagemca.org/docs/docs/howto#mediaItem',
  showReviewedByEditor: true,
  showNeedsVerificationByEditor: true,
  tabs: [
    {
      title: 'tab.basis',
      type: 'basis',
      children: [
        {
          title: 'media-carriers',
          icon: 'icons/documents.svg',
          required: true,
          components: [
            {name: 'Select', relationship: 'kind', endpointParams: {'filter[id][query]': 'document.media_item.*{1,}'}, label: 'events.table.kind', placeholder: 'events.table.kind', style: {width: 'calc(35% - 20px)', marginRight: '20px'}},
            {name: 'nobr', fieldName: 'name', label: 'events.table.title', placeholder: 'events.table.title', style: {width: '65%'}}
          ],
          side: 'left'
        },
        {
          title: 'media-item.duration-and-format',
          icon: 'icons/time.svg',
          components: [
            {name: 'Duration', fieldName: 'duration', label: 'duration', style: {width: '50%', paddingRight: '20px'}},
            {name: 'AutoComplete', label: 'media-item.format', fieldName: 'rformat', endpoint: '/field_rformat', placeholder: 'format.placeholder', style: {width: '50%'}}
          ],
          side: 'left'
        },
        {
          title: 'description',
          icon: 'icons/description-icon.svg',
          components: {name: 'text', fieldName: 'description', placeholder: 'description.placeholder', style: {width: '100%'}},
          side: 'left'
        },
        {
          title: 'collection',
          icon: 'icons/documents.svg',
          required: true,
          components: [
            {name: 'Select', relationship: 'collection', kind: 'collection', label: 'collection', style: {width: '100%'}}
          ],
          side: 'right'
        },
        {
          title: 'document.storage-details',
          icon: 'icons/storage.svg',
          components: [
            {name: 'input', fieldName: 'inv', label: 'inv', disabled: true, style: {width: '100%'}},
            {name: 'input', fieldName: 'code', label: 'code', style: {width: '100%'}},
            {name: 'Bool', fieldName: 'storedOnBluRay', label: 'media-item.stored-on-bluray', disabled: true, style: {width: '50%'}}
          ],
          side: 'right'
        },
        {
          title: 'comment',
          icon: 'icons/comment.svg',
          components: {name: 'text', fieldName: 'notes', placeholder: 'comment.placeholder', style: {width: '100%'}},
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.library',
      type: 'library',
      children: [
        {
          title: 'books',
          icon: 'icons/book.svg',
          components: {name: 'Relation', pointer: '/relDocumentDocument', filter: {'/document/type': 'book'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'book', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty'}
          ]},
          side: 'left'
        },
        {
          title: 'periodical-issues',
          icon: 'icons/periodic.svg',
          components: {name: 'Relation', pointer: '/relDocumentDocument', filter: {'/document/type': 'issue'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'issue', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty'}
          ]},
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.media',
      type: 'media',
      children: [
        {
          title: 'videos',
          icon: 'icons/video.svg',
          components: {name: 'Relation', pointer: '/relDocumentDocument', filter: {'/document/type': 'video'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'video', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty'}
          ]},
          side: 'left'
        },
        {
          title: 'audios',
          icon: 'icons/audio.svg',
          components: {name: 'Relation', pointer: '/relDocumentDocument', filter: {'/document/type': 'audio'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'audio', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty'}
          ]},
          side: 'right'
        },
        {
          title: 'media-items',
          icon: 'icons/documents.svg',
          components: {name: 'Relation', pointer: '/relDocumentDocument', filter: {'/document/type': 'mediaItem'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'media_item'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty'}
          ]},
          side: 'left'
        }
      ]
    },
    {
      title: 'tab.files',
      type: 'files',
      children: [
        {
          title: 'files',
          wrapper: false,
          icon: 'icons/media-archive.svg',
          components: [
            {name: 'Files', relationship: 'files'}
          ],
          side: 'both'
        }
      ]
    }
  ]
}
