import React from 'react'
import Text from '../fields/CommonField'
import NameDefault from '../fields/NameDefault'

const GroupTitle = (props) => {
  return <div className="GroupTitle">
    <div className="GroupTitle__russian">
      <Text
        {...props}
        name="input"
        fieldName="nameRu"
        label="groups.nameRu"
        placeholder="groups.nameRu.placeholder"
      />
    </div>
    <div className="GroupTitle__latin">
      <Text
        {...props}
        name="input"
        fieldName="nameEn"
        label="groups.nameEn"
        placeholder="groups.nameEn.placeholder"
      />
    </div>
    <div className="GroupTitle__default">
      <NameDefault
        {...props}
        fieldName="defaultNameLang"
        label="groups.basis-title"
      />
    </div>
  </div>
}

export default GroupTitle
