const getActiveTab = function (tabs) {
  let activeTab = {}
  for (let index in tabs) {
    if (tabs[index].active !== true) continue
    activeTab = tabs[index]
    break
  }
  return activeTab
}

export default getActiveTab
