import React from 'react'
import { FormattedMessage } from 'react-intl'
import HelpIcon from 'react-icons/lib/md/help'

const HelpLink = function ({ url, style, className }) {
  if (!url) return null
  return <a 
    className={'HelpLink ' + (className || '')} 
    href={url} 
    target="_blank"
    rel="noopener noreferrer"
    style={{...style}}
  >
    <HelpIcon /> <FormattedMessage id="help" />
  </a>
}

export default HelpLink
