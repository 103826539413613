import uuid from 'uuid'
import { arrayMove } from 'react-sortable-hoc'
import pointer from 'json-pointer'
import produce from 'immer'

const initialState = [
  {
    id: uuid(),
    active: true,
    path: '/'
  }
]

const tabs = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_NEW_TAB':
      return produce(state, draftState => {
        if (action.isActive === true) {
          draftState.map(tab => {
            tab.active = false
            return tab
          })
        }
        var newTab = {
          path: action.path || '/',
          id: uuid(),
          active: action.isActive,
          parentTabId: action.parentTabId
        }
        if (action.parentTabId) newTab.currentData = {}
        draftState.push(newTab)
      })
    case 'CLOSE_TAB':
      return produce(state, draftState => {
        if (draftState.length === 1) return draftState
        if (action.id == null) {
          for (let index in draftState) {
            if (draftState[index].active !== true) continue
            action.index = index
            break
          }
        } else {
          for (let index in draftState) {
            if (draftState[index].id !== action.id) continue
            action.index = index
            break
          }
        }
        if (draftState[action.index].active === true) {
          let nextActiveIndex = action.index - 1
          if (nextActiveIndex < 0) nextActiveIndex = parseInt(action.index, 10) + 1
          draftState[nextActiveIndex].active = true
        }
        draftState.splice(action.index, 1)
      })
    case 'ACTIVATE_TAB':
      return produce(state, draftState => {
        if (draftState[action.index].active === true) return draftState
        draftState = draftState.map((tab, index) => {
          tab.active = (index === action.index)
          return tab
        })
      })
    case 'NAVIGATE_TAB':
      return produce(state, draftState => {
        draftState.map(tab => {
          if (tab.active !== true) return tab
          tab.path = action.path
          tab.searchShown = null
          tab.searchTerm = null
          tab.position = null
          tab.pageNumbers = null
          if (tab.path.split('?')[0] !== action.path.split('?')[0]) {
            tab.changes = null
          }
          return tab
        })
      })
    case 'TAB_TOGGLE_SEARCH':
      return produce(state, draftState => {
        draftState.map(tab => {
          if (tab.active !== true) return tab
          if (action.state == null) action.state = !tab.searchShown
          tab.searchShown = action.state
          if (action.state === false) {
            tab.searchTerm = null
          }
          return tab
        })
      })
    case 'TAB_SET_SEARCH_TERM':
      return produce(state, draftState => {
        draftState.map(tab => {
          if (tab.active !== true) return tab
          tab.searchTerm = action.value
          return tab
        })
      })
    case 'REORDER_TAB':
      return produce(state, draftState => arrayMove(draftState, action.oldIndex, action.newIndex))
    case 'REFRESH_PAGE':
      return produce(state, draftState => {
        draftState.map(tab => {
          if (tab.active !== true) return tab
          return { ...tab, currentData: {}, changes: {} }
        })
      })
    case 'ADD_INITIAL_DATA_TO_TAB':
      const newState = produce(state, draftState => {
        draftState.map(tab => {
          if (tab.id !== action.tabId) return tab
          tab.initialData = action.data
          tab.currentData = action.data
          tab.changes = {}
          return tab
        })
      })
      return newState
    case 'CANCEL_TAB_CHANGES':
      // const tabId = (action.tabId == null) ? state.filter(t => t.active)[0].id : action.tabId
      return produce(state, draftState => {
        draftState.map(tab => {
          if (tab.id !== action.tabId) return tab
          tab.currentData = tab.initialData
          return tab
        })
      })
    case 'EDIT_FIELD':
      return produce(state, draftState => {
        draftState.map(tab => {
          if (tab.id !== action.tabId) return tab
          pointer.set(tab.currentData, action.path, action.value)
          return tab
        })
      })
    case 'STORE_FIELD_DATA':
      // return produce(state, draftState => {
      //   draftState.map(tab => {
      //     if (tab.id !== action.tabId) return tab
      //     if (tab.changes == null) tab.changes = {}
      //     pointer.set(tab.changes, action.path, action.value)
      //     return tab
      //   })
      // })
      return state.map((tab, index) => {
        if (tab.id !== action.tabId) return tab
        // if (tab.changes == null) tab.changes = {}
        let changes = Object.assign({}, tab.changes)
        pointer.set(changes, action.path, action.value)
        return { ...tab, changes }
      })
    case 'OPEN_ACTIVE_TAB_POPUP':
      return produce(state, draftState => {
        draftState.map(tab => {
          if (!tab.active) return tab
          tab.popup = {
            is_open: true,
            img_src: action.imgSrc
          }
          return tab
        })
      })
    case 'CLOSE_ACTIVE_TAB_POPUP':
      return produce(state, draftState => {
        draftState.map(tab => {
          if (!tab.active) return tab
          tab.popup = {
            is_open: false
          }
          return tab
        })
      })
    default:
      return state
  }
}

export default tabs
