const table = (state = {}, action) => {
  switch (action.type) {
    case 'SET_TABLE_SIZE':
      return {
        width: action.width,
        height: action.height
      }
    default:
      return state
  }
}

export default table
