import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import { subscribe } from '../utils/pubSub'
import jsonPointer from 'json-pointer'
import Editable from '../components/Editable'
import { update } from '../redux/actions/apiActions'
import { storeFieldData } from '../redux/actions/editingCard'
import { isUri } from 'valid-url'
import Notification from '../components/Notification'
import addData from '../utils/addData'

class Url extends PureComponent {

  componentWillMount () {
    subscribe(this.props.tab.id, this.props.pointer, (msg, done) => {
      if (msg === 'cancel') return this.cancelChanges.apply(this)
      if (msg === 'save') return this.saveChanges.apply(this)
    })
  }

  componentWillUnmount () {
    // PubSub.unsubscribe(this.props.tab.id)
  }

  render () {
    const { label, placeholder } = this.props
    const value = this.getValue.apply(this)

    return <div className="Url" style={{width: '100%'}}>
      <Editable
        label={label}
        type="input"
        placeholder={placeholder}
        value={value}
        onChange={this.setValue.bind(this)}
        invalid={!this.isValidValue.apply(this)}
      />
    </div>
  }

  isValidValue () {
    const value = this.getValue.apply(this)
    if (this.getInitialValue.apply(this) === value) return true
    return value == null || value === '' || isUri(value)
  }

  getInitialValue () {
    const { data, pointer } = this.props
    if (!data || !jsonPointer.has(data, pointer)) return null
    return jsonPointer.get(data, pointer)
  }

  getEditedValue () {
    const { tab, pointer } = this.props
    if (!tab.changes || !jsonPointer.has(tab.changes, pointer)) return null
    return jsonPointer.get(tab.changes, pointer)
  }

  getValue () {
    const editedValue = this.getEditedValue.apply(this)
    if (editedValue !== null) return editedValue
    return this.getInitialValue.apply(this)
  }

  setValue (newValue) {
    const { tab, pointer } = this.props
    this.props.dispatch(storeFieldData(tab.id, pointer, newValue))
  }

  cancelChanges () {
    const { tab, pointer } = this.props
    this.props.dispatch(storeFieldData(tab.id, pointer, null))
  }

  saveChanges () {
    const initialValue = this.getInitialValue.apply(this)
    const editedValue = this.getEditedValue.apply(this)
    if (initialValue === editedValue || editedValue == null) return
    const url = '/' + this.props.config.objectName + '/' + this.props.data.id
    let modifier = {}
    jsonPointer.set(modifier, '/data/type', this.props.config.objectName)
    jsonPointer.set(modifier, '/data/attributes' + this.props.pointer, editedValue)
    this.props.dispatch(update(url, modifier, (error, data, normalised) => {
      if (error) {
        const message = this.props.intl.formatMessage({id: 'notification.card.update.error'})
        const errorText = error.errors ? error.errors[0].detail : JSON.stringify(error)
        return Notification.error(message + ': ' + errorText, {duration: null})
      }
    }))
  }

}

export default addData(injectIntl(Url))
