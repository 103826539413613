import formatDate from '../utils/formatDate'

export default {
  componentType: 'search',
  objectName: 'collection',
  sortLevel: 19,
  defaultSorting: 'institution.code,code',
  url: '/search/?filter=collection',
  title: 'collections',
  getUrl: data => '/collections/' + data.id,
  table: [
    {
      title: 'code',
      width: 140,
      fixed: true,
      sortBy: 'code',
      getValue: data => data.code
    },
    {
      title: 'institution',
      width: 180,
      fixed: true,
      sortBy: 'institution.code',
      filterBy: 'institution?response[type]=short',
      getValue: data => data.institution ? data.institution.code : null
    },
    {
      title: 'title',
      width: 400,
      fixed: true,
      sortBy: 'name',
      getValue: data => data.name
    },
    {
      title: 'titleEn',
      width: 400,
      sortBy: 'name_en',
      getValue: data => data.nameEn
    },
    {
      title: 'published',
      width: 105,
      sortBy: 'published_on_web.name',
      filterBy: 'published_on_web',
      getValue: data => data.publishedOnWeb ? data.publishedOnWeb.name : null
    },
    {
      title: 'collections.documentsCountIncludingUnpublished',
      width: 180,
      sortBy: 'documents_count_including_unpublished',
      getValue: data => data.documentsCountIncludingUnpublished
    },
    {
      title: 'collections.documentsCount',
      width: 180,
      sortBy: 'documents_count',
      getValue: data => data.documentsCount
    },
    {
      title: 'createdAt',
      width: 120,
      sortBy: 'created_at',
      getValue: data => formatDate(data.createdAt)
    },
    {
      title: 'individuals.table.createdBy',
      width: 165,
      sortBy: 'created_by',
      filterBy: 'user?filter[created_type]=collection',
      filterMode: 'multiple',
      getValue: data => data.createdBy
    }
  ]
}

