import React from 'react'
import { FormattedMessage } from 'react-intl'

import formatDate from '../utils/formatDate'

export default {
  componentType: 'search',
  objectName: 'chronicle',
  defaultSorting: '-created_at',
  url: '/search/?filter=chronicle',
  title: 'chronicleTable',
  getUrl: data => '/chronicle/' + data.id,
  table: [
    {
      title: 'kind',
      width: 100,
      fixed: true,
      sortBy: 'kind.name',
      filterBy: 'kind?filter[id][prefix]=chronicle.external',
      filterMode: 'multiple',
      getValue: data => data.kind ? data.kind.name : null
    },
    {
      title: 'title',
      width: 450,
      fixed: true,
      sortBy: 'name',
      getValue: data => data.name
    },
    {
      title: 'titleEn',
      width: 250,
      fixed: true,
      sortBy: 'name_en',
      getValue: data => data.nameEn
    },
    {
      title: 'code',
      width: 100,
      sortBy: 'slug',
      getValue: data => data.slug
    },
    {
      title: 'events.table.dateFrom',
      width: 120,
      sortBy: 'date_from',
      filterBy: 'date_from',
      getValue: data => formatDate(data.dateFrom)
    },
    {
      title: 'published',
      width: 150,
      sortBy: 'published_on_web.name',
      getValue: data => data.publishedOnWeb ? data.publishedOnWeb.name : null
    },
    {
      title: 'reviewedByEditor',
      width: 175,
      sortBy: 'reviewed_by_editor',
      filterBy: 'reviewed_by_editor',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: data => data.reviewedByEditor ?
        <FormattedMessage id="yes" /> :
        <FormattedMessage id="no" />
    },
    {
      title: 'needsVerificationByEditor',
      width: 175,
      sortBy: 'needs_verification_by_editor',
      filterBy: 'needs_verification_by_editor',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: data => data.needsVerificationByEditor ?
        <FormattedMessage id="yes" /> :
        <FormattedMessage id="no" />
    },
    {
      title: 'createdAt',
      width: 120,
      sortBy: 'created_at',
      getValue: data => formatDate(data.createdAt)
    },
    {
      title: 'individuals.table.createdBy',
      width: 165,
      sortBy: 'created_by',
      filterBy: 'user?filter[created_type]=chronicle',
      filterMode: 'multiple',
      getValue: data => data.createdBy
    }
  ]
}
