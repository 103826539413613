import React, { Component } from 'react'
import { withRouter, matchPath } from 'react-router'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import Ellipsis from './EllipsisComponent'
import config from '../config'
import build from 'redux-object'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { openNewTab, closeTab, activateTab, navigateTab, reorderTab } from '../redux/actions/manipulateTabs'
import getSearchTermFromUrl from '../utils/getSearchTermFromUrl'
import getPropsFromUrl from '../utils/getPropsFromUrl'

const closeIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"><path stroke="#5a5a5a" strokeLinecap="round" strokeWidth="1.125" d="M4 4 L10 10 M10 4 L4 10" /></svg>
const createIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"><path stroke="#5a5a5a" strokeLinecap="round" strokeWidth="1.125" d="M3 7 L11 7 M7 3 L7 11" /></svg>

class ApplicationTabs extends Component {

  componentWillMount () {
    if (this.props.tabs !== null && this.justLoaded == null) {
      const activeTab = this.props.tabs.filter(tab => tab.active)[0].path
      this.justLoaded = false
      this.props.history.push(activeTab)
    }
    this.props.history.listen((location, event, test) => {
      this.props.dispatch(navigateTab(location.pathname + location.search))
    })
  }

  render () {
    return React.createElement(SortableContainer(this.renderList.bind(this)), {
      axis: 'x',
      distance: 5,
      lockAxis: 'x',
      onSortEnd: this.onSortEnd.bind(this)
    })
  }

  renderList () {
    return <div className="ApplicationTabs">
      {this.props.tabs.map((tab, index) => {
        return React.createElement(SortableElement(this.renderElement.bind(this)), {tab, index, i: index, key: tab.id})
      })}
      <div className="ApplicationTabs__tab-new" onClick={this.openNewTab.bind(this)}>{createIcon}</div>
    </div>
  }

  renderElement ({tab, index, i}) {
    const tabTitle = tab.title
    const className = classNames('ApplicationTabs__tab', {active: tab.active})
    return <div className={className} key={tab.id} onClick={(e) => this.activateTab.bind(this)(e, i, tab)}>
      <span className="ApplicationTabs__tab-title"><Ellipsis>{tabTitle}</Ellipsis></span>
      {(this.props.tabs.length > 1)
        ? <div className="ApplicationTabs__tab-close" onClick={(e) => this.closeTab.bind(this)(e, tab.id)}>{closeIcon}</div>
        : null
      }
    </div>
  }

  onSortEnd ({oldIndex, newIndex}) {
    this.props.dispatch(reorderTab(oldIndex, newIndex))
  }

  activateTab (e, index, tab) {
    e.preventDefault()
    e.stopPropagation()
    this.props.dispatch(activateTab(index))
    this.props.history.push(tab.path)
  }

  openNewTab () {
    this.props.dispatch(openNewTab())
  }

  closeTab (e, id) {
    e.preventDefault()
    e.stopPropagation()
    this.props.dispatch(closeTab(id))
  }

}

function getTabTitle (tab, state) {
  let tabTitle = null

  if (typeof tab.path === 'string') {
    if (tab.path === '/search/' || tab.path === '/') {
      return <FormattedMessage id={'search.table.all'} />
    }

    if (tab.path.indexOf('/search/') === 0) {
      let title = getSearchTermFromUrl(tab.path)
      if (title && title.length > 0) return title 
    }
  }

  Object.keys(config.pages).forEach((pageName, index) => {
    const configuration = config.pages[pageName]
    const indexOfQuery = tab.path.indexOf('?')
    const urlProps = getPropsFromUrl(tab.path)
    const onlyPath = tab.path.substring(0, indexOfQuery)
    const cleanedPath = (indexOfQuery >= 0) ? 
      (onlyPath === '/' ? '/search/' : onlyPath) + (urlProps.filter ? `?filter=${urlProps.filter}` : '') : 
      tab.path
    const match = matchPath(cleanedPath, {
      path: configuration.url,
      exact: true
    })
    if (match == null) return
    if (configuration.title) {
      tabTitle = <FormattedMessage id={configuration.title} />
    }
    if (configuration.getValue && match.params && state.data) {
      const data = tab.currentData
        ? build(tab.currentData, configuration.objectName, match.params.guid, {includeType: true})
        : build(state.data, configuration.objectName, match.params.guid, {includeType: true})
      if (!data) return
      tabTitle = configuration.getValue(data)
    }
  })
  return tabTitle
}

function mapStateToProps (state, params) {
  const tabs = state.tabs
    .filter(tab => !tab.parentTabId)
    .map(tab => {
      return {...tab, title: getTabTitle(tab, state)}
    })
  return {tabs}
}

export default connect(mapStateToProps)(withRouter(ApplicationTabs))
