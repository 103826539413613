import React, { PureComponent } from 'react'
import Editor from '../fieldsStyled/RichTextStyled'
import { EditorState, convertToRaw, convertFromRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import jsonPointer from 'json-pointer'
import addData from '../utils/addData'
import { storeFieldData } from '../redux/actions/editingCard'
import Route from 'route-parser'
import { update } from '../redux/actions/apiActions'
import { subscribe } from '../utils/pubSub'

class RichText extends PureComponent {

  constructor (props) {
    super(props)
    this.getInitialValue = this.getInitialValue.bind(this)
    this.getEditedValue = this.getEditedValue.bind(this)
    this.setEditedValue = this.setEditedValue.bind(this)
    this.cancelChanges = this.cancelChanges.bind(this)
    this.saveChanges = this.saveChanges.bind(this)
  }

  componentWillMount () {
    const { tab, fieldName } = this.props
    subscribe(tab.id, '/' + fieldName, (type) => {
      if (type === 'cancel') return this.cancelChanges()
      if (type === 'save') return this.saveChanges()
    })
  }

  render () {
    return <Editor
      editorState={this.getEditedValue() || this.getInitialValue()}
      onEditorStateChange={this.setEditedValue}
    />
  }

  getInitialValue () {
    const html = this.props.data[this.props.fieldName] || ''
    const contentBlock = htmlToDraft(html)
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    return EditorState.createWithContent(contentState)
  }

  getEditedValue () {
    const { tab, fieldName } = this.props
    if (!tab.changes || !jsonPointer.has(tab.changes, '/' + fieldName)) return null
    const raw = jsonPointer.get(tab.changes, '/' + fieldName)
    if (!raw) return null
    const editorState = EditorState.createWithContent(convertFromRaw(raw))
    if (!this.selection) return editorState
    return EditorState.acceptSelection(editorState, this.selection)
  }

  setEditedValue (editorState) {
    const { tab, fieldName, dispatch } = this.props
    const raw = convertToRaw(editorState.getCurrentContent())
    this.selection = editorState.getSelection()
    return dispatch(storeFieldData(tab.id, '/' + fieldName, raw))
  }

  cancelChanges (cb) {
    const { tab, fieldName, dispatch } = this.props
    dispatch(storeFieldData(tab.id, '/' + fieldName))
    if (typeof cb === 'function') cb()
  }

  async saveChanges () {
    const { tab, data, fieldName, config, dispatch } = this.props
    if (!tab.changes || !jsonPointer.has(tab.changes, '/' + fieldName)) return
    const raw = jsonPointer.get(tab.changes, '/' + fieldName)
    if (!raw) return
    const newHTML = draftToHtml(raw)
    const oldHTML = this.props.data[this.props.fieldName]
    if (newHTML === oldHTML) return
    const route = new Route(config.endpoint)
    const endpoint = route.reverse({guid: data.id})
    await dispatch(update(endpoint, {data: {attributes: {[fieldName]: newHTML || ''}}}))
  }

}

export default addData(RichText)
