import { Component } from 'react'
import { connect } from 'react-redux'
import { openNewTab } from '../redux/actions/manipulateTabs'

class ClickCatcher extends Component {

  componentDidMount () {
    document.body.addEventListener('click', this.clickHandler.bind(this))
  }

  componentWillUnmount () {
    document.body.removeEventListener('click', this.clickHandler)
  }

  render () {
    return this.props.children
  }

  clickHandler (e) {
    const getLink = function (node, cb) {
      if (node.nodeName === 'A') return cb(node.href)
      if (!node.parentNode) return
      getLink(node.parentNode, cb)
    }
    if (e.metaKey || e.ctrlKey) {
      getLink(e.target, (href) => {
        e.preventDefault()
        this.props.dispatch(openNewTab(href, false))
      })
    }
  }
  
}

export default connect(() => ({}))(ClickCatcher)
