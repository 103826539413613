import React from 'react'
// import LinkIcon from 'react-icons/lib/fa/external-link-square'
// import LinkIcon from 'react-icons/lib/fa/paper-plane'
// import LinkIcon from 'react-icons/lib/md/open-in-browser'
import LinkIcon from 'react-icons/lib/md/open-in-new'

const ExternalLink = function ({ url, style, className }) {
  if (!url) return null
  return <a 
    className={'ExternalLink ' + className} 
    href={url} 
    target="_blank"
    rel="noopener noreferrer"
    style={{...style}} 
  >
    <LinkIcon />
  </a>
}

export default ExternalLink
