import React from 'react'

const ShadowScroll = function ({className, children}) {
  return <div className={className + ' ShadowScroll'}>
    <div className="ShadowScroll__shadowContainer">
      <div className="ShadowScroll__shadow ShadowScroll__linearShadowTop" />
      <div className="ShadowScroll__shadow ShadowScroll__linearShadowBottom" />
    </div>
    <div className="ShadowScroll__content">
      <div className="ShadowScroll__shadowCoverTop" />
      <div className="ShadowScroll__text">{children}</div>
      <div className="ShadowScroll__shadowCoverBottom" />
    </div>
  </div>
}

export default ShadowScroll
