import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import Dropzone from 'react-dropzone'
import getActiveTab from '../utils/getActiveTab'
import {addFile, updateProgress, updateId, setError, removeFile} from '../redux/actions/filesActions'
import Route from 'route-parser'
import Notification from './Notification'

import {calcMD5, chunkCount} from '../utils/calcMD5'

class GlobalFileUploader extends PureComponent {

  constructor () {
    super()
    this.state = {dropzoneActive: false}
  }

  render () {
    return (
      <Dropzone
        disableClick
        className="GlobalFileUploader"
        onDrop={this.onDrop.bind(this)}
        onDragEnter={this.onDragEnter.bind(this)}
        onDragLeave={this.onDragLeave.bind(this)}
        ref={node => { window.dropzone = node }}
      >
        {this.props.children}
        {this.state.dropzoneActive && <div className="GlobalFileUploader__overlay" />}
      </Dropzone>
    )
  }

  onDragEnter () {
    this.setState({dropzoneActive: true})
  }

  onDragLeave () {
    this.setState({dropzoneActive: false})
  }

  async onDrop (files) {
    const filesWithData = files.map(file => {
      const udid = file.preview.substring(file.preview.lastIndexOf('/') + 1)
      file.udid = udid
      file.documentId = this.props.documentId
      return file
    })

    for (const file of filesWithData) {
      let md5
      try {
        md5 = await this.calcHashSum(file)
      } catch (e) {
        Notification.error("Error calculating MD5 " + file.name)
        break
      }
      this.upload(file, md5)
    }

    this.setState({dropzoneActive: false})
  }

  async calcHashSum (file) {
    const onerror = (event) => {
      if (event && event.target && event.target.error) {
        Notification.error("Error calculating MD5 " + event.target.error)
      }
      console.log("FileReader errors:", event.target.error)
      this.props.dispatch(removeFile(file.udid))
    }

    let chunkNumber = 0
    const fileChunksCount = chunkCount(file);
    const onloadstart = (event) => {
      chunkNumber += 1
      let progress = - Math.min(chunkNumber / fileChunksCount, 1)
      console.log(progress)
      this.props.dispatch(updateProgress(file.udid, progress))
    }

    this.props.dispatch(addFile(file.udid, file.documentId, {name: file.name, size: file.size}))
    const md5 = await calcMD5(file, onerror, onloadstart)
    console.log(file.name, 'md5 =', md5)
    return md5
  }

  upload (file, md5) {
    return new Promise((resolve, reject) => {
      if(!md5 || typeof md5 !== 'string' || md5.length !== 32) {
        Notification.error('No MD5')
        return
      }

      let request = new XMLHttpRequest() // eslint-disable-line
      file.md5 = md5
      request.file = file

      const uploadHost = this.props.uploadToInternalServer ? process.env.REACT_APP_INTERNAL_UPLOAD_HOST : process.env.REACT_APP_UPLOAD_HOST
      const uploadUrl = `${uploadHost}/file/storage`
      request.open('POST', uploadUrl, true)
      request.setRequestHeader('Authorization', 'Bearer ' + this.props.token)
      request.upload.addEventListener('progress', (e) => {
        if (this.props.files[request.file.udid].isAborting) {
          request.abort()
          this.props.dispatch(removeFile(request.file.udid))
          return
        }
        if (!e.lengthComputable) return
        const progress = e.loaded / e.total
        this.props.dispatch(updateProgress(request.file.udid, progress))
      }, false)
      request.onloadend = (e) => {
        if (request.status === 0 && this.props.files[request.file.udid].isAborting) {
          // load was aborted
          resolve()
          return
        } else if (request.status > 0 && request.status !== 201) {
          Notification.error(request.responseText)
          return this.props.dispatch(setError(request.file.udid, request.responseText))
        }
        if (!request.response) {
          Notification.error('No server response')
          return this.props.dispatch(setError(request.file.udid))
        }
        try {
          const json = JSON.parse(request.response)
          this.props.dispatch(updateId(request.file.udid, json.data.id))
        } catch (e) {
          Notification.error('Wrong server response')
          this.props.dispatch(setError(request.file.udid))
        }
        resolve()
      }
      let formData = new FormData() // eslint-disable-line
      formData.append('document_id', file.documentId)
      formData.append('md5', md5)
      formData.append('file', file)
      request.send(formData)
    })
  }

}

const mapStateToProps = (state, props) => {
  const activeTab = getActiveTab(state.tabs)
  const route = new Route('/:type/:id')
  const params = route.match(activeTab.path)
  return {
    documentId: params.id,
    uploadToInternalServer: params.type === "media-items" && state.user.name.startsWith("G/"),
    token: state.user.access_token,
    files: state.files
  }
}

export default connect(mapStateToProps)(GlobalFileUploader)
