import React from 'react'
import { FormattedMessage } from 'react-intl'
import componentTypes from '../fields'
import ActionButton from '../components/ActionButton'

const returnComponent = function (component, props) {
  const Comp = componentTypes[component.name]
  if (Comp == null) return null
  return <Comp 
    {...component} 
    {...props} 
    key={component.relationship + '-' + component.fieldName + '-' + props.applicationTabId} 
  />
}

const fieldWrapper = (fieldConfig, props) => {
  let value = null
  if (fieldConfig.components != null) {
    try {
      if (fieldConfig.components.length > 0) {
        value = fieldConfig.components.map(component => {
          return returnComponent(component, props)
        })
      } else {
        const component = fieldConfig.components
        value = returnComponent(component, props)
      }
    } catch (e) {
      console.error(e)
      value = <FormattedMessage id="error" />
    }
  }
  return <div
    key={fieldConfig.title}
    className={fieldConfig.wrapper !== false ? 'FieldsComponent__wrapper' : 'FieldsComponent__wrapper FieldsComponent__wrapper-none'}
    style={{float: fieldConfig.side}}
  >
    <div className="FieldsComponent__title">
      <FormattedMessage id={fieldConfig.title} />
    </div>
    <div className="FieldsComponent__wrapper-box">
      <div className="FieldsComponent__icon" style={{'backgroundImage': 'url("' + fieldConfig.icon + '")'}} />
      <div className="FieldsComponent__container">
        {value}
      </div>
    </div>
  </div>
}

const FieldsComponent = ({ onlyRequired, tabType, ...props }) => {
  let tabConfig = (tabType == null)
    ? props.config.tabs[0]
    : props.config.tabs.filter(tab => tab.type === tabType)[0]
  let fieldsConfig = tabConfig.children

  if (fieldsConfig == null) return null
  if (onlyRequired === true) fieldsConfig = fieldsConfig.filter(field => field.required)
  
  let buttonsConfig = tabConfig.buttons ? tabConfig.buttons : null
  const applicationDataId = props.applicationDataId
  const applicationTabId = props.applicationTabId

  return <div className="FieldsComponent">
    {buttonsConfig && (
      <div className="FieldsComponent__buttons">
        {buttonsConfig.map(item => (
          <ActionButton 
            key={item.title} 
            item={item} 
            applicationDataId={applicationDataId}
            applicationTabId={applicationTabId}
            endpoint={props.config.endpoint}
          />
        ))}
      </div>
    )}
    <div className="FieldsComponent__left">
      {fieldsConfig.filter(fieldConfig => fieldConfig.side === 'left').map(fieldConfig => fieldWrapper(fieldConfig, props))}
    </div>
    <div className="FieldsComponent__right">
      {fieldsConfig.filter(fieldConfig => fieldConfig.side === 'right').map(fieldConfig => fieldWrapper(fieldConfig, props))}
    </div>
    <div className="FieldsComponent__both">
      {fieldsConfig.filter(fieldConfig => fieldConfig.side === 'both').map(fieldConfig => fieldWrapper(fieldConfig, props))}
    </div>
    <div className="FieldsComponent__clear" />
  </div>
}

export default FieldsComponent
