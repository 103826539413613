import formatDate from '../utils/formatDate'

export default {
  componentType: 'table',
  objectName: 'lto',
  endpoint: '/lto',
  endpointParams: {
    'page[size]': 100,
    'page[number]': 1
  },
  url: '/lto',
  title: 'lto.tapes',
  getUrl: data => '/lto/' + data.id,
  table: [
    {
      title: 'title',
      width: 120,
      fixed: true,
      sortBy: 'name',
      getValue: data => data.name
    },
    {
      title: 'lto.finalized',
      width: 150,
      sortBy: 'finalized',
      getValue: data => data.finalized ? 'Да' : '-'
    },
    {
      title: 'lto.copy-a',
      width: 140,
      sortBy: 'copy_a_exists',
      getValue: data => data.copyAExists ? 'Да' : '-'
    },
    {
      title: 'lto.copy-b',
      width: 140,
      sortBy: 'copy_b_exists',
      getValue: data => data.copyBExists ? 'Да' : '-'
    },
    {
      title: 'lto.stored-files-size',
      width: 120,
      getValue: data => data.storedFilesSizeHuman
    },
    {
      title: 'comment',
      width: 150,
      sortBy: 'notes',
      getValue: data => data.notes
    },
    {
      title: 'createdAt',
      width: 100,
      sortBy: 'created_at',
      getValue: data => formatDate(data.createdAt)
    },
    {
      title: 'individuals.table.createdBy',
      width: 120,
      sortBy: 'created_by_id',
      getValue: data => data.createdBy
    }
  ]
}
