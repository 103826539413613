export default {
  componentType: 'card',
  endpoint: '/document(/:guid)',
  objectName: 'periodical',
  objectType: 'document',
  url: '/periodicals/:guid',
  getUrl: data => '/periodicals/' + data.id,
  title: 'periodicalsCard',
  titleCreate: 'periodicals.create',
  getValue: data => data.name,
  showReviewedByEditor: true,
  showNeedsVerificationByEditor: true,
  tabs: [
    {
      title: 'tab.basis',
      type: 'basis',
      children: [
        {
          title: 'periodical',
          icon: 'icons/periodic.svg',
          required: true,
          components: [
            {name: 'Select', relationship: 'kind', endpointParams: {'filter[id][prefix]': 'document.periodical'}, label: 'events.table.kind', placeholder: 'events.table.kind', style: {width: 'calc(35% - 20px)', marginRight: '20px'}},
            {name: 'nobr', fieldName: 'name', label: 'events.table.title', placeholder: 'events.table.title', style: {width: '65%'}}
          ],
          side: 'left'
        },
        {
          title: 'periodicals.other-names',
          icon: 'icons/other-names-icon.svg',
          components: [
            {name: 'TextArray', fieldName: 'alternate_names', placeholder: 'other-names.placeholder', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'periodicals.language',
          icon: 'icons/flag.svg',
          components: [
            {name: 'MultiSelect', relationship: 'languages', kind: 'language', label: 'periodicals.language', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'colophon',
          icon: 'icons/book.svg',
          components: [
            {name: 'Select', relationship: 'place', endpointParams: {'page[size]': '20'}, kind: 'place', allowClear: true, label: 'place', style: {width: '100%'}, createNew: true, createObjectName: 'place'},
            {name: 'input', fieldName: 'issnStr', label: 'periodicals.issn', placeholder: 'periodicals.issn.placeholder', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'keywords',
          icon: 'icons/keywords-icon.svg',
          components: {name: 'keywords', pointer: '/keywords', placeholder: 'keywords.placeholder', style: {width: '100%'}},
          side: 'left'
        },
        {
          title: 'description',
          icon: 'icons/description-icon.svg',
          components: {name: 'text', fieldName: 'description', placeholder: 'description.placeholder', style: {width: '100%'}},
          side: 'left'
        },
        {
          title: 'periodicals.issues',
          icon: 'icons/periodic.svg',
          components: [
            {name: 'TextList', fieldName: 'issues', disabled: true, placeholder: 'periodicals.issues', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'periodicals.articles',
          icon: 'icons/periodic.svg',
          components: [
            {name: 'TextList', fieldName: 'articles', disabled: true, placeholder: 'periodicals.articles', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'periodicals.dates-period',
          icon: 'icons/calendar-icon.svg',
          components: [
            {name: 'FullDate', label: 'periodicals.date-from', fieldName: 'dateFrom', style: {width: '100%'}},
            {name: 'FullDate', label: 'periodicals.date-to', fieldName: 'dateTo', style: {width: '100%'}},
            {name: 'Select', relationship: 'periodicalFrequency', kind: 'periodical_frequency', allowClear: true, label: 'periodicals.periodical-frequency', style: {width: '100%'}}
          ],
          side: 'right'
        },
        {
          title: 'website-status',
          icon: 'icons/website-icon.svg',
          components: [
            {name: 'Switch', relationship: 'publishedOnWeb', kind: 'published_on_web', style: {float: 'none'}}
          ],
          side: 'right'
        },
        {
          title: 'publishers',
          icon: 'icons/organisation.svg',
          components: {name: 'Relation', pointer: '/relDocumentOrganisation', components: [
            {name: 'Select', relationship: 'organisation', endpointParams: {'search[fields]': 'name'}, placeholder: 'organisations.placeholder', createNew: true, createObjectName: 'organisation'},
            {name: 'Select', relationship: 'role', fixed: 'role.book_publisher'}
          ]},
          side: 'right'
        },
        {
          title: 'comment',
          icon: 'icons/comment.svg',
          components: {name: 'text', fieldName: 'notes', placeholder: 'periodicals.comment.placeholder', style: {width: '100%'}},
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.related-people',
      type: 'related-people',
      children: [
        {
          title: 'individuals',
          icon: 'icons/individual.svg',
          components: {name: 'Relation', pointer: '/relPersonDocument', filter: {'/person/type': 'individual'}, components: [
            {name: 'Select', relationship: 'person', endpointParams: {'filter[type]': 'individual'}, placeholder: 'individuals.placeholder', createNew: true, createObjectName: 'individual'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'periodical,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'left'
        },
        {
          title: 'groups',
          icon: 'icons/group.svg',
          components: {name: 'Relation', pointer: '/relPersonDocument', filter: {'/person/type': 'group'}, components: [
            {name: 'Select', relationship: 'person', endpointParams: {'filter[type]': 'group'}, placeholder: 'individuals.placeholder', createNew: true, createObjectName: 'group'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'periodical,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.files',
      type: 'files',
      children: [
        {
          title: 'files',
          wrapper: false,
          icon: 'icons/media-archive.svg',
          components: [
            {name: 'Files', relationship: 'files'}
          ],
          side: 'both'
        }
      ]
    }
  ]
}
