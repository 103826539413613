import React, { PureComponent } from 'react'
import jsonPointer from 'json-pointer'
import addData from '../utils/addData'
import camelCase from 'lodash/camelCase'
import { FormattedMessage } from 'react-intl'
import { openNewTab } from '../redux/actions/manipulateTabs'
import getCardURLByType from '../utils/getCardURLByType'
import Tooltip from 'antd/lib/tooltip'
import generateTooltip from '../utils/generateTooltip'
import NewTabLink from '../components/NewTabLink'

class TextList extends PureComponent {

  constructor () {
    super()
    this.getPointer = this.getPointer.bind(this)
  }

  render () {
    const value = this.getValue.apply(this)
    let noListData = null
    let listData = null
    
    if (!value || value.length < 1) {
      noListData = (
        <div className="TextList__no-data">
          <FormattedMessage id="no-data" />
        </div>
      )
    } else {
      listData = (
        <ol className="TextList__ol" style={{listStyle: this.props.listStyle || 'decimal'}}>
          {
            value.map((name, index) => {
              const cardUrl = getCardURLByType(name)
              let tooltip = generateTooltip(name)
              let tooltipContent = cardUrl
                ? <NewTabLink url={cardUrl}>{tooltip}</NewTabLink>
                : tooltip
              return (
                <li key={index} onClick={() => this.props.dispatch(openNewTab(cardUrl))}>
                  <Tooltip placement="left" title={tooltipContent}>
                    <span>{name.name}</span>
                  </Tooltip>
                </li>
              )
            })
          }
        </ol>
      )
    }

    return <div className="TextList" style={this.props.style}>
      {noListData}
      {listData}
    </div>
  }

  getPointer () {
    return '/' + camelCase(this.props.fieldName)
  }

  getInitialValue () {
    const { data } = this.props
    if (!data || !jsonPointer.has(data, this.getPointer())) return null
    return jsonPointer.get(data, this.getPointer())
  }

  getEditedValue () {
    const { tab } = this.props
    if (!tab.changes || !jsonPointer.has(tab.changes, this.getPointer())) return null
    return jsonPointer.get(tab.changes, this.getPointer())
  }

  getValue () {
    return this.getEditedValue.apply(this) || this.getInitialValue.apply(this)
  }

}

export default addData(TextList)
