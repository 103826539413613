import React from 'react'
import LabelWrapper from '../components/LabelWrapper'
import AutoComplete from 'antd/lib/auto-complete'

const AutoCompleteStyled = ({label, style, dataSource, value, options, onChange, onSearch}) => {
  return <LabelWrapper label={label} style={style} >
    <AutoComplete
      className="AutoCompleteStyled"
      value={value}
      dataSource={dataSource}
      onChange={onChange}
      onSearch={onSearch}
    />
  </LabelWrapper>
}

export default AutoCompleteStyled
