var callbacks = {}

const subscribe = function (tabId, callbackId, callback) {
  if (!callbacks[tabId]) callbacks[tabId] = {}
  callbacks[tabId][callbackId] = callback
}

const call = function (tabId, eventName, done, index) {
  if (callbacks[tabId] == null) {
    if (typeof done === 'function') done()
    return
  }
  const callbackIds = Object.keys(callbacks[tabId])
  if (typeof index === 'undefined') index = 0
  if (callbackIds.length <= 0 || index > callbackIds.length - 1) {
    if (typeof done === 'function') done()
    return
  }
  const promise = callbacks[tabId][callbackIds[index]](eventName)
  if (typeof promise === 'undefined') return call(tabId, eventName, done, index + 1)
  promise.then(function () {
    call(tabId, eventName, done, index + 1)
  })
}

export {subscribe, call}
