import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { FormattedMessage } from 'react-intl'
import TableContainer from '../containers/TableContainer'
import CreateCard from '../components/CreateCard'
import FiltersForm from '../components/FiltersForm'
import ColumnsSettings from '../components/ColumnsSettings'
import TableCounter from '../components/TableCounter'
import getPropsFromUrl from '../utils/getPropsFromUrl'
import { navigateTab } from '../redux/actions/manipulateTabs'
import formatParams from '../utils/formatParams'

class TableLayout extends Component {
  
  constructor (props) {
    super(props)
    this.resetFilters = this.resetFilters.bind(this)
    this.onFilter = this.onFilter.bind(this)
  }

  onFilter (params) {
    const indexOfQuery = this.props.tab.path.indexOf('?')
    const cleanPath = (indexOfQuery >= 0) ? this.props.tab.path.substring(0, indexOfQuery) : this.props.tab.path
    const urlParams = getPropsFromUrl(this.props.tab.path)
    const path = cleanPath + formatParams({...urlParams, ...params})
    this.props.dispatch(navigateTab(path))
    this.props.history.replace(path)
  }

  resetFilters () {
    const indexOfQuery = this.props.tab.path.indexOf('?')
    const cleanPath = (indexOfQuery >= 0) ? this.props.tab.path.substring(0, indexOfQuery) : this.props.tab.path
    const urlParams = getPropsFromUrl(this.props.tab.path)
    const path = cleanPath + formatParams({filter: urlParams.filter, sort: urlParams.sort})
    this.props.dispatch(navigateTab(path))
    this.props.history.replace(path)
  }

  render () {
    const props = this.props

    let otherParams = getPropsFromUrl(this.props.tab.path)
    if (otherParams.filter) delete otherParams.filter
    if (otherParams.sort) delete otherParams.sort

    const isActiveFilters = otherParams && Object.keys(otherParams).length > 0

    return (
      <div className="TableLayout">
        <div className="TableLayout__topPanel">
          <div className="TableLayout__title">
            <h2><FormattedMessage id={props.config.title} /></h2>
          </div>
          <div className="TableLayout__filters-icon-inner">
            <FiltersForm 
              isActiveFilters={isActiveFilters}
              filters={otherParams}
              onFilter={this.onFilter}
              filter={this.props.config.objectName}
            />
          </div>
          <div className="TableLayout__filters">
            <div 
              className="TableLayout__reset-filters" 
              onClick={this.resetFilters}
              style={{ display: isActiveFilters ? 'block' : 'none' }}
            >✕</div>            
          </div>
          <div className="TableLayout__counter">
            <FormattedMessage id="total" /> <TableCounter {...props} />
          </div>
          <div className="TableLayout__settings-icon-inner">
            <ColumnsSettings filter={this.props.config.objectName} />
          </div>
          <div className="TableLayout__controls">
            <CreateCard type={props.config.objectName} tabId={props.tab.tabId} />
          </div>
        </div>
        <div className="TableLayout__body">
          <TableContainer 
            filters={otherParams}
            filter={this.props.config.objectName}
            {...props} 
          />
        </div>
      </div>
    )
  }

}

export default connect(() => ({}))(withRouter(TableLayout))
