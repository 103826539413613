import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import camelCase from 'lodash/camelCase'

import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'

import LabelWrapper from './LabelWrapper'
import Button from './Button'

import { updateColumnsSettings } from '../redux/actions/settingsActions'
import getTableConfig from '../utils/getTableConfigByType'
import config from '../config'
import { defaults as tableDefaultSettings } from './TableComponent'

const Option = Select.Option

const searchConfig = config.pages.searchTable

class ColumnsSettings extends PureComponent {

  constructor() {
    super()
    this.state = {
      isOpen: false,
      settings: {
        show: [],
        fixed: [],
        width: {}
      }
    }
  }

  saveDefaultSettings() {
    const sectionName = (this.props.filter == null) ? 'search' : camelCase(this.props.filter)

    const columnsConfig = (this.props.filter == null)
      ? searchConfig.table
      : getTableConfig(sectionName).table

    if (!this.props.settings.columns[sectionName]) {
      this.props.dispatch(updateColumnsSettings(
        sectionName,
        {
          show: columnsConfig.map(item => item.title),
          fix: columnsConfig.filter(item => item.fixed).map(item => item.title),
          width: Object.fromEntries(
            columnsConfig.map(item => [
              item.title,
              item.width ? item.width : tableDefaultSettings.cellWidth
            ])
          )
        }
      ))
    }
  }

  componentDidMount() {
    this.saveDefaultSettings = this.saveDefaultSettings.bind(this)
    this.saveDefaultSettings()
  }

  componentDidUpdate() {
    this.saveDefaultSettings()
  }

  render() {
    const { intl } = this.props

    const sectionName = (this.props.filter == null) ? 'search' : camelCase(this.props.filter)

    const footerContent = [
      <Button key="cancel" type="inline" onClick={() => this.setState({ isOpen: false })}>
        <FormattedMessage id="cancel" />
      </Button>,
      <Button key="create" onClick={() => {
        this.props.dispatch(updateColumnsSettings(sectionName, this.state.settings))
        this.setState({ isOpen: false })
      }}>
        <FormattedMessage id="filters.apply" />
      </Button>
    ]

    const columnsConfig = (this.props.filter == null)
      ? searchConfig.table
      : getTableConfig(sectionName).table

    return (
      <>
        <Icon
          type="setting"
          className={"SearchLayout__filters-icon"}
          onClick={() => {
            this.setState({
              isOpen: true,
              settings: this.props.settings.columns[sectionName]
            })
          }}
        />

        <Modal
          className="CreateCard__modal"
          visible={this.state.isOpen}
          title={<FormattedMessage id={'columns-settings.title'} />}
          onCancel={() => this.setState({ isOpen: false })}
          footer={footerContent}
        >
          <div style={{ marginTop: '20px' }}>

            <div className="SelectComponent">
              <LabelWrapper label="columns-settings.show">
                <Select
                  placeholder={<FormattedMessage id="filters.select" />}
                  allowClear
                  notFoundContent={<FormattedMessage id="input.no-results" />}
                  defaultActiveFirstOption={false}
                  onChange={value => {
                    let width = Object.assign({}, this.state.settings.width)

                    for (let title of value) {
                      if (!(title in width)) {
                        const column = columnsConfig.find(item => item.title === title)
                        width[title] = column.width
                      }
                    }

                    this.setState({
                      settings: {
                        ...this.state.settings,
                        show: value,
                        fix: this.state.settings.fix.filter(title => value.includes(title)),
                        width
                      }
                    })
                  }}
                  style={{ width: '100%' }}
                  mode={'multiple'}
                  value={this.state.settings.show}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    columnsConfig
                      .map(column =>
                        <Option key={column.title} value={column.title}>
                          {intl.formatMessage({ id: column.filterTitle || column.title })}
                        </Option>
                      )
                  }
                </Select>
              </LabelWrapper>
            </div>

            <div className="SelectComponent">
              <LabelWrapper label="columns-settings.fix">
                <Select
                  placeholder={<FormattedMessage id="filters.select" />}
                  allowClear
                  notFoundContent={<FormattedMessage id="input.no-results" />}
                  defaultActiveFirstOption={false}
                  onChange={value =>
                    this.setState({
                      settings: {
                        ...this.state.settings,
                        fix: value
                      }
                    })
                  }
                  style={{ width: '100%' }}
                  mode={'multiple'}
                  value={this.state.settings.fix}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    columnsConfig
                      .filter(column =>
                        this.state.settings.show.includes(column.title)
                      )
                      .map(column =>
                        <Option key={column.title} value={column.title}>
                          {intl.formatMessage({ id: column.filterTitle || column.title })}
                        </Option>
                      )
                  }
                </Select>
              </LabelWrapper>
            </div>

          </div>
        </Modal>
      </>
    )
  }

}

const mapStateToProps = function (state, props) {
  return {
    settings: state.settings
  }
}

export default connect(mapStateToProps)(injectIntl(ColumnsSettings))