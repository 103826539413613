import merge from 'lodash/merge'

const menu = (state = {}, action) => {
  switch (action.type) {
    case 'MENU_TOGGLE':
      if (!state.hiddenItems) state.hiddenItems = {}
      if (state.hiddenItems[action.title]) {
        state.hiddenItems[action.title] = false
      } else {
        state.hiddenItems[action.title] = true
      }
      return merge({}, state)
    default:
      return state
  }
}

export default menu
