import React from 'react'
import moment from 'moment'

export default function (date, options = {shifted: true}) {
  if (!date) return null
  if (date.length === 4) {
    if (options.shifted) {
      return <span style={{whiteSpace: 'pre'}}>{' '.repeat(11) + date}</span>
    }
    return <span>{date}</span>
  }
  if (date.length === 7) return <span style={{whiteSpace: 'pre'}}>{' '.repeat(6) + moment(date).format('MM.YYYY')}</span>
  if (date.length === 10) return <span>{moment(date).format('DD.MM.YYYY')}</span>
  if (date.length === 24) return <span>{moment(date).format('DD.MM.YYYY')}</span>
  return null
}
