import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import build from 'redux-object'
import { injectIntl } from 'react-intl'
import debounce from 'lodash/debounce'
import 'antd/dist/antd.css'
import { get } from '../redux/actions/apiActions'
import camelCase from 'lodash/camelCase'
import AutoCompleteStyled from '../fieldsStyled/AutoCompleteStyled'

class AutoCompleteComponent extends PureComponent {

  constructor () {
    super()
    this.handleSearch = debounce(this.handleSearchRaw, 300)
  }

  componentWillMount () {
    this.props.dispatch(get(this.props.endpoint, this.props.endpointParams))
  }

  render () {
    const { style, data, label, placeholder, value } = this.props
    const dataSource = data && Array.isArray(data) ? data.map(e => e.name) : undefined
    return <AutoCompleteStyled
      value={value}
      label={label}
      placeholder={placeholder}
      dataSource={dataSource}
      style={style}
      onChange={this.handleChange.bind(this)}
      onSearch={this.handleSearch.bind(this)}
    />
  }

  handleSearchRaw (value) {
    const params = {
      ...this.props.endpointParams, 
      'filter[name][infix]': value,
      'page[size]': 20
    }
    this.props.dispatch(get(
      this.props.endpoint, 
      params
    ))
  }

  handleChange (newValue) {
    if (this.props.onChange) this.props.onChange(newValue)
  }

}

function mapStateToProps (state, props) {
  const requestMeta = state.data.meta[props.endpoint]

  if (requestMeta == null) {
    if (!props.value) return {}
    const singleValue = Array.isArray(props.value) ? props.value[0] : props.value
    if (!singleValue) return {}
    return {data: build(state.data, singleValue.type, null, {includeType: true})}
  }

  const latestRequest = Object.keys(requestMeta).sort((a, b) => {
    if (requestMeta[a].requestedAt < requestMeta[b].requestedAt) return 1
    if (requestMeta[a].requestedAt > requestMeta[b].requestedAt) return -1
    return 0
  })
  
  if (latestRequest == null || latestRequest[0] == null) return []

  const requestData = requestMeta[latestRequest[0]].data
  
  if (!requestData || !Array.isArray(requestData)) return {}
  return {data: build(state.data, camelCase(props.endpoint.substring(1)), requestData.map(e => e.id), {includeType: true})}
}

export default connect(mapStateToProps)(injectIntl(AutoCompleteComponent))
