import merge from 'lodash/merge'

const files = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_FILE':
      return {
        ...state,
        [action.udid]: {
          ...action.fileObject,
          udid: action.udid,
          documentId: action.documentId,
          isAborting: false
        }
      }
    case 'REMOVE_FILE':
      delete state[action.udid]
      return merge({}, state)
    case 'ABORT_FILE_LOADING':
      state[action.udid].isAborting = true
      return merge({}, state)
    case 'UPDATE_FILE_PROGRESS':
      state[action.udid].progress = action.progress
      return merge({}, state)
    case 'UPDATE_FILE_ID':
      state[action.udid].id = action.id
      return merge({}, state)
    case 'UPDATE_FILE_DATA':
      state[action.udid] = {...state[action.udid], ...action.data}
      return merge({}, state)
    case 'UPDATE_FILE_ERROR':
      delete state[action.udid]
      return merge({}, state)
    default:
      return state
  }
}

export default files
