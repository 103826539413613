import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import getSearchTabs from '../utils/getSearchTabs'
import TabsComponent from '../components/TabsComponent'
import mapSeachResultsFull from '../redux/mapers/mapSeachResultsFull'

class SearchTabs extends PureComponent {

  render () {
    const tabs = getSearchTabs(this.props.meta, this.props.filter)
    return <TabsComponent
      tabs={tabs}
      onClick={this.processClick.bind(this)}
      activeType={this.props.filter}
    />
  }

  processClick (e) {
    if (this.props.onClick) this.props.onClick(e)
  }

}

function mapStateToProps (state, props) {
  return {meta: mapSeachResultsFull(state, props).meta}
}

export default connect(mapStateToProps)(SearchTabs)
