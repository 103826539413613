import React, { PureComponent } from 'react'
import Select from '../fieldsStyled/SelectStyled'
import range from 'lodash/range'
const Option = Select.Option

class DateSelector extends PureComponent {

  constructor () {
    super()
    this.state = {}
    this.setGe = this.setGe.bind(this)
    this.setLe = this.setLe.bind(this)
  }

  render () {
    let { min, max, inline, externalValue, minValue, maxValue } = this.props
    if (min == null) min = 0
    if (max == null) max = (new Date()).getFullYear()

    return <div className={"DateSelector " + (inline ? 'DateSelector-inline' : '')}>
      <Select
        getPopupContainer={triggerNode => inline ? document.body : triggerNode.parentNode.parentNode}
        hideSelectIcon
        style={{'width': '100px'}}
        placeholder="From"
        value={(externalValue ? minValue : this.state.ge) || min}
        onChange={this.setGe.bind(this)}
      >
        {range(min, max + 1).map(year => {
          return <Option key={year} value={year}>{year}</Option>
        })}
      </Select>
      {inline && (
        <div className="DateSelector__separator"> ~ </div>
      )}
      <Select
        getPopupContainer={triggerNode => inline ? document.body : triggerNode.parentNode.parentNode}
        hideSelectIcon
        style={{'width': '100px'}}
        placeholder="To"
        value={(externalValue ? maxValue : this.state.le) || max}
        onChange={this.setLe.bind(this)}
      >
        {range(min, max + 1).map(year => {
          return <Option key={year} value={year}>{year}</Option>
        })}
      </Select>
    </div>
  }

  setGe (value) {
    this.setState({ge: value})
    this.props.onDateChange({...this.state, ge: value})
  }
  setLe (value) {
    this.setState({le: value})
    this.props.onDateChange({...this.state, le: value})
  }

}

export default DateSelector
