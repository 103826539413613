import { createMD5 } from 'hash-wasm'

const chunkSize = 16 * 1024 * 1024; // 16MB

function hashChunk(chunk, fileReader, hasher) {
  return new Promise((resolve, reject) => {
    fileReader.onload = async(e) => {
      const view = new Uint8Array(e.target.result);
      hasher.update(view);
      resolve();
    };

    fileReader.readAsArrayBuffer(chunk);
  });
}

const chunkCount = function(file) {
  return Math.floor(file.size / chunkSize)
}

const calcMD5 = async(file, onerror, onloadstart) => {
  const fileReader = new FileReader();
  fileReader.onerror = onerror
  fileReader.onloadstart = onloadstart

  const hasher = await createMD5();

  const chunkNumber = chunkCount(file)

  for (let i = 0; i <= chunkNumber; i++) {
    console.log("Getting chunk ", i);
    const chunk = file.slice(
      chunkSize * i,
      Math.min(chunkSize * (i + 1), file.size)
    );
    await hashChunk(chunk, fileReader, hasher);
  }

  const hash = hasher.digest();
  return Promise.resolve(hash);
};

export {calcMD5, chunkCount}
