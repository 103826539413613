import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import pointer from 'json-pointer'
import Route from 'route-parser'
import Button from '../components/Button'
import { create } from '../redux/actions/apiActions'
import { injectIntl } from 'react-intl'
import Notification from './Notification'
import handleError from '../utils/handleError'

class MergeControls extends PureComponent {

  constructor () {
    super()
    this.merge = this.merge.bind(this)
  }

  render () {
    return <div className="CardControls">
      <div className="CardControls__left">
        <Button title="merge" onClick={this.merge} />
      </div>
    </div>
  }

  async merge () {
    console.log('this.props.tab.currentData', this.props.tab.currentData)
    if (
      !this.props.tab.currentData ||
      !this.props.tab.currentData.undefined ||
      !this.props.tab.currentData.undefined.undefined
    ) return

    let modifier = {}
    pointer.set(modifier, '/data/type', this.props.config.objectType)
    pointer.set(modifier, '/data', this.props.tab.currentData.undefined.undefined)

    const route = new Route(this.props.config.endpoint)
    const endpoint = route.reverse({guid: this.props.data.id})

    await this.props.dispatch(create(endpoint, modifier, (error, data, normalised) => {
      if (error) return handleError(error, this.props.intl)
      Notification.success(this.props.intl.formatMessage({id: 'notification.card.update.success'}))
    }))
  }

}

export default connect(() => ({}))(injectIntl(MergeControls))
