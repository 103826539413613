const user = (state = {}, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state, ...action.user
      }
    case 'LOGOUT':
      return {}
    case 'API_DATA_SUCCESS':
      if (action.endpoint !== '/signin') return state
      const data = action.response.data.attributes
      return {
        ...state,
        ...data,
        user_role: action.response.data.relationships.user_role.data
      }
    default:
      return state
  }
}

export default user
