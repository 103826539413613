import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Table, Column, Cell, Plugins } from 'fixed-data-table-2'
import 'fixed-data-table-2/dist/fixed-data-table.css'
import { FormattedMessage } from 'react-intl'
import debounce from 'lodash/debounce'
import { setTableSize } from '../redux/actions/setTableSize'
import Ellipsis from './EllipsisComponent'
import Spin from 'antd/lib/spin'
import Icon from 'antd/lib/icon'
import camelCase from 'lodash/camelCase'
import { openNewTab } from '../redux/actions/manipulateTabs'
import { updateColumnWidth } from '../redux/actions/settingsActions'

export const defaults = {
  pageSize: 100,
  rowHeight: 30,
  rowsCount: 10000,
  tableWidth: 1200,
  tableHeight: 800,
  headerHeight: 30,
  cellWidth: 100,
  minCellWidth: 50
}

const getSortSymbol = function (column, sortBy) {
  if (!sortBy || !column.sortBy) return null
  const index = sortBy.indexOf(column.sortBy)
  if (index < 0 || index > 1) return null
  return (index === 0) ? '↓ ' : '↑ '
}

class Content extends PureComponent {

  componentDidMount() {
    this.adjustTableSize()
    window.addEventListener('resize', this.adjustTableSize.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.adjustTableSize)
  }

  headerDataRenderer(columnConfig, sortBy, sortTable) {
    const sectionName = (this.props.filter == null) ? 'search' : camelCase(this.props.filter)
    let columnsSettings = this.props.settings.columns[sectionName]

    return (
      <Plugins.ResizeCell
        onColumnResizeEnd={(newColumnWidth, columnKey) => {
          this.props.dispatch(updateColumnWidth(
            sectionName,
            columnKey,
            newColumnWidth > defaults.minCellWidth ?
              newColumnWidth :
              defaults.minCellWidth
          ))
        }}
        columnKey={columnConfig.title}
        width={columnsSettings.width[columnConfig.title]}
        height={defaults.headerHeight}
      >
        <Cell>
          <div
            className={columnConfig.sortBy ? 'TableComponent__sortable' : ''}
            onClick={sortTable ? sortTable.bind(null, columnConfig.sortBy) : undefined}
          >
            &nbsp;
            <Ellipsis>
              {getSortSymbol(columnConfig, sortBy)}
              <FormattedMessage id={columnConfig.title} />
            </Ellipsis>
          </div>
        </Cell>
      </Plugins.ResizeCell>
    )
  }

  cellDataRenderer(cellProps, columnConfig, rows) {
    if (typeof columnConfig.getValue !== 'function') return null
    const row = rows[cellProps.rowIndex]
    if (row == null && cellProps.columnKey !== 0) return null
    if (row == null) return <Cell><Ellipsis>{cellProps.rowIndex}</Ellipsis></Cell>
    const field = columnConfig.getValue(row)
    if (field == null) return null
    const cell = <Cell><Ellipsis>{field}</Ellipsis></Cell>
    if (row.url == null || columnConfig.disableLink) return cell

    return (
      <Link to={row.url} onClick={e => {
        e.preventDefault()
        this.props.dispatch(openNewTab(row.url, true))
      }}>
        {cell}
      </Link>
    )
  }

  render() {
    const { rows, columnsConfig, loading, total, width, height, sortBy } = this.props

    const sectionName = (this.props.filter == null) ? 'search' : camelCase(this.props.filter)
    let columnsSettings = this.props.settings.columns[sectionName]

    if (!columnsSettings) {
      return null
    }

    return <div className="TableComponent" ref={dom => (this.dom = dom)}>
      <Table
        rowHeight={defaults.rowHeight}
        rowsCount={total || 0}
        width={width || defaults.tableWidth}
        height={height || defaults.tableHeight}
        headerHeight={defaults.headerHeight}
        onScrollEnd={this.onScroll.bind(this)}
        rowClassNameGetter={(index) => {
          if (!rows[index]) return ''
          return rows[index].viewedByCurrentUser ? 'public_fixedDataTableRow_viewed' : ''
        }}
      >
        {columnsConfig
          .filter(columnConfig =>
            !columnsSettings ||
            columnsSettings.show.includes(columnConfig.title)
          )
          .map((columnConfig, index) => {
            return <Column
              key={columnConfig.title}
              columnKey={index}
              header={headerProps => this.headerDataRenderer(
                columnConfig,
                sortBy,
                this.props.sortTable
              )}
              cell={cellProps => this.cellDataRenderer(cellProps, columnConfig, rows)}
              width={columnsSettings.width[columnConfig.title]}
              fixed={columnsSettings.fix.includes(columnConfig.title) || false}
            />
          })}
      </Table>
      <div className="TableComponent__info">
        {total === 0 && <FormattedMessage id="search.no-results" />}
      </div>
      <div className="TableComponent__loader">
        {loading === true && <Spin indicator={<Icon type="loading" style={{ fontSize: 40, color: '#30BFB3' }} spin />} />}
      </div>
    </div>
  }

  adjustTableSize() {
    debounce(() => {
      if (this.dom == null) return
      const { width, height } = this.props
      const rect = this.dom.getBoundingClientRect()
      if (rect.width === width && rect.height === height) return
      this.props.dispatch(setTableSize(rect.width, rect.height))
    }, 150)()
  }

  onScroll(bodyTop, scrollTop) {
    const pageTop = Math.floor((scrollTop) / (defaults.pageSize * defaults.rowHeight)) + 1
    const pageBottom = Math.floor((scrollTop + defaults.tableHeight) / (defaults.pageSize * defaults.rowHeight)) + 1
    if (pageTop === pageBottom) return this.props.requestNewPages([pageTop])
    return this.props.requestNewPages([pageTop, pageBottom])
  }

}

Content.propTypes = {
  dispatch: PropTypes.func.isRequired,
  rows: PropTypes.object.isRequired,
  total: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number
}

function mapStateToProps(state) {
  const { width, height } = state.table || {}
  return {
    width,
    height,
    settings: state.settings
  }
}

export default connect(mapStateToProps)(Content)
