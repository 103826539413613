export default {
  componentType: 'card',
  endpoint: '/lto(/:guid)',
  objectName: 'lto',
  url: '/lto/:guid',
  getUrl: data => '/lto/' + data.id,
  title: 'lto',
  getValue: data => data.name,
  helpLink: 'https://app.raan.garagemca.org/docs/docs/lto/',
  tabs: [
    {
      title: 'tab.basis',
      type: 'basis',
      children: [
        {
          title: 'lto.tape',
          icon: 'icons/storage.svg',
          required: true,
          components: [
            {name: 'nobr', disabled: true, fieldName: 'name', label: 'title', placeholder: 'title', style: {width: '100%'}},
            {name: 'nobr', disabled: true, fieldName: 'storedFilesSizeHuman', label: 'lto.stored-files-size', placeholder: 'lto.stored-files-size', style: {width: '100%'}},
            {name: 'input', disabled: true, fieldName: 'indexFileUrl', label: 'lto.index-file', placeholder: 'lto.index-file', style: {width: '100%'}, externalLink: ' '},
            {name: 'Bool', disabled: true, fieldName: 'finalized', label: 'lto.finalized', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'lto.status',
          icon: 'icons/comment-icon.svg',
          components: [
            {name: 'Bool', fieldName: 'copyAExists', label: 'lto.copy-a', style: {width: '100%'}},
            {name: 'Bool', fieldName: 'copyBExists', label: 'lto.copy-b', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'comment',
          icon: 'icons/comment.svg',
          components: {name: 'text', fieldName: 'notes', placeholder: 'comment', style: {width: '100%'}},
          side: 'right'
        }
      ]
    }
  ]
}
