import formatDate from '../utils/formatDate'

export default {
  componentType: 'search',
  objectName: 'institution',
  sortLevel: 20,
  defaultSorting: '-created_at',
  url: '/search/?filter=institution',
  title: 'institutions',
  getUrl: data => '/institutions/' + data.id,
  table: [
    {
      title: 'code',
      width: 200,
      fixed: true,
      sortBy: 'code',
      getValue: data => data.code
    },
    {
      title: 'title',
      width: 450,
      fixed: true,
      sortBy: 'name',
      getValue: data => data.name
    },
    {
      title: 'titleEn',
      width: 450,
      sortBy: 'name_en',
      getValue: data => data.nameEn
    },
    {
      title: 'published',
      width: 105,
      sortBy: 'published_on_web.name',
      filterBy: 'published_on_web',
      getValue: data => data.publishedOnWeb ? data.publishedOnWeb.name : null
    },
    {
      title: 'users.email',
      width: 200,
      sortBy: 'email',
      getValue: data => data.email
    },
    {
      title: 'institutions.phone',
      width: 200,
      sortBy: 'phone',
      getValue: data => data.phone
    },
    
    {
      title: 'createdAt',
      width: 120,
      sortBy: 'created_at',
      getValue: data => formatDate(data.createdAt)
    }
  ]
}

