import React from 'react'
import { FormattedMessage } from 'react-intl'

import ValidationWarning from '../components/ValidationWarning'
import formatDate from '../utils/formatDate'

export default {
  componentType: 'search',
  objectName: 'thing',
  sortLevel: 10,
  defaultSorting: '-created_at',
  url: '/search/?filter=thing',
  title: 'things',
  getUrl: data => '/things/' + data.id,
  table: [
    {
      title: 'empty',
      width: 50,
      fixed: true,
      sortBy: 'has_validation_warnings',
      filterTitle: 'validation-warnings',
      filterBy: 'has_validation_warnings',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: data => {
        const validationWarnings = data.metadata && data.metadata.validationWarnings ?
          data.metadata.validationWarnings : null

        return <ValidationWarning validationWarnings={validationWarnings} />
      }
    },
    {
      title: 'kind',
      width: 200,
      fixed: true,
      sortBy: 'kind.name',
      filterBy: 'kind?filter[id][prefix]=document.thing',
      filterMode: 'multiple',
      getValue: data => data.kind ? data.kind.name : null
    },
    {
      title: 'title',
      width: 300,
      fixed: true,
      sortBy: 'name',
      getValue: data => data.name
    },
    {
      title: 'code-inv',
      width: 200,
      sortBy: 'code',
      getValue: data => data.code || data.inv
    },
    {
      title: 'collection',
      width: 350,
      sortBy: 'collection.name',
      filterBy: 'collection?page[size]=all&filter[relationships][institution][id]=all&sort=-documents_count_including_unpublished',
      filterMode: 'multiple',
      getValue: data => data.collection ? data.collection.name : null
    },
    {
      title: 'documents.published',
      width: 120,
      sortBy: 'published_on_web.name',
      filterBy: 'published_on_web',
      getValue: data => data.publishedOnWeb ? data.publishedOnWeb.name : null
    },
    {
      title: 'documents.files',
      width: 60,
      sortBy: 'files_count',
      getValue: data => data.filesCount
    },
    {
      title: 'reviewedByEditor',
      width: 175,
      sortBy: 'reviewed_by_editor',
      filterBy: 'reviewed_by_editor',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: data => data.reviewedByEditor ?
        <FormattedMessage id="yes" /> :
        <FormattedMessage id="no" />
    },
    {
      title: 'needsVerificationByEditor',
      width: 175,
      sortBy: 'needs_verification_by_editor',
      filterBy: 'needs_verification_by_editor',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: data => data.needsVerificationByEditor ?
        <FormattedMessage id="yes" /> :
        <FormattedMessage id="no" />
    },
    {
      title: 'createdAt',
      width: 120,
      sortBy: 'created_at',
      getValue: data => formatDate(data.createdAt)
    },
    {
      title: 'individuals.table.createdBy',
      width: 165,
      sortBy: 'created_by',
      filterBy: 'user?filter[created_type]=thing',
      filterMode: 'multiple',
      getValue: data => data.createdBy
    }
  ]
}
