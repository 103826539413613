import formatDate from '../utils/formatDate'
import formatDateTime from '../utils/formatDateTime'

export default {
  componentType: 'search',
  objectName: 'user',
  sortLevel: 21,
  defaultSorting: '-last_used_client_updated_at',
  url: '/search/?filter=user',
  title: 'users',
  getUrl: data => '/users/' + data.id,
  table: [
    {
      title: 'institution',
      width: 180,
      fixed: true,
      sortBy: 'institution.code',
      filterBy: 'institution?response[type]=short',
      getValue: data => data.institution ? data.institution.code : null
    },
    {
      title: 'users.name',
      width: 180,
      fixed: true,
      sortBy: 'name',
      getValue: data => data.name
    },
    {
      title: 'users.role',
      width: 150,
      fixed: true,
      sortBy: 'user_role.name',
      getValue: data => data.userRole ? data.userRole.name : null
    },
    {
      title: 'users.lastname',
      width: 180,
      fixed: false,
      sortBy: 'lastname_ru',
      getValue: data => data.lastnameRu
    },
    {
      title: 'users.firstname',
      width: 180,
      fixed: false,
      sortBy: 'firstname_ru',
      getValue: data => data.firstnameRu
    },

    {
      title: 'users.email',
      width: 300,
      fixed: false,
      sortBy: 'email',
      getValue: data => data.email
    },
    {
      title: 'users.lastseen',
      width: 150,
      sortBy: 'last_used_client_updated_at',
      getValue: data => formatDateTime(data.lastUsedClientUpdatedAt)
    },
    {
      title: 'users.lastversion',
      width: 180,
      sortBy: 'last_used_client',
      getValue: data => data.lastUsedClient
    },
    {
      title: 'users.file-access-level',
      width: 250,
      sortBy: 'file_access_level.name',
      getValue: data => data.fileAccessLevel ? data.fileAccessLevel.name : null
    },
    {
      title: 'users.createdAt',
      width: 150,
      sortBy: 'created_at',
      getValue: data => formatDate(data.createdAt)
    }
    // {
    //   title: 'individuals.table.createdBy',
    //   width: 120,
    //   sortBy: 'created_by',
    //   getValue: data => data.createdBy
    // }
  ]
}
