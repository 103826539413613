import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import jsonPointer from 'json-pointer'
import TabsComponent from '../components/TabsComponent'
import FieldsComponent from '../components/FieldsComponent'
import CardControls from '../components/CardControls'
import ShadowScroll from '../layouts/ShadowScroll'
import getPropsFromUrl from '../utils/getPropsFromUrl'
import { navigateTab } from '../redux/actions/manipulateTabs'
import { closeActiveTabPopUp } from '../redux/actions/editingCard'
import ExternalLink from '../components/ExternalLink'
import HelpLink from '../components/HelpLink'
import Spin from 'antd/lib/spin'
import CreateCard from '../components/CreateCard'
import ValidationWarning from '../components/ValidationWarning'
import Icon from 'antd/lib/icon'
import copyTextToClipboard from '../utils/copyTextToClipboard'

const getTabAmount = (tabConfig, data, changes) => {
  if (tabConfig.type === 'basis') return 0
  var total = 0
  for (const field of tabConfig.children) {
    if (!field.components) continue
    if (Array.isArray(field.components)) {
      total += field.components.map(component => {
        return getComponentAmount(component, data, changes)
      }).reduce((e, sum) => sum + e)
    } else {
      total += getComponentAmount(field.components, data, changes)
    }
  }
  return total
}

const getComponentAmount = (component, data, changes) => {
  if (!component.name) return 0
  if (component.name === 'Relation') return getRelationAmount(component, data, changes)
  if (component.name === 'Files') return getFilesAmount(component, data, changes)
  if (component.name === 'TextList') return getTextListAmount(component, data, changes)
  if (component.name === 'text' || component.name === 'CKEditor') return getTextAmount(component, data, changes)
  return 0
}

const getFilesAmount = (component, data, changes) => {
  const initialValue = (data && component.relationship && data[component.relationship])
    ? data[component.relationship].filter(f => f.deleted !== true)
    : 0
  const value = initialValue
  return value.length || 0
}

const getRelationAmount = (component, data, changes) => {
  if (!data || !component.pointer || !jsonPointer.has(data, component.pointer)) return 0
  const initialValue = jsonPointer.get(data, component.pointer)
  let editedValue = null
  if (changes && component.pointer && jsonPointer.has(changes, component.pointer)) {
    editedValue = jsonPointer.get(changes, component.pointer)
  }
  let relations = editedValue || initialValue
  if (!relations || !Array.isArray(relations)) return 0
  if (component.components[1].fixed) {
    relations = relations.filter(relation => {
      if (!relation[component.components[1].relationship]) return false
      return relation[component.components[1].relationship].id === component.components[1].fixed
    })
  }
  if (component.filter) {
    relations = relations.filter(eachValue => {
      const key = Object.keys(component.filter)[0]
      if (!jsonPointer.has(eachValue, key)) return false
      const value = jsonPointer.get(eachValue, key)
      if (component.filter[key] instanceof RegExp) {
        // filter is regex
        return component.filter[key].test(value)
      }
      // filter is regular string
      return value === component.filter[key]
    })
  }
  return relations.length
}

const getTextListAmount = (component, data, changes) => {
  if (!data || !component.fieldName || !data[component.fieldName]) return 0
  return data[component.fieldName].length
}

const getTextAmount = (component, data, changes) => {
  if (!data || !component.fieldName || !data[component.fieldName] || data[component.fieldName].trim() === "") return 0
  return 1
}

class CardLayout extends PureComponent {

  renderAdditionalTitleData() {
    const { config, data } = this.props

    let titleData = []

    if (config.additionalTitleData) {
      config.additionalTitleData.forEach(item => {
        if (data[item.key]) {
          titleData.push(
            <div key={item.key} className="CardLayout__title-additional-data-item">
              <FormattedMessage id={item.label} />{' '}
              <span
                className="CardLayout__title-additional-data-value"
                onClick={() => copyTextToClipboard(data[item.key], this.props.intl)}
              >
                {data[item.key]}{' '}
                <Icon className="CardLayout__title-additional-data-copy" type="copy" />
              </span>
            </div>
          )
        }
      })
    }

    return titleData.length > 0 ?
      (
        <div className="CardLayout__title-additional-data">
          {titleData}
        </div>
      ) : null
  }

  render() {
    const { config, data, tab } = this.props
    const activeTabType = getPropsFromUrl(this.props.tab.path).tab
    const tabsComponentConfig = config.tabs.map(tabConfig => ({
      ...tabConfig,
      amount: getTabAmount(tabConfig, data, tab.changes)
    }))
    const cardTitle = (typeof config.getValue === 'function')
      ? config.getValue(data) || <FormattedMessage id={config.title} />
      : <FormattedMessage id={config.title} />
    const Controls = (config.customControls == null)
      ? CardControls
      : config.customControls

    const validationWarnings = data.metadata && data.metadata.validationWarnings ?
      data.metadata.validationWarnings : null

    return <div className="CardLayout">
      {
        tab.popup && tab.popup.is_open && (
          <div className="CardLayout__popup">
            <div className="CardLayout__popup-bg" onClick={this.closePopUp.bind(this)} />
            <img src={tab.popup.img_src} className="CardLayout__popup-img" alt="" />
          </div>
        )
      }
      <div className="CardLayout__title">
        <h2>
          {cardTitle}
          <ExternalLink url={data.webUrlRaan} />
          <ValidationWarning
            validationWarnings={validationWarnings}
            className={'ValidationWarning__big'}
          />
          {this.renderAdditionalTitleData()}
        </h2>

        {config.createFromCard && (
          <CreateCard
            type={config.objectName}
            tabId={this.props.tab.tabId}
            internalType={config.internalObjectName ? config.internalObjectName : config.objectName}
          />
        )}

        {config.helpLink && (
          <HelpLink url={config.helpLink} />
        )}

      </div>
      <div className="CardLayout__tabs">
        <TabsComponent
          tabs={tabsComponentConfig}
          onClick={this.changeTab.bind(this)}
          activeType={activeTabType}
        />
      </div>
      <ShadowScroll className="CardLayout__body">
        <div className="CardLayout__body-padding">
          <Spin indicator={<span />} delay={0} spinning={this.props.loading}>
            <FieldsComponent
              tabType={activeTabType}
              applicationTabId={this.props.tab.id}
              config={config}
              applicationDataId={this.props.data.id}
            />
          </Spin>
        </div>
      </ShadowScroll>
      <div className="CardLayout__footer">
        <Controls {...this.props} />
      </div>
    </div>
  }

  closePopUp() {
    this.props.dispatch(closeActiveTabPopUp())
  }

  changeTab(tabType) {
    const indexOfQuery = this.props.tab.path.indexOf('?')
    const cleanPath = (indexOfQuery >= 0) ? this.props.tab.path.substring(0, indexOfQuery) : this.props.tab.path
    const path = (tabType === 'basis')
      ? cleanPath
      : cleanPath + '?tab=' + tabType
    this.props.dispatch(navigateTab(path))
    this.props.history.push(path)
  }

}

export default connect(() => ({}))(withRouter(CardLayout))
