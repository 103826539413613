import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import config from '../config'
import { get } from '../redux/actions/apiActions'
import mapSeachResultsFull from '../redux/mapers/mapSeachResultsFull'
import TableComponent from '../components/TableComponent'
import generateSearchParams from '../utils/generateSearchParams'
import getTableConfig from '../utils/getTableConfigByType'
import camelCase from 'lodash/camelCase'
import debounce from 'lodash/debounce'
import handleError from '../utils/handleError'
import { injectIntl } from 'react-intl'

const searchConfig = config.pages.searchTable

const getCardConfig = function (type) {
  let cardConfig = {}
  for (let key in config.pages) {
    if (config.pages[key].componentType !== 'card') continue
    if (config.pages[key].objectName !== type) continue
    if (
      config.pages[key].internalObjectName &&
      config.pages[key].internalObjectName !== config.pages[key].objectName
    ) {
      continue
    }
    cardConfig = config.pages[key]
    break
  }
  return cardConfig
}

class SearchResultsFull extends PureComponent {

  constructor() {
    super()
    this.requestResultsDebounsed = debounce(this.requestResultsRaw.bind(this), 300)
    this.requestResults = (term, ...otherParams) => {
      const otherParamsJSON = JSON.stringify(otherParams)
      if (this.previousTerm === null || term !== this.previousTerm) {
        this.requestResultsDebounsed(term, ...otherParams)
      } else if (!this.previousOtherParams || otherParamsJSON !== this.previousOtherParams) {
        this.requestResultsRaw(term, ...otherParams)
      }
      this.previousTerm = term
      this.previousOtherParams = otherParamsJSON
    }
  }

  componentWillMount() {
    this.requestResults(this.props.term, { filter: this.props.filter, sort: this.props.sort, ...this.props.filters })
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.term === this.props.term &&
      nextProps.filter === this.props.filter &&
      nextProps.sort === this.props.sort &&
      nextProps.filters === this.props.filters
    ) return
    this.requestResults(nextProps.term, { filter: nextProps.filter, sort: nextProps.sort, ...nextProps.filters })
  }

  render() {
    const columnsConfig = (this.props.filter == null)
      ? searchConfig.table
      : getTableConfig(camelCase(this.props.filter)).table
    // const notFound = <div className="SearchResults__notfound"><FormattedMessage id="search.no-results" /></div>
    return <div className="SearchResults">
      {(this.props.meta.total > 0 || this.props.meta.total == null)
        ? this.renderTable.bind(this)(columnsConfig)
        : this.renderTable.bind(this)(columnsConfig)
      }
    </div>
  }

  renderTable(columnsConfig, rows) {
    if (this.props.filter == null) {
      for (let index in this.props.rows) {
        const cardConfig = getCardConfig(this.props.rows[index].type)
        this.props.rows[index].url = cardConfig.getUrl ? cardConfig.getUrl(this.props.rows[index]) : null
        this.props.rows[index].title = cardConfig.getValue ? cardConfig.getValue(this.props.rows[index]) : null
        this.props.rows[index].type = <FormattedMessage id={cardConfig.title || 'error.undefined-type'} />
      }
    } else {
      //const cardConfig = getCardConfig(camelCase(this.props.filter))
      const tableConfig = getTableConfig(camelCase(this.props.filter))
      if (typeof tableConfig.getUrl === 'function') {
        for (let index in this.props.rows) {
          this.props.rows[index].url = tableConfig.getUrl(this.props.rows[index])
        }
      }
    }
    return <TableComponent
      filter={this.props.filter}
      rows={rows || this.props.rows}
      columnsConfig={columnsConfig}
      total={this.props.meta.total}
      loading={this.props.loading}
      sortTable={this.sortTable.bind(this)}
      sortBy={this.props.sort}
      requestNewPages={this.requestNewPages.bind(this)}
    />
  }

  sortTable(sortBy) {
    const newSortBy = (sortBy === this.props.sort) ? '-' + sortBy : sortBy
    this.props.onSort(newSortBy)
  }

  requestNewPages(pageNumbers) {
    pageNumbers.forEach(pageNumber => {
      this.requestResults(this.props.term, { filter: this.props.filter, sort: this.props.sort, ...this.props.filters }, pageNumber)
    })
  }

  requestResultsRaw(term, params, page) {
    const requestParams = generateSearchParams(term, params, page)
    this.props.dispatch(get('/search', requestParams, this.handleInitialData.bind(this)))
  }

  handleInitialData(error, data, normalisedData) {
    if (error) return handleError(error, this.props.intl)
  }

}

export default connect(mapSeachResultsFull)(injectIntl(SearchResultsFull))
