import fetch from 'isomorphic-fetch'
import { version } from '../../../package.json'

if (!process.env.REACT_APP_API_HOST) {
  throw new Error('Please set REACT_APP_API_HOST in your .env file')
}

const API_ROOT = process.env.REACT_APP_API_HOST

export const API_DATA_REQUEST = 'API_DATA_REQUEST'
export const API_DATA_SUCCESS = 'API_DATA_SUCCESS'
export const API_DATA_FAILURE = 'API_DATA_FAILURE'

function callApi (endpoint, options = {}) {
  const fullUrl = (endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint

  return fetch(fullUrl, options)
    .then(response => {
      if (response.status === 204) {
        return ''
      }

      return response.json()
        .then((json) => {
          if (!response.ok) {
            return Promise.reject(json)
          }
          return Object.assign({}, json)
        })
    })
}

export const CALL_API = Symbol('Call API')

export default function (store) {
  return function nxt (next) {
    return function call (action) {
      const callAPI = action[CALL_API]

      if (typeof callAPI === 'undefined') {
        return next(action)
      }

      let { requestUrl, ...props } = callAPI
      let { options } = callAPI

      const state = store.getState()
      if (typeof requestUrl === 'function') {
        requestUrl = requestUrl(state)
      }

      let headers = {
        'Content-Type': 'application/json',
        'RAAN-Client': 'RAAN-App/' + version
      }

      const bearer = (state.user != null) ? state.user.access_token : null
      if (bearer) {
        headers['Authorization'] = 'Bearer ' + bearer
      }

      headers['Accept-Language'] = 'ru'

      if (typeof requestUrl !== 'string') {
        throw new Error('Specify a string endpoint URL.')
      }

      const actionWith = (data) => {
        const finalAction = Object.assign({}, action, data)
        delete finalAction[CALL_API]
        return finalAction
      }

      next(actionWith({ type: API_DATA_REQUEST, requestUrl, ...props }))

      return callApi(requestUrl, {...options, headers})
        .then(
          response => next(actionWith({ response, type: API_DATA_SUCCESS, requestUrl, ...props })),
          error => next(actionWith({ type: API_DATA_FAILURE, error: error || 'Something bad happened', ...props }))
        )
    }
  }
}