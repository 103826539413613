export default {
  componentType: 'card',
  endpoint: '/keyword(/:guid)',
  objectName: 'keyword',
  url: '/keywords/:guid',
  getUrl: data => '/keywords/' + data.id,
  title: 'keywords',
  titleCreate: 'keywords.create',
  getValue: data => data.name,
  tabs: [
    {
      title: 'tab.basis',
      type: 'basis',
      children: [
        {
          title: 'keywords',
          icon: 'icons/keywords-icon.svg',
          required: true,
          components: [
            {name: 'nobr', fieldName: 'name', label: 'title', placeholder: 'title', style: {width: '100%'}},
            {name: 'nobr', fieldName: 'nameEn', label: 'keywords.nameEn', placeholder: 'keywords.nameEn.placeholder', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'comment',
          icon: 'icons/documents.svg',
          components: [
            {name: 'text', fieldName: 'help', placeholder: 'keywords.comment.placeholder', style: {width: '100%'}}
          ],
          side: 'right'
        }
      ]
    }
  ]
}
