import React from 'react'
import {injectIntl} from 'react-intl'

const LabelWrapper = ({label, children, intl, style}) => {
  if (!label) return <label className="LabelWrapper" style={style}>{children}</label>
  return <label className="LabelWrapper" style={style}>
    <span className="LabelWrapper__text">
      {label && intl.formatMessage({id: label})}
    </span>
    {children}
  </label>
}

export default injectIntl(LabelWrapper)
