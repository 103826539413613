import React from 'react'
import { FormattedMessage } from 'react-intl'

import formatDate from '../utils/formatDate'

export default {
  componentType: 'search',
  objectName: 'article',
  sortLevel: 7,
  defaultSorting: '-created_at',
  url: '/search/?filter=article',
  title: 'articlesTable',
  getUrl: data => '/articles/' + data.id,
  table: [
    // {
    //   title: 'empty',
    //   width: 50,
    //   fixed: true,
    //   disableLink: true,
    //   getValue: data => {
    //     const validationWarnings = data.metadata && data.metadata.validationWarnings ? 
    //       data.metadata.validationWarnings : null

    //     return <ValidationWarning validationWarnings={validationWarnings} />
    //   }
    // },
    {
      title: 'title',
      width: 400,
      fixed: true,
      sortBy: 'name',
      getValue: data => data.name
    },
    {
      title: 'articles.periodical',
      width: 300,
      fixed: true,
      sortBy: 'periodical.name',
      getValue: data => data.periodical ? data.periodical.name : null
    },
    {
      title: 'articles.date',
      width: 100,
      sortBy: 'date_from',
      filterBy: 'date_from?filter[have_public_records]=article',
      getValue: data => formatDate(data.dateFrom)
    },
    {
      title: 'code-inv',
      width: 200,
      sortBy: 'code',
      getValue: data => data.code || data.inv
    },
    {
      title: 'collection',
      width: 350,
      sortBy: 'collection.name',
      filterBy: 'collection?page[size]=all&filter[relationships][institution][id]=all&sort=-documents_count_including_unpublished',
      filterMode: 'multiple',
      getValue: data => data.collection ? data.collection.name : null
    },
    {
      title: 'accessType',
      width: 180,
      sortBy: 'access_type.name',
      filterBy: 'access_type',
      getValue: data => data.accessType ? data.accessType.name : null
    },
    {
      title: 'articles.published',
      width: 105,
      sortBy: 'published_on_web.name',
      filterBy: 'published_on_web',
      getValue: data => data.publishedOnWeb ? data.publishedOnWeb.name : null
    },
    {
      title: 'articles.files',
      width: 70,
      sortBy: 'files_count',
      getValue: data => data.files.length
    },
    {
      title: 'reviewedByEditor',
      width: 175,
      sortBy: 'reviewed_by_editor',
      filterBy: 'reviewed_by_editor',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: data => data.reviewedByEditor ?
        <FormattedMessage id="yes" /> :
        <FormattedMessage id="no" />
    },
    {
      title: 'needsVerificationByEditor',
      width: 175,
      sortBy: 'needs_verification_by_editor',
      filterBy: 'needs_verification_by_editor',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: data => data.needsVerificationByEditor ?
        <FormattedMessage id="yes" /> :
        <FormattedMessage id="no" />
    },
    {
      title: 'articles.createdAt',
      width: 120,
      sortBy: 'created_at',
      getValue: data => formatDate(data.createdAt)
    },
    {
      title: 'individuals.table.createdBy',
      width: 165,
      sortBy: 'created_by',
      filterBy: 'user?filter[created_type]=article',
      filterMode: 'multiple',
      getValue: data => data.createdBy
    }
  ]
}
