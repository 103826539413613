import React from 'react'
import { editField } from '../redux/actions/editingCard'
import jsonPointer from 'json-pointer'
import addData from '../utils/addData'
import TimeField from 'react-simple-timefield'
import Input from '../fieldsStyled/Input'

const Duration = ({ type, fieldName, label, style, dispatch, tab, data, config }) => {
  if (!tab || !tab.currentData) return null

  const getObjectPointer = () => {
    return '/' + config.objectName + '/' + data.id + '/attributes/' + fieldName
  }

  const getValue = () => {
    const objectPointer = getObjectPointer()
    if (!jsonPointer.has(tab.currentData, objectPointer)) return '00:00:00'
    const seconds = jsonPointer.get(tab.currentData, objectPointer)
    const h = Math.floor(seconds / (60 * 60))
    const m = Math.floor((seconds - (h * 60 * 60)) / 60)
    const s = seconds - (h * 60 * 60) - (m * 60)
    const pad = (n) => ('00' + n).substr(-2)
    const formattedTime = `${pad(h)}:${pad(m)}:${pad(s)}`
    return formattedTime
  }

  const setValue = formattedTime => {
    const timeParts = formattedTime.split(':')
    const seconds = parseInt(timeParts[0], 10) * 60 * 60 + parseInt(timeParts[1], 10) * 60 + parseInt(timeParts[2], 10)
    const objectPointer = getObjectPointer()
    dispatch(editField(tab.id, objectPointer, seconds))
  }

  return <div className="CommonField" style={style}>
    <TimeField
      value={getValue()}
      label={label}
      onChange={setValue}
      input={<Input />}
      showSeconds
    />
  </div>
}

export default addData(Duration)
