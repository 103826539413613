export const updateColumnsSettings = (section, value) => ({
  type: 'UPDATE_COLUMNS_SETTINGS',
  section,
  value
})

export const updateColumnWidth = (section, columnKey, value) => ({
  type: 'UPDATE_COLUMN_WIDTH',
  section,
  columnKey,
  value
})