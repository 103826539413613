import React, { PureComponent } from 'react'
import SelectComponent from '../components/SelectComponent'
import jsonPointer from 'json-pointer'
import { storeFieldData } from '../redux/actions/editingCard'
import { create, update, remove, get } from '../redux/actions/apiActions'
import uuid from 'uuid'
import { subscribe } from '../utils/pubSub'
import { getNew, getChanged, getDeleted } from '../utils/comparison'
import addData from '../utils/addData'
import snakeCase from 'lodash/snakeCase'
import removeLazyLoading from '../utils/removeLazyLoading'

class Relation extends PureComponent {

  componentWillMount () {
    subscribe(this.props.tab.id, this.props.pointer, (type) => {
      if (type === 'cancel') return this.cancelChanges.apply(this)
      if (type === 'save') return this.saveChanges.apply(this)
    })
  }

  render () {
    const { tab } = this.props
    if (!tab.currentData) return null

    const value = this.getFilteredValue.apply(this)

    return <div className="Relation">
      {this.renderRelation({id: 'new'})}
      {value == null
        ? null
        : value.map(this.renderRelation.bind(this))
      }
    </div>
  }

  buildEndpointParams (endpointParams) {
    const {config, data, tab} = this.props
    let endpointParamsBuild = Object.assign({}, endpointParams)

    const getValueForRelation = (relationship) => {
      const objectPointer = '/' + config.objectName + '/' + data.id + '/relationships/' + relationship + '/data'
      const dataType = 'currentData'

      if (!jsonPointer.has(tab[dataType], objectPointer)) {
        return null
      }

      return jsonPointer.get(tab[dataType], objectPointer).id
    }

    for (let key in endpointParamsBuild) {
      if (typeof endpointParamsBuild[key] === 'function') {
        endpointParamsBuild[key] = endpointParamsBuild[key](getValueForRelation)
      }
    }
      
    return endpointParamsBuild
  }

  renderRelation (relation) {
    const { components } = this.props
    const isErrorShown = relation[components[0].relationship] && !relation[components[1].relationship]

    return <div className="Relation__each" key={relation.id}>
      {components[0].fixed ? null : <SelectComponent
        endpoint={components[0].endpoint || '/' + components[0].relationship}
        endpointParams={components[0].endpointParams}
        relationship={components[0].relationship}
        value={relation[components[0].relationship]}
        label={components[0].label}
        placeholder={components[0].placeholder}
        style={{width: components[1].fixed ? '100%' : '58%'}}
        createNew={components[0].createNew}
        createObjectName={components[0].createObjectName}
        onChange={this.setEditedValue.bind(this, relation.id, components[0].relationship)}
      />}
      {components[1].fixed ? null : <SelectComponent
        endpoint={components[1].endpoint || '/' + components[1].relationship}
        endpointParams={this.buildEndpointParams(components[1].endpointParams)}
        relationship={components[1].relationship}
        value={relation[components[1].relationship]}
        label={components[1].label}
        showError={isErrorShown}
        placeholder={components[1].placeholder}
        style={{width: '42%', paddingLeft: '20px'}}
        createNew={components[0].createNew}
        createObjectName={components[0].createObjectName}
        onChange={this.setEditedValue.bind(this, relation.id, components[1].relationship)}
      />}
      {relation.id !== 'new' && <div className="Relation__delete" onClick={this.deleteRelation.bind(this, relation.id)} />}
    </div>
  }

  getInitialValue () {
    const { data, pointer } = this.props
    if (!data || !jsonPointer.has(data, pointer)) return []
    return jsonPointer.get(data, pointer)
  }

  getEditedValue () {
    const { tab, pointer } = this.props
    if (!tab.changes || !jsonPointer.has(tab.changes, pointer)) return null
    return jsonPointer.get(tab.changes, pointer)
  }

  getValue () {
    return this.getEditedValue.apply(this) || this.getInitialValue.apply(this) || []
  }

  getFilteredValue () {
    let relations = this.getValue()
    const { components } = this.props
    if (Array.isArray(relations) && components[1].fixed) {
      relations = relations.filter(relation => {
        if (!relation[components[1].relationship]) return false
        return relation[components[1].relationship].id === components[1].fixed
      })
    }
    if (Array.isArray(relations) && this.props.filter) {
      relations = relations.filter(relation => {
        const key = Object.keys(this.props.filter)[0]
        if (!jsonPointer.has(relation, key)) return false
        const value = jsonPointer.get(relation, key)
        if (this.props.filter[key] instanceof RegExp) {
          // filter is regex
          return this.props.filter[key].test(value)
        }
        // filter is regular string
        return value === this.props.filter[key]
      })
    }
    const retVals = relations.slice().reverse()
    return retVals
  }

  setEditedValue (id, type, newValue) {
    const { tab, pointer, components } = this.props
    const previousValue = this.getValue.apply(this)
    if (id === 'new') {
      let newRelation = {
        id: uuid(),
        [type]: newValue
      }
      if (components[1].fixed) {
        const url = '/' + components[1].relationship + '/' + components[1].fixed
        this.props.dispatch(get(url, null, (error, result) => {
          if (error || !result.data) return
          newRelation[components[1].relationship] = result.data
          const update = [...previousValue, newRelation]
          this.props.dispatch(storeFieldData(tab.id, pointer, update))
        }))
      } else {
        const update = [...previousValue, newRelation]
        this.props.dispatch(storeFieldData(tab.id, pointer, update))
      }
    } else {
      let updates = previousValue.map(item => (
        Object.assign({}, removeLazyLoading(item, true))
      ))

      for (let i = 0; i < updates.length; i++) {
        if (updates[i].id !== id) continue
        if (updates[i]['__' + type]) {
          try { 
            updates[i]['__' + type] = newValue
          } catch (err) {
            console.log(err)
          }
        } else {
          updates[i][type] = newValue
        }
      }
    this.props.dispatch(storeFieldData(tab.id, pointer, updates, false))
  }
}

  deleteRelation (id) {
    const { tab, pointer } = this.props
    const previousValue = this.getValue.apply(this)
    const newValue = previousValue.filter(relation => relation.id !== id)
    this.props.dispatch(storeFieldData(tab.id, pointer, newValue))
  }

  cancelChanges (cb) {
    const { tab, pointer } = this.props
    this.props.dispatch(storeFieldData(tab.id, pointer, null))
    if (typeof cb === 'function') cb()
  }

  async saveChanges(cb) {
    const initialValue = this.getInitialValue.apply(this)
    const editedValue = this.getEditedValue.apply(this)
    if (editedValue == null) return
    const { components, config } = this.props
    const toDelete = getDeleted(initialValue, editedValue)
    const toAdd = getNew(initialValue, editedValue)
    const toChange = getChanged(initialValue, editedValue, [components[0].relationship, components[1].relationship])
    const endpoint = snakeCase(this.props.pointer)
    if (toDelete.length > 0) {
      for (const relation of toDelete) {
        await this.props.dispatch(remove('/' + endpoint + '/' + relation.id))
      }
    }
    if (toChange.length > 0) {
      const request = toChange.map(relation => ({
        id: relation.id,
        type: endpoint,
        relationships: {
          sibling: {
            data: {
              id: this.props.data.id,
              type: config.objectName
            }
          },
          [components[0].relationship]: {
            data: {
              ...relation[components[0].relationship],
              type: components[0].relationship
            }
          },
          [components[1].relationship]: {
            data: {
              id: components[1].fixed && !(relation[components[1].relationship] && relation[components[1].relationship].id) ? 
                components[1].fixed : relation[components[1].relationship].id,
              type: components[1].relationship
            }
          }
        }
      }))
      for (const each of request) {
        await this.props.dispatch(update('/' + endpoint + '/' + each.id, { data: each }))
      }
      // request.forEach(each => this.props.dispatch(update('/' + endpoint + '/' + each.id, {data: each})))
    }
    if (toAdd.length > 0) {
      const request = toAdd.map(relation => ({
        // id: relation.id,
        type: endpoint,
        relationships: {
          sibling: {
            data: {
              id: this.props.data.id,
              type: config.objectName
            }
          },
          [components[0].relationship]: {
            data: {
              ...relation[components[0].relationship],
              type: components[0].relationship
            }
          },
          [components[1].relationship]: {
            data: {
              id: components[1].fixed && !(relation[components[1].relationship] && relation[components[1].relationship].id) ? 
                components[1].fixed : (relation[components[1].relationship] && relation[components[1].relationship].id),
              type: components[1].relationship
            }
          }
        }
      }))
      for (const each of request) {
        await this.props.dispatch(create('/' + endpoint, { data: each }))
      }
      // request.forEach(each => this.props.dispatch(create('/' + endpoint, {data: each})))
      if (typeof cb === 'function') cb()
    }
  }

}

export default addData(Relation)
