import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import config from '../config'
import TableLayout from './TableLayout'
import CardContainer from '../containers/CardContainer'
import SearchLayout from './SearchLayout'
import Route from 'route-parser'

const pageLayouts = {
  table: TableLayout,
  card: CardContainer,
  search: SearchLayout
}

class TabLayout extends PureComponent {

  render () {
    const tab = this.props
    let pageName = Object.keys(config.pages).filter((pageName, index) => {
      const configuration = config.pages[pageName]
      const route = new Route(configuration.url)
      const match = route.match(tab.path)
      if (!match) return false
      return pageName
    })[0]
    if (pageName == null) pageName = 'searchTable'
    const configuration = config.pages[pageName]
    const component = pageLayouts[configuration.componentType]
    const route = new Route(configuration.url)
    const match = route.match(tab.path)
    return React.createElement(component, {config: configuration, match, tab})
  }

}

function mapStateToProps (state, params) {
  const tab = state.tabs.filter(tab => tab.id === params.tabId)[0]
  return {...tab, active: null}
}

export default connect(mapStateToProps)(TabLayout)
