import config from '../config'
import snakeCase from 'lodash/snakeCase'

const getCardConfigByType = (type) => {
  if (type == null) return null
  const pageName = Object.keys(config.pages).filter((pageName, index) => {
    if (config.pages[pageName].componentType !== 'card') return false
    return (
      (
        !config.pages[pageName].internalObjectName &&
        snakeCase(config.pages[pageName].objectName) === snakeCase(type)
      ) ||
      snakeCase(config.pages[pageName].internalObjectName) === snakeCase(type)
    )
  })[0]
  return config.pages[pageName]
}

export default getCardConfigByType
