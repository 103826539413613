import React, { PureComponent } from 'react'
import 'intl'
import { addLocaleData, IntlProvider } from 'react-intl'
import en from 'react-intl/locale-data/en'
import ru from 'react-intl/locale-data/ru'
import ruMessages from '../i18n/ru'

addLocaleData([...en, ...ru])

class Localize extends PureComponent {

  render () {
    return <IntlProvider locale="ru" messages={ruMessages}>
      {this.props.children}
    </IntlProvider>
  }

}

export default Localize
