import React from 'react'
import { FormattedMessage } from 'react-intl'

import ValidationWarning from '../components/ValidationWarning'
import formatDate from '../utils/formatDate'

export default {
  componentType: 'search',
  objectName: 'issue',
  sortLevel: 6,
  defaultSorting: '-created_at',
  url: '/search/?filter=issue',
  title: 'issuesTable',
  getUrl: data => '/issues/' + data.id,
  table: [
    {
      title: 'empty',
      width: 50,
      fixed: true,
      sortBy: 'has_validation_warnings',
      filterTitle: 'validation-warnings',
      filterBy: 'has_validation_warnings',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: data => {
        const validationWarnings = data.metadata && data.metadata.validationWarnings ?
          data.metadata.validationWarnings : null

        return <ValidationWarning validationWarnings={validationWarnings} />
      }
    },
    {
      title: 'issues.kind',
      width: 200,
      fixed: true,
      sortBy: 'periodical.kind.name',
      filterBy: 'kind?filter[id][prefix]=document.issue',
      filterMode: 'multiple',
      getValue: data => data.periodical ? data.periodical.kind ? data.periodical.kind.name : null : null
    },
    {
      title: 'issues.periodical',
      width: 300,
      fixed: true,
      sortBy: 'periodical.name',
      getValue: data => data.periodical ? data.periodical.name : null
    },
    {
      title: 'inv',
      width: 100,
      sortBy: 'inv_int',
      getValue: data => data.inv
    },
    {
      title: 'institution',
      width: 180,
      sortBy: 'institution.code',
      filterBy: 'institution?response[type]=short',
      getValue: data => data.institution ? data.institution.code : null
    },
    {
      title: 'issues.volume',
      width: 50,
      sortBy: 'volume',
      getValue: data => data.volumeStr
    },
    {
      title: 'issues.number',
      width: 100,
      sortBy: 'number',
      getValue: data => data.numberStr
    },
    {
      title: 'issues.date',
      width: 100,
      sortBy: 'date_from',
      filterBy: 'date_from?filter[have_public_records]=issue',
      getValue: data => formatDate(data.dateFrom)
    },
    {
      title: 'accessType',
      width: 120,
      sortBy: 'access_type.name',
      filterBy: 'access_type',
      getValue: data => data.accessType ? data.accessType.name : null
    },
    {
      title: 'issues.published',
      width: 105,
      sortBy: 'published_on_web.name',
      filterBy: 'published_on_web',
      getValue: data => data.publishedOnWeb ? data.publishedOnWeb.name : null
    },
    {
      title: 'issues.files',
      width: 70,
      sortBy: 'files_count',
      getValue: data => data.files.length
    },
    {
      title: 'collection',
      width: 350,
      sortBy: 'collection.name',
      filterBy: 'collection?page[size]=all&filter[relationships][institution][id]=all&sort=-documents_count_including_unpublished',
      filterMode: 'multiple',
      getValue: data => data.collection ? data.collection.name : null
    },
    {
      title: 'reviewedByEditor',
      width: 175,
      sortBy: 'reviewed_by_editor',
      filterBy: 'reviewed_by_editor',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: data => data.reviewedByEditor ?
        <FormattedMessage id="yes" /> :
        <FormattedMessage id="no" />
    },
    {
      title: 'needsVerificationByEditor',
      width: 175,
      sortBy: 'needs_verification_by_editor',
      filterBy: 'needs_verification_by_editor',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: data => data.needsVerificationByEditor ?
        <FormattedMessage id="yes" /> :
        <FormattedMessage id="no" />
    },
    {
      title: 'issues.createdAt',
      width: 100,
      sortBy: 'created_at',
      getValue: data => formatDate(data.createdAt)
    },
    {
      title: 'individuals.table.createdBy',
      width: 165,
      sortBy: 'created_by',
      filterBy: 'user?filter[created_type]=issue',
      filterMode: 'multiple',
      getValue: data => data.createdBy
    }
  ]
}
