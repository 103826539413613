import React, { PureComponent } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import Route from 'route-parser'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Popconfirm from 'antd/lib/popconfirm'

import { create, get } from '../redux/actions/apiActions'
import { addInitialData } from '../redux/actions/editingCard'
import Button from './Button'
import Notification from './Notification'

class ActionButton extends PureComponent {
  
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false
    }
  }

  doAction () {
    const dataId = this.props.applicationDataId
    if (!dataId) return

    const tabId = this.props.applicationTabId

    const route = new Route(this.props.item.endpoint)
    const endpoint = route.reverse({guid: dataId})

    this.setState({isLoading: true})

    this.props.dispatch(create(
      endpoint, 
      {},
      (error, data) => {
        this.setState({isLoading: false})
        
        if (error && error.errors) {
          const errorText = error.errors && error.errors[0] ? error.errors[0].detail : JSON.stringify(error)
          return Notification.error(errorText)
        }
        
        const dataRoute = new Route(this.props.endpoint)
        const dataEndpoint = dataRoute.reverse({guid: dataId})

        this.props.dispatch(get(dataEndpoint, null, (error, data, normalisedData) => {
          this.props.dispatch(addInitialData(tabId, normalisedData))
          Notification.success(this.props.intl.formatMessage({id: 'notification.card.update.success'}))
        }))

        return Notification.success(
          <span>
            {this.props.intl.formatMessage({
              id: this.props.item.success.text
            })}: {data.meta.total}
          </span>
        )
      }
    ))
  }

  render () {
    const { item, intl } = this.props

    if (item.confirm) {
      return (
        <Popconfirm
          placement="top"
          arrowPointAtCenter
          title={intl.formatMessage({id: item.confirm.text})}
          onConfirm={this.doAction.bind(this)}
          okText={intl.formatMessage({id: 'notification.yes'})}
          cancelText={intl.formatMessage({id: 'notification.no'})}
        >
          <Button 
            type="dashed" 
            loading={this.state.isLoading}
          >
            <FormattedMessage id={item.title} />
          </Button>
        </Popconfirm>
      )
    } else {
      return (
        <Button 
          type="dashed" 
          onClick={this.doAction.bind(this)} 
          loading={this.state.isLoading}
        >
          <FormattedMessage id={item.title} />
        </Button>
      )
    }
  }
  
}

export default connect(() => ({}))(withRouter(injectIntl(ActionButton)))