import formatDate from '../utils/formatDate'

export default {
  componentType: 'search',
  objectName: 'keyword',
  sortLevel: 16,
  defaultSorting: 'name',
  url: '/search/?filter=keyword',
  title: 'keywords',
  getUrl: data => '/keywords/' + data.id,
  table: [
    {
      title: 'title',
      width: 400,
      fixed: true,
      sortBy: 'name',
      getValue: data => data.name
    },
    {
      title: 'keywords.nameEn',
      width: 400,
      fixed: true,
      sortBy: 'name_en',
      getValue: data => data.nameEn
    },
    {
      title: 'comment',
      width: 400,
      sortBy: 'help',
      getValue: data => data.help
    },
    {
      title: 'createdAt',
      width: 100,
      sortBy: 'created_at',
      getValue: data => formatDate(data.createdAt)
    },
    {
      title: 'individuals.table.createdBy',
      width: 165,
      sortBy: 'created_by',
      filterBy: 'user?filter[created_type]=keyword',
      filterMode: 'multiple',
      getValue: data => data.createdBy
    }
  ]
}
