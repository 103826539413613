import React, { PureComponent } from 'react'
import Select from '../components/SelectComponent'
import jsonPointer from 'json-pointer'
import { storeFieldData } from '../redux/actions/editingCard'
import uuid from 'uuid'
import { subscribe } from '../utils/pubSub'
import difference from 'lodash/difference'
import addData from '../utils/addData'

class RelationWithRole extends PureComponent {

  componentWillMount () {
    subscribe(this.props.tab.id, this.props.pointer, (msg, done) => {
      if (msg === 'cancel') return this.cancelChanges.apply(this)
      if (msg === 'save') return this.saveChanges.apply(this)
    })
  }

  render () {
    const { tab } = this.props
    if (!tab.currentData) return null

    const value = this.getValue.apply(this)

    return <div className="RelationWithRole">
      {value == null
        ? null
        : value.map(this.renderRelation.bind(this))
      }
      {this.renderRelation({id: 'new'})}
    </div>
  }

  renderRelation ({ id, event, role }) {
    return <div className="RelationWithRole__each" key={id}>
      <Select
        onlySearchable
        endpoint="/event"
        value={event}
        label="title"
        placeholder="title.placeholder"
        style={{width: '58%'}}
        onChange={this.setEditedValue.bind(this, id, 'event')}
      />
      <Select
        endpoint="/role"
        value={role}
        endpointParams={{'filter[type]': 'Person,Event'}}
        label="role"
        placeholder="role.placeholder"
        style={{width: '42%', paddingLeft: '20px'}}
        onChange={this.setEditedValue.bind(this, id, 'role')}
      />
    </div>
  }

  getInitialValue () {
    const { data, pointer } = this.props
    if (!data || !jsonPointer.has(data, pointer)) return null
    return jsonPointer.get(data, pointer)
  }

  getEditedValue () {
    const { tab, pointer } = this.props
    if (!tab.changes || !jsonPointer.has(tab.changes, pointer)) return null
    return jsonPointer.get(tab.changes, pointer)
  }

  getValue () {
    return this.getEditedValue.apply(this) || this.getInitialValue.apply(this)
  }

  setEditedValue (id, type, newValue) {
    const { tab, pointer } = this.props
    const previousValue = this.getValue.apply(this)
    if (id === 'new') {
      previousValue.push({
        id: uuid(),
        [type]: {id: newValue}
      })
    } else {
      previousValue.map(relation => {
        if (relation.id !== id) return relation
        if (relation['__' + type]) {
          relation['__' + type] = {id: newValue}
        } else {
          relation[type] = {id: newValue}
        }
        return relation
      })
    }
    this.props.dispatch(storeFieldData(tab.id, pointer, previousValue))
  }

  cancelChanges () {
    const { tab, pointer } = this.props
    this.props.dispatch(storeFieldData(tab.id, pointer, null))
  }

  saveChanges () {
    const initialValue = this.getInitialValue.apply(this)
    const editedValue = this.getEditedValue.apply(this)
    if (editedValue == null) return
    const toDelete = difference(initialValue, editedValue, 'id')
    const toAdd = difference(editedValue, initialValue, 'id')
    console.log('initialValue, toDelete', 'toAdd', initialValue, toDelete, toAdd)
    // if (toAdd.length > 0) {
    //   const data = toAdd.map(id => ({id, type: 'keyword'}))
    //   console.log('POST request:', {data})
    //   this.props.dispatch(create('/person/' + this.props.data.id + '/relationships/keywords', {data}))
    // }
    // if (toDelete.length > 0) {
    //   const data = toDelete.map(id => ({id, type: 'keyword'}))
    //   console.log('DELETE request:', {data})
    //   this.props.dispatch(remove('/person/' + this.props.data.id + '/relationships/keywords', {data}))
    // }
  }

}

export default addData(RelationWithRole)
