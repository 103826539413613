import React, {Component} from 'react'
import {connect} from 'react-redux'
import getCardData from '../redux/mapers/getCardData'
import Button from '../components/Button'
import UploadingFile from '../components/UploadingFile'
import ExistingFile from '../components/ExistingFile'
import FilesSelectionToolbar from '../components/FilesSelectionToolbar'

class Files extends Component {

  constructor () {
    super()
    this.state = {uploads: []}
  }

  render () {
    const {relationship, style, data, tab, config} = this.props
    if (!tab || !tab.currentData) return null

    const getValue = () => {
      return data[relationship] || []
    }

    const existing = getValue()
    const uploading = this.props.uploads.filter(file => file.processed !== true)
    const uploaded = this.props.uploads.filter(file => file.processed === true)
    const uploadedWithoutExisting = uploaded.filter(uploadedFile => {
      return !existing.find(existingFile => existingFile.id === uploadedFile.id)
    })
 
    return <div className="Files" style={style}>
      <FilesSelectionToolbar 
        objectName={config.objectName}
        tabId={tab.id}
        files={[...existing, ...uploadedWithoutExisting]}
      />
      <div className="Files__new" onClick={e => { window.dropzone.open() }}>
        <Button title="add-file" />
      </div>
      {uploading && uploading.map(file => <UploadingFile key={file.udid} file={file} />)}
      {uploadedWithoutExisting && uploadedWithoutExisting.map(
        file => <ExistingFile 
          key={file.id}
          fileId={file.id}
          dataId={data.id}
          objectName={config.objectName}
          tabId={tab.id}
          selected={file.selected}
        />
      )}
      {existing.map(
        file => <ExistingFile 
          key={file.id}
          fileId={file.id}
          dataId={data.id}
          objectName={config.objectName}
          tabId={tab.id}
          selected={file.selected}
        />
      )}
    </div>
  }

}

const mapStateToProps = (state, props) => {
  const cardData = getCardData(state, props)
  const documentId = cardData.data.id
  const uploads = Object.keys(state.files).map(udid => state.files[udid]).filter(file => file.documentId === documentId)
  return {...cardData, token: state.user.access_token, uploads}
}

export default connect(mapStateToProps)(Files)
