export default {
  componentType: 'card',
  endpoint: '/document(/:guid)',
  objectName: 'issue',
  objectType: 'document',
  url: '/issues/:guid',
  getUrl: data => '/issues/' + data.id,
  title: 'issuesCard',
  titleCreate: 'issues.create',
  getValue: data => data.name,
  showReviewedByEditor: true,
  showNeedsVerificationByEditor: true,
  tabs: [
    {
      title: 'tab.basis',
      type: 'basis',
      children: [
        {
          title: 'issues.issue',
          icon: 'icons/issue.svg',
          required: true,
          components: [
            {name: 'Select', relationship: 'periodical', kind: 'document', endpointParams: {'filter[type]': 'periodical', 'search[fields]': 'name'}, label: 'issues.periodical', style: {width: '100%'}},
            {name: 'input', fieldName: 'numberStr', label: 'number', placeholder: 'number', style: {width: '30%'}},
            {name: 'input', fieldName: 'subname', label: 'number-theme', placeholder: 'number-theme', style: {width: '70%', paddingLeft: '20px'}},
            {name: 'input', fieldName: 'globalNumber', label: 'global-number', placeholder: 'global-number.placeholder', style: {width: '30%'}},
            {name: 'input', fieldName: 'volumeStr', label: 'volume', placeholder: 'volume.placeholder', style: {width: '30%', marginLeft: '20px'}}
          ],
          side: 'left'
        },
        {
          title: 'issues.dates',
          icon: 'icons/calendar-icon.svg',
          components: [
            {name: 'FullDate', label: 'issues.dateFrom', fieldName: 'dateFrom', style: {width: '100%'}},
            {name: 'FullDate', label: 'issues.dateTo', fieldName: 'dateTo', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'storage-type',
          icon: 'icons/properties.svg',
          components: [
            {name: 'Select', relationship: 'originalOrCopy', kind: 'original_or_copy', allowClear: true, style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'keywords',
          icon: 'icons/keywords-icon.svg',
          components: {name: 'keywords', pointer: '/keywords', placeholder: 'individuals.field.keywords.placeholder', style: {width: '100%'}},
          side: 'left'
        },
        {
          title: 'description',
          icon: 'icons/description-icon.svg',
          components: {name: 'text', fieldName: 'description', placeholder: 'description.placeholder', style: {width: '100%'}},
          side: 'left'
        },
        {
          title: 'storage-details',
          icon: 'icons/storage.svg',
          components: [
            {name: 'input', fieldName: 'inv', label: 'inv', disabled: true, style: {width: '100%'}},
            {name: 'Select', relationship: 'institution', label: 'institution', disabled: true, style: {width: '100%'}},
            {name: 'Select', relationship: 'storageLocation', kind: 'storage_location', allowClear: true, endpointParams: {'filter[group]': 'raan'}, label: 'storage-location', style: {width: '100%'}},
            {name: 'TextArray', fieldName: 'additional_copies', label: 'inv.extra', style: {width: '100%'}}
          ],
          side: 'right'
        },
        {
          title: 'collection',
          icon: 'icons/documents.svg',
          required: true,
          components: [
            {name: 'Select', relationship: 'collection', kind: 'collection', label: 'collection', style: {width: '100%'}}
          ],
          side: 'right'
        },
        {
          title: 'accessType',
          icon: 'icons/shelf.svg',
          components: [
            {name: 'Select', relationship: 'accessType', kind: 'access_type', allowClear: true, style: {width: '100%'}},
            {name: 'Select', relationship: 'subjectHeading', kind: 'subject_heading', getOptionTitle: option => option.id + ' ' + option.name, endpointParams: {'view[type]': 'app'}, label: 'books.subject-heading', style: {width: '100%'}}
          ],
          side: 'right'
        },
        {
          title: 'website-status',
          icon: 'icons/website-icon.svg',
          components: [
            {name: 'Switch', relationship: 'publishedOnWeb', kind: 'published_on_web', style: {float: 'none'}}
          ],
          side: 'right'
        },
        {
          title: 'source',
          icon: 'icons/organisation.svg',
          components: {name: 'AutoComplete', fieldName: 'source', endpoint: '/field_source', endpointParams: {'filter[type]': 'book,issue'}, placeholder: 'source.placeholder', style: {width: '100%'}},
          side: 'right'
        },
        {
          title: 'comment',
          icon: 'icons/comment.svg',
          components: {name: 'text', fieldName: 'notes', placeholder: 'issues.comment.placeholder', style: {width: '100%'}},
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.related-people',
      type: 'related-people',
      buttons: [{
        title: 'issues.participants',
        confirm: {
          text: 'issues.participants.confirm'
        },
        success: {
          text: 'issues.participants.success'
        },
        endpoint: '/document/(:guid)/copy_people_from_connected_articles'
      }],
      children: [
        {
          title: 'individuals',
          icon: 'icons/individual.svg',
          components: {name: 'Relation', pointer: '/relPersonDocument', filter: {'/person/type': 'individual'}, components: [
            {name: 'Select', relationship: 'person', endpointParams: {'filter[type]': 'individual'}, placeholder: 'individuals.placeholder', createNew: true, createObjectName: 'individual'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'issue,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'left'
        },
        {
          title: 'groups',
          icon: 'icons/group.svg',
          components: {name: 'Relation', pointer: '/relPersonDocument', filter: {'/person/type': 'group'}, components: [
            {name: 'Select', relationship: 'person', endpointParams: {'filter[type]': 'group'}, placeholder: 'individuals.placeholder', createNew: true, createObjectName: 'group'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'issue,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.events',
      type: 'events',
      children: [
        {
          title: 'exhibitions',
          icon: 'icons/frame.svg',
          components: {name: 'Relation', pointer: '/relEventDocument', filter: {'/event/kind/id': /^event.exhibition/}, components: [
            {name: 'Select', relationship: 'event', endpointParams: {'filter[kind][prefix]': 'event.exhibition', 'search[fields]': 'name'}, placeholder: 'event.placeholder', createNew: true, createObjectName: 'event'},
            {name: 'Select', relationship: 'role', fixed: 'role.default_event_document' }
          ]},
          side: 'left'
        },
        {
          title: 'other-events',
          icon: 'icons/event-icon.svg',
          components: {name: 'Relation', pointer: '/relEventDocument', filter: {'/event/kind/id': /^(?!event.exhibition).*/}, components: [
            {name: 'Select', relationship: 'event', endpointParams: {'filter[kind][not_prefix]': 'event.exhibition', 'search[fields]': 'name'}, placeholder: 'event.placeholder', createNew: true, createObjectName: 'event'},
            {name: 'Select', relationship: 'role', fixed: 'role.default_event_document' }
          ]},
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.library',
      type: 'library',
      children: [
        {
          title: 'articles',
          icon: 'icons/article.svg',
          components: {name: 'Relation', pointer: '/relDocumentDocument', filter: {'/document/type': 'article'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'article', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty'}
          ]},
          side: 'left'
        }
      ]
    },
    {
      title: 'tab.archive',
      type: 'archive',
      children: [
        {
          title: 'documents',
          icon: 'icons/documents.svg',
          components: {name: 'Relation', pointer: '/relDocumentDocument', filter: {'/document/type': 'archiveDocument'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'archive_document', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty', placeholder: 'role.placeholder'}
          ]},
          side: 'left'
        },
        {
          title: 'images',
          icon: 'icons/photo.svg',
          components: {name: 'Relation', pointer: '/relDocumentDocument', filter: {'/document/type': 'image'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'image', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty', placeholder: 'role.placeholder'}
          ]},
          side: 'left'
        }
      ]
    },
    {
      title: 'tab.media',
      type: 'media',
      children: [
        {
          title: 'media-items',
          icon: 'icons/documents.svg',
          components: {name: 'Relation', pointer: '/relDocumentDocument', filter: {'/document/type': 'mediaItem'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'media_item'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty'}
          ]},
          side: 'left'
        }

      ]
    },
    {
      title: 'tab.files',
      type: 'files',
      children: [
        {
          title: 'files',
          wrapper: false,
          icon: 'icons/media-archive.svg',
          components: [
            {name: 'Files', relationship: 'files'}
          ],
          side: 'both'
        }
      ]
    }
  ]
}
