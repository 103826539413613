import React, { PureComponent } from 'react'
import uuid from 'uuid'
import jsonPointer from 'json-pointer'
import { differenceBy } from 'lodash'
import { subscribe } from '../utils/pubSub'
import Editable from '../components/Editable'
import LabelWrapper from '../components/LabelWrapper'
import { create, remove } from '../redux/actions/apiActions'
import { storeFieldData } from '../redux/actions/editingCard'
import addData from '../utils/addData'
import camelCase from 'lodash/camelCase'
import produce from 'immer'

const Name = ({value, placeholder, onChange, isDeletable, onDelete, style}) => {
  return <div className="TextArray__each">
    <Editable
      type="input"
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      style={style}
    />
    {isDeletable
      ? <div className="TextArray__delete" onClick={onDelete} />
      : null
    }
  </div>
}

class TextArray extends PureComponent {

  constructor () {
    super()
    this.getPointer = this.getPointer.bind(this)
  }

  componentWillMount () {
    subscribe(this.props.tab.id, this.getPointer(), (msg, done) => {
      if (msg === 'cancel') return this.cancelChanges.apply(this)
      if (msg === 'save') return this.saveChanges.apply(this)
    })
  }

  render () {
    let value = this.getValue.apply(this)
    if (value == null || value.length < 1) {
      value = [{id: uuid()}]
    } else {
      const lastValue = value[value.length - 1].name
      if (lastValue != null) {
        value.push({id: uuid()})
      }
    }
    return <div className="TextArray" style={this.props.style}>
      <LabelWrapper label={this.props.label}>
        {value && value.map((name, index) => {
          return <Name
            key={name.id}
            value={name.name}
            style={this.props.style}
            onChange={this.editField.bind(this, name.id)}
            isDeletable={index + 1 !== value.length}
            onDelete={this.deleteField.bind(this, name.id)}
          />
        })}
      </LabelWrapper>
    </div>
  }

  getPointer () {
    return '/' + camelCase(this.props.fieldName)
  }

  getInitialValue () {
    const { data } = this.props
    if (!data || !jsonPointer.has(data, this.getPointer())) return null
    return jsonPointer.get(data, this.getPointer())
  }

  getEditedValue () {
    const { tab } = this.props
    if (!tab.changes || !jsonPointer.has(tab.changes, this.getPointer())) return null
    return jsonPointer.get(tab.changes, this.getPointer())
  }

  getValue () {
    return this.getEditedValue.apply(this) || this.getInitialValue.apply(this)
  }

  deleteField (id) {
    let currentValue = this.getValue.apply(this)
    if (currentValue == null) return
    currentValue = currentValue.filter(each => each.id !== id)
    const { tab } = this.props
    this.props.dispatch(storeFieldData(tab.id, this.getPointer(), currentValue))
  }

  editField (id, newValue) {
    let currentValue = this.getValue.apply(this)
    const valueToUpdate = produce(currentValue, draftValue => {
      if (draftValue == null) {
        draftValue = [{id, name: newValue}]
      } else {
        let isEditedValueInArray = false
        for (let i in draftValue) {
          if (draftValue[i].id !== id) continue
          draftValue[i].name = newValue
          isEditedValueInArray = true
        }
        if (isEditedValueInArray === false) {
          draftValue.push({id, name: newValue})
        }
      }
    })
    const { tab } = this.props
    this.props.dispatch(storeFieldData(tab.id, this.getPointer(), valueToUpdate))
  }

  cancelChanges () {
    const { tab } = this.props
    this.props.dispatch(storeFieldData(tab.id, this.getPointer()))
  }

  saveChanges () {
    const {config, fieldName, data} = this.props
    const initialValue = this.getInitialValue.apply(this)
    const editedValue = this.getEditedValue.apply(this)
    if (editedValue == null) return
    const toDelete = differenceBy(initialValue, editedValue, 'id')
    const toAdd = differenceBy(editedValue, initialValue, 'name')
    const convert = function (array) {
      return array.map(each => ({
        id: each.id,
        attributes: {name: each.name},
        type: fieldName
      }))
    }
    const endpoint = '/' + config.objectType + '/' + data.id + '/relationships/' + fieldName
    if (toAdd.length > 0) {
      const data = convert(toAdd)
      this.props.dispatch(create(endpoint, {data}))
    }
    if (toDelete.length > 0) {
      const data = convert(toDelete)
      this.props.dispatch(remove(endpoint, {data}))
    }
  }

}

export default addData(TextArray)
