import MergeControls from '../components/MergeControls'

export default {
  componentType: 'card',
  endpoint: '/merge_organisation',
  fetchInitialData: false,
  objectType: 'merge_organisation',
  url: '/merge-organisation',
  getUrl: data => '/merge-organisation',
  title: 'merge-organisation',
  customControls: MergeControls,
  tabs: [
    {
      title: 'empty',
      type: 'basis',
      children: [
        {
          title: 'merge.keep',
          icon: 'icons/organisation.svg',
          components: [
            {name: 'Select', relationship: 'keep', endpointParams: {'search[fields]': 'name'}, placeholder: 'organisations.placeholder', kind: 'organisation', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'merge.delete',
          icon: 'icons/organisation.svg',
          components: [
            {name: 'Select', relationship: 'delete', endpointParams: {'search[fields]': 'name'}, placeholder: 'organisations.placeholder', kind: 'organisation', style: {width: '100%'}}
          ],
          side: 'right'
        }
      ]
    }
  ]
}
