import removeLazyLoading from '../../utils/removeLazyLoading'

export const addInitialData = (tabId, response) => {
  return {
    type: 'ADD_INITIAL_DATA_TO_TAB',
    data: response,
    tabId
  }
}

export const cancelChanges = (tabId) => {
  return {
    type: 'CANCEL_TAB_CHANGES',
    tabId
  }
}

export const editField = (tabId, path, value) => {
  return {
    type: 'EDIT_FIELD',
    tabId,
    path,
    value
  }
}

export const storeFieldData = (tabId, path, value, doRemoveLazyLoading = true) => {
  if (value != null && Array.isArray(value) && doRemoveLazyLoading) {
    value = value.map(each => removeLazyLoading(each, true))
  }
  return {
    type: 'STORE_FIELD_DATA',
    tabId,
    path,
    value
  }
}

export const openActiveTabPopUp = (imgSrc) => {
  return {
    type: 'OPEN_ACTIVE_TAB_POPUP',
    imgSrc
  }
}

export const closeActiveTabPopUp = () => {
  return {
    type: 'CLOSE_ACTIVE_TAB_POPUP'
  }
}