import fclone from 'fclone'

// This function is here because we're using `redux-object` package,
// which builds objects with getters instead of pure values (so-called lazy loading).
// If we want to store those built objects into redux storage,
// we have to replace all getters with pure values.
// In order to make sure that there is no circular values
// (so we can serialise this data) we use fclone package.

const getDescriptorProperty = function (propertyDescriptors, key) {
  if (propertyDescriptors[key].get) {
    return propertyDescriptors[key].get()
  } else {
    return propertyDescriptors[key].value
  }
}

const copyWithoutGetters = function (obj) {
  // return immediately if not object
  if (obj == null || typeof obj !== 'object') return obj

  // create a new object and fill it with values
  let newObj = {}
  const propertyDescriptors = Object.getOwnPropertyDescriptors(obj)
  for (const key in propertyDescriptors) {
    if (key.indexOf('__') === 0) {
      // this is package-specific
      const new_key = key.substr(2)
      newObj[new_key] = getDescriptorProperty(propertyDescriptors, key)
      continue
    }
    newObj[key] = getDescriptorProperty(propertyDescriptors, key)
  }
  return newObj
}

const removeLazyLoading = function (obj, onlyTopLevel = false) {
  if (obj == null || typeof obj !== 'object') return obj

  let newObj = copyWithoutGetters(obj)

  if (onlyTopLevel) {
    const serviceFields = ['__kind']
    for (const key in newObj) {
      serviceFields.forEach(item => {
        if (newObj[key] && newObj[key][item]) {
          let newKey = item.substr(2)
          let newItem = Object.assign({}, newObj[key])
          let propertyDescriptors = Object.getOwnPropertyDescriptors(newObj[key])
          newItem[newKey] = getDescriptorProperty(propertyDescriptors, newKey)
          newObj[key] = newItem
        }
      })
    }
    return fclone(newObj)
  }
  
  // loop over sub objects for the same reason
  for (const key in newObj) {
    newObj[key] = copyWithoutGetters(newObj[key])
  }

  return fclone(newObj)
}

export default removeLazyLoading