import React from 'react'
import { connect } from 'react-redux'
import getPropsFromUrl from '../utils/getPropsFromUrl'
import getRowsByRequestParams from '../redux/mapers/getRowsByRequestParams'

const TableCounter = function ({ total = 0 }) {
  const formatted = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return <span>{formatted}</span>
}

function mapStateToProps (state, { config, tab }) {
  const urlSortParam = getPropsFromUrl(tab.path).sort
  const params = urlSortParam ? {...config.endpointParams, sort: urlSortParam} : config.endpointParams
  const ret = getRowsByRequestParams(state, {endpoint: config.endpoint, params: params})
  return {total: ret.meta.total}
}

export default connect(mapStateToProps)(TableCounter)
