import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import Tabs, { TabPane } from 'antd/lib/tabs'

class TabsComponent extends PureComponent {

  render() {
    let { tabs, activeType } = this.props
    tabs.sort((prev, next) => prev.sortLevel - next.sortLevel)
    const filteredTabs = tabs.filter(t => t.type)
    return <Tabs
      className="TabsComponent"
      onChange={this.props.onClick}
      defaultActiveKey="basis"
      activeKey={activeType || 'basis'}
    >{
        filteredTabs && filteredTabs.map(this.tabRenderer.bind(this))
      }</Tabs>
  }

  tabRenderer(tab, index) {
    let { activeType } = this.props
    const tabName = <span className="TabsComponent__tab">
      <FormattedMessage id={tab.title} />
      {(
        tab.amount != null && tab.amount !== 0 && 
        (
          !activeType || 
          tab.type === activeType || 
          (tab.children && tab.children.length > 0)
        )
      )
        ? <span className="TabsComponent__tab-amount">{tab.amount}</span>
        : null
      }
    </span>
    return <TabPane tab={tabName} key={tab.type} className="TabsComponent__pane" />
  }

}

export default TabsComponent
