import Text from './CommonField'
import Keywords from './KeywordsField'
import Relation from './Relation'
import RelationWithRole from './RelationWithRole'
import PersonName from './PersonName'
import GroupTitle from './GroupTitle'
import GroupAlternativeTitle from './GroupAlternativeTitle'
import PersonAlternativeName from './PersonAlternativeName'
import TextArray from './TextArray'
import TextList from './TextList'
import Select from './Select'
import MultiSelect from './MultiSelect'
import StaticSwitch from './StaticSwitch'
import Switch from './Switch'
import AutoComplete from './AutoComplete'
import Url from './Url'
import Bool from './Bool'
import Files from './Files'
import Duration from './Duration'
import FullDate from './FullDate'
import RichText from './RichText'
import CKEditor from './CKEditor'

const componentTypes = {
  PersonName,
  GroupTitle,
  GroupAlternativeTitle,
  PersonAlternativeName,
  TextArray,
  TextList,
  Select,
  MultiSelect,
  StaticSwitch,
  Switch,
  AutoComplete,
  Bool,
  Files,
  text: Text,
  nobr: Text,
  input: Text,
  url: Url,
  keywords: Keywords,
  Relation,
  RelationWithRole,
  Duration,
  FullDate,
  RichText,
  CKEditor
}

export default componentTypes
