import config from '../config'
import snakeCase from 'lodash/snakeCase'

export default function getSearchTabs (meta, filter) {
  if (meta == null) return []
  let tabs = [
    {
      title: 'search.table.all',
      type: 'basis',
      sortLevel: 0
    }
  ]
  Object.keys(config.pages).filter(key => {
    return (
      config.pages[key].componentType === 'search' &&
      config.pages[key].objectName != null
    )
  }).forEach(key => {
    tabs.push({
      title: config.pages[key].title,
      type: snakeCase(config.pages[key].objectName),
      sortLevel: config.pages[key].sortLevel ? config.pages[key].sortLevel : 9999
    })
  })
  tabs = tabs.map(tab => {
    let amount = (!filter || tab.type === filter) ? meta[tab.type] : null
    if (tab.type === 'basis') amount = meta['total']
    return {...tab, amount}
  })
  return tabs
}
