export default {
  componentType: 'card',
  endpoint: '/branch(/:guid)',
  objectName: 'branch',
  url: '/branch/:guid',
  getUrl: data => '/branch/' + data.id,
  title: 'branches',
  titleCreate: 'branches.create',
  getValue: data => data.name,
  tabs: [
    {
      title: 'tab.basis',
      type: 'basis',
      children: [
        {
          title: 'branch',
          icon: 'icons/comment.svg',
          required: true,
          components: [
            { name: 'nobr', fieldName: 'name', label: 'branches.nameRu', placeholder: 'branches.nameRu', style: { width: '100%' } },
            { name: 'nobr', fieldName: 'nameEn', label: 'branches.nameEn', placeholder: 'branches.nameEn', style: { width: '100%' } },
            { name: 'Select', relationship: 'institution', label: 'institution', endpointParams: { 'response[type]': 'short' }, style: { width: '100%' }, disableFilled: true }
          ],
          side: 'left'
        },
        {
          title: 'books.website-status',
          icon: 'icons/website-icon.svg',
          components: [
            { name: 'Switch', relationship: 'publishedOnWeb', kind: 'published_on_web', style: { float: 'none' } }
          ],
          side: 'right'
        },
        {
          title: 'branches.email',
          icon: 'icons/description-icon.svg',
          components: { name: 'nobr', fieldName: 'email', style: { width: '100%' } },
          side: 'right'
        },
        {
          title: 'branches.phone',
          icon: 'icons/description-icon.svg',
          components: { name: 'nobr', fieldName: 'phone', style: { width: '100%' } },
          side: 'right'
        },
        {
          title: 'branches.addressRu',
          icon: 'icons/description-icon.svg',
          components: { name: 'nobr', fieldName: 'addressRu', style: { width: '100%' } },
          side: 'right'
        },
        {
          title: 'branches.addressEn',
          icon: 'icons/description-icon.svg',
          components: { name: 'nobr', fieldName: 'addressEn', style: { width: '100%' } },
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.description-ru',
      type: 'description-ru',
      children: [
        {
          title: 'tab.description-ru',
          components: [
            { name: 'text', fieldName: 'descriptionRu', placeholder: 'description.placeholder', style: { width: '100%' } }
          ],
          side: 'both'
        }
      ]
    },
    {
      title: 'tab.description-en',
      type: 'description-en',
      children: [
        {
          title: 'tab.description-en',
          components: [
            { name: 'text', fieldName: 'descriptionEn', placeholder: 'collections.descriptionEn.placeholder', style: { width: '100%' } }
          ],
          side: 'both'
        }
      ]
    },
    {
      title: 'tab.foundations',
      type: 'foundations',
      children: [
        {
          title: 'foundations',
          icon: 'icons/storage.svg',
          components: [
            { name: 'TextList', fieldName: 'collections', disabled: true, placeholder: 'foundations', style: { width: '100%' }, listStyle: 'none' }
          ],
          side: 'left'
        }
      ]
    },
    {
      title: 'tab.files',
      type: 'files',
      children: [
        {
          title: 'files',
          wrapper: false,
          icon: 'icons/media-archive.svg',
          components: [
            { name: 'Files', relationship: 'files' }
          ],
          side: 'both'
        }
      ]
    }
  ]
}
