import Mousetrap from 'mousetrap'
import 'mousetrap-global-bind'
import { connect } from 'react-redux'
import { openNewTab, closeTab } from '../redux/actions/manipulateTabs'

const KeyCatcher = function ({ bindHotkey, dispatch, children }) {
  // reload the page
  Mousetrap.bindGlobal(['command+r', 'ctrl+r'], (event) => {
    event.preventDefault()
    dispatch({type: 'REFRESH_PAGE'})
    window.location.reload()
  })

  // open tab
  Mousetrap.bindGlobal(['command+t', 'ctrl+t'], (event) => {
    event.preventDefault()
    dispatch(openNewTab())
  })
  Mousetrap.bindGlobal(['command+w', 'ctrl+w'], (event) => {
    event.preventDefault()
    dispatch(closeTab())
  })

  return children
}

export default connect(() => ({}))(KeyCatcher)
