import produce from 'immer'

const initialState = {
  columns: {}
}

const settings = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_COLUMNS_SETTINGS':
      return produce(state, draftState => {
        draftState.columns[action.section] = action.value
      })
    case 'UPDATE_COLUMN_WIDTH':
      return produce(state, draftState => {
        draftState.columns[action.section].width[action.columnKey] = action.value
      })
    default:
      return state
  }
}

export default settings