import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import api from './middleware/api'
import persistState from 'redux-localstorage'
import Raven from "raven-js"
import createRavenMiddleware from "raven-for-redux"
import appConfig from '../../package.json'

if (process.env.REACT_APP_SENTRY_DISABLED === 'false') {
  try {
    Raven.config(process.env.REACT_APP_SENTRY_CLIENT_ID, {
      release: appConfig.version
    }).install()
  } catch (e) {
    console.error('Raven setup error:', e)
  }
}

const stateTransformer = state => {
  return {
    tabs: state.tabs
  }
}

const composeEnhancers = composeWithDevTools({maxAge: 50})
const enhancer = composeEnhancers(
  persistState(['user', 'menu', 'tabs', 'settings']),
  applyMiddleware(
    createRavenMiddleware(Raven, { stateTransformer }), 
    thunk, 
    api
  )
)

export default function (initialState = {}) {
  const store = createStore(rootReducer, initialState, enhancer)

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      return store.replaceReducer(require('./reducers').default) // eslint-disable-line global-require
    })
  }

  return store
}
