export const login = (user) => {
  return {
    type: 'SET_USER',
    user
  }
}

export const logout = (user) => {
  return {
    type: 'LOGOUT'
  }
}
