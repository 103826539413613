import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import build from 'redux-object'
import { injectIntl } from 'react-intl'
import 'antd/dist/antd.css'
import formatParams from '../utils/formatParams'
import { get } from '../redux/actions/apiActions'
import camelCase from 'lodash/camelCase'
import SwitchStyled from '../fieldsStyled/SwitchStyled'

class SwitchComponent extends PureComponent {

  componentWillMount () {
    this.props.dispatch(get(this.props.endpoint, this.props.endpointParams))
  }

  render () {
    const { style, label, data, value } = this.props
    const options = data
      ? data.sort((a, b) => a.name ? a.name.localeCompare(b.name) : a.name)
      : Array.isArray(value)
        ? value
        : [value]
    const selectValue = Array.isArray(value)
      ? value.map(v => v ? v.id : undefined)
      : value ? value.id : undefined
    return <SwitchStyled
      label={label}
      value={selectValue}
      options={options}
      style={style}
      onChange={this.handleChange.bind(this)}
    />
  }

  handleChange (e) {
    if (this.props.onChange) this.props.onChange(e.target.value)
  }

}

function mapStateToProps (state, props) {
  const requestMeta = state.data.meta[props.endpoint]
  if (requestMeta == null) {
    if (!props.value) return {}
    const singleValue = Array.isArray(props.value) ? props.value[0] : props.value
    if (!singleValue) return {}
    return {data: build(state.data, singleValue.type, null, {includeType: true})}
  }
  if (props.endpointParams != null && requestMeta[formatParams(props.endpointParams)] == null) return {}
  return {data: build(state.data, camelCase(props.endpoint.substring(1)), null, {includeType: true})}
}

export default connect(mapStateToProps)(injectIntl(SwitchComponent))
