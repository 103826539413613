import MergeControls from '../components/MergeControls'

export default {
  componentType: 'card',
  endpoint: '/merge_person',
  fetchInitialData: false,
  objectType: 'merge_person',
  url: '/merge-person',
  getUrl: data => '/merge-person',
  title: 'merge-person',
  customControls: MergeControls,
  tabs: [
    {
      title: 'empty',
      type: 'basis',
      children: [
        {
          title: 'merge.keep',
          icon: 'icons/individual.svg',
          components: [
            {name: 'Select', relationship: 'keep', endpointParams: {'search[fields]': 'name'}, placeholder: 'individuals.placeholder', kind: 'person', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'merge.delete',
          icon: 'icons/individual.svg',
          components: [
            {name: 'Select', relationship: 'delete', endpointParams: {'search[fields]': 'name'}, placeholder: 'individuals.placeholder', kind: 'person', style: {width: '100%'}}
          ],
          side: 'right'
        }
      ]
    }
  ]
}
