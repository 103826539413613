import React from 'react'
import LabelWrapper from '../components/LabelWrapper'
import Select from 'antd/lib/select'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'

const SelectStyled = ({label, style, showError, children, intl, hideSelectIcon, ...props}) => {
  const notFound = !(children && children[0])
      ? intl.formatMessage({id: 'input.no-results'})
      : false
  const className = classNames('SelectComponent', {
    showError: showError,
    showSelectIcon: hideSelectIcon ? false : true
  })
  return <div className={className} style={style}>
    <LabelWrapper label={label}>
      <Select
        showSearch
        defaultActiveFirstOption={false}
        // showArrow={false}
        optionFilterProp="children"
        notFoundContent={notFound}
        filterOption={value => true}
        {...props}
      >
        {children}
      </Select>
    </LabelWrapper>
  </div>
}

SelectStyled.Option = Select.Option

export default injectIntl(SelectStyled)
