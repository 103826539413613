import React, {Component} from 'react'
import {connect} from 'react-redux'
import Editable from '../components/Editable'
import Progress from 'antd/lib/progress'
import Icon from 'antd/lib/icon'
import Popconfirm from 'antd/lib/popconfirm'
import {get} from '../redux/actions/apiActions'
import {updateFileData, abortFileLoading} from '../redux/actions/filesActions'
import {injectIntl} from 'react-intl'
import build from 'redux-object'
import bytes from 'bytes'

class UploadingFile extends Component {

  constructor(props) {
    super(props)
    this.state = { pollFlag: false }
  }

  componentWillReceiveProps (newProps) {
    if (newProps.file.id != null) {
      this.poll.bind(this)(newProps.file.id)
    }
  }
  render () {
    const {file, intl} = this.props
    if (!file) return null

    const format = percent => {
      if(file.progress < 1) { // md5 calculation, uploading
        return Math.round(percent) + '%'
      } else {
        return intl.formatMessage({id: 'files.processing-on-server'})
      }
    }

    const extention = file.extention || file.name.substring(file.name.lastIndexOf('.'))
    const size = file.size
      ? <span className="Files__size">({bytes(file.size)})</span>
      : null
    return <div className="Files__each">
      <div className="Files__header">
        {file.url
          ? <a className="Files__download" href={file.url}><Icon type="download" /> {extention} {size}</a>
          : <span className="Files__download">{extention} {size}</span>
        }
        {file.progress > 0 && file.progress < 1.0 && (
          <Popconfirm
            placement="topRight"
            arrowPointAtCenter
            title={intl.formatMessage({id: 'notification.sure'})}
            onConfirm={e => this.props.dispatch(abortFileLoading(file.udid))}
            okText={intl.formatMessage({id: 'notification.yes'})}
            cancelText={intl.formatMessage({id: 'notification.no'})}
          >
            <div className="Files__delete" />
          </Popconfirm>
        )}
      </div>
      <div className="Files__preview">
        {file.progress < 0
          ? <Progress type="circle" percent={-file.progress * 100} format={format} status="success" />
          : <Progress type="circle" percent={file.progress * 100} format={format} status="active" />
        }
      </div>
      <div className="Files__details">
        <Editable type="input" value={file.name} disabled />
      </div>
    </div>
  }
  poll (fileId) {
    if (this.state.pollFlag) return
    this.setState({
      pollFlag: true
    })
    if (this.props.file.processed === true) return
    this.props.dispatch(get('/file/' + fileId, null, (error, result, raw) => {
      if (error) {
        setTimeout(this.poll.bind(this, fileId), 1000)
        return console.error('something went wrong', fileId)
      }
      if (result.data.attributes.processed !== true) {
        this.setState({
          pollFlag: false
        })
        setTimeout(this.poll.bind(this, fileId), 1000)
      } else {
        const fileObject = build(raw, 'file')[0]
        this.props.dispatch(updateFileData(this.props.file.udid, fileObject))
      }
    }))
  }

}

export default connect(state => ({}))(injectIntl(UploadingFile))
