import React from 'react'
import SelectComponent from '../components/SelectComponent'
import { editField } from '../redux/actions/editingCard'
import jsonPointer from 'json-pointer'
import addData from '../utils/addData'

const MultiSelect = (props) => {
  const {label, placeholder, style} = props
  const {data, dispatch, tab, config} = props
  let {relationship, kind, endpointParams, getOptionTitle, getOptionTooltip} = props

  // return if there is no currentData yet
  if (!tab || !tab.currentData) return null

  // if type is not set it's same as ralationship field
  if (kind == null) kind = relationship

  // construct pointer to the value
  const objectPointer = '/' + config.objectName + '/' + data.id + '/relationships/' + relationship + '/data'

  const getValue = () => {
    if (!jsonPointer.has(tab.currentData, objectPointer)) return null
    return jsonPointer.get(tab.currentData, objectPointer)
  }

  const setValue = newValue => {
    dispatch(editField(tab.id, objectPointer, newValue))
  }

  return <div className="CommonField" style={style}>
    <SelectComponent
      mode="multiple"
      endpoint={'/' + kind}
      endpointParams={endpointParams}
      getOptionTitle={getOptionTitle}
      getOptionTooltip={getOptionTooltip}
      value={getValue()}
      label={label}
      placeholder={placeholder}
      onChange={setValue}
    />
  </div>
}

export default addData(MultiSelect)
