import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import build from 'redux-object'
import { injectIntl } from 'react-intl'
import { get, create, remove } from '../redux/actions/apiActions'
import Select from 'antd/lib/select'
import Tooltip from 'antd/lib/tooltip'
import NewTabLink from '../components/NewTabLink'
import 'antd/dist/antd.css'
import { subscribe } from '../utils/pubSub'
import getCardURLByType from '../utils/getCardURLByType'
import jsonPointer from 'json-pointer'
import { storeFieldData } from '../redux/actions/editingCard'
import lodash from 'lodash'
import addData from '../utils/addData'
import constructUrl from '../utils/constructUrl'
const Option = Select.Option

class KeywordsField extends PureComponent {

  componentWillMount () {
    subscribe(this.props.tab.id, this.props.pointer, (msg, done) => {
      if (msg === 'cancel') return this.cancelChanges.apply(this)
      if (msg === 'save') return this.saveChanges.apply(this)
    })
    this.props.dispatch(get('/keyword'))
  }

  componentWillUnmount () {
    // PubSub.unsubscribe(this.props.tab.id)
  }

  render () {
    const { placeholder, keywords } = this.props
    // if (!tab.currentData) return null

    const initialValue = this.getInitialValue.apply(this)
    if (initialValue == null) return null
    const value = this.getEditedValue.apply(this) || initialValue

    const options = []
    if (keywords != null && Array.isArray(keywords)) {
      keywords.sort((a, b) => a.name.localeCompare(b.name))
      for (let i in keywords) {
        const cardUrl = getCardURLByType({type: 'keyword', id: keywords[i].id})
        const tooltip = keywords[i].help
        const tooltipContent = cardUrl
          ? <NewTabLink url={cardUrl}>{tooltip}</NewTabLink>
          : tooltip

        options.push(
          <Option key={keywords[i].id} title={keywords[i].name}>
            <Tooltip placement="left" title={tooltipContent}>
              <span>{keywords[i].name}</span>
            </Tooltip>
          </Option>
        )
      }
    }

    return <div className="KeywordsField">
      <Select
        mode="multiple"
        placeholder={this.props.intl.formatMessage({id: placeholder || 'input.keywords.placeholder'})}
        value={value || []}
        onChange={this.setEditedValue.bind(this)}
        notFoundContent={this.props.intl.formatMessage({id: 'input.keywords.no-results'})}
        filterOption={this.filterOptions}
      >
        {options}
      </Select>
    </div>
  }

  getInitialValue () {
    const { data, pointer } = this.props
    if (!data || !jsonPointer.has(data, pointer)) return null
    return jsonPointer.get(data, pointer).map(key => key.id)
  }

  getEditedValue () {
    const { tab, pointer } = this.props
    if (!tab.changes || !jsonPointer.has(tab.changes, pointer)) return null
    return jsonPointer.get(tab.changes, pointer)
  }

  setEditedValue (newValue) {
    const { tab, pointer } = this.props
    this.props.dispatch(storeFieldData(tab.id, pointer, newValue))
  }

  async cancelChanges () {
    const { tab, pointer } = this.props
    return this.props.dispatch(storeFieldData(tab.id, pointer, null))
  }

  async saveChanges () {
    const initialValue = this.getInitialValue.apply(this)
    const editedValue = this.getEditedValue.apply(this)
    if (editedValue == null) return
    const toDelete = lodash.difference(initialValue, editedValue)
    const toAdd = lodash.difference(editedValue, initialValue)
    const objectUrl = constructUrl(this.props.config.endpoint, {guid: this.props.data.id})
    const url = objectUrl + '/relationships/keywords'
    if (toAdd.length > 0) {
      const data = toAdd.map(id => ({id, type: 'keyword'}))
      await this.props.dispatch(create(url, {data}))
    }
    if (toDelete.length > 0) {
      const data = toDelete.map(id => ({id, type: 'keyword'}))
      await this.props.dispatch(remove(url, {data}))
    }
  }

  filterOptions (inputValue, option) {
    return option.props.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
  }

}

function mapStateToProps (state, props) {
  const keywordsMeta = state.data.meta['/keyword']
  if (keywordsMeta == null || keywordsMeta[''] == null) return {}
  return {keywords: build(state.data, 'keyword')}
}

export default connect(mapStateToProps)(addData(injectIntl(KeywordsField)))
