import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import Textarea from 'react-autosize-textarea'
import Label from './Label'
import ExternalLink from '../components/ExternalLink'

class Editable extends Component {

  render () {
    let { intl, label, placeholder, value, style, autoFocus, onFocus } = this.props
    if (value == null) value = ''
    const formattedLabel = label ? intl.formatMessage({id: label}) : ''
    const formattedPlaceholder = placeholder ? intl.formatMessage({id: placeholder}) : ''

    style = style || {}
    if (this.props.type === 'nobr') {
      style.minHeight = 'auto'
    }

    if (this.props.invalid === true) {
      style.border = '1px solid red'
    }

    if (this.props.externalLink) {
      style.width = 'calc(100% - 32px)'
    }

    return <div className="Editable">
      <Label>{formattedLabel}</Label>
      {(this.props.type === 'input')
        ? <>
          <input
            onFocus={onFocus ? onFocus.bind(this) : undefined}
            style={style}
            autoFocus={autoFocus}
            className="Editable__input"
            placeholder={formattedPlaceholder}
            value={value}
            disabled={this.props.disabled}
            onChange={this.onChange.bind(this)}
          /> 
          {this.props.externalLink && (
            <ExternalLink 
              url={this.props.externalLink + value} 
              className={'ExternalLink__input ' + (!value ? 'ExternalLink__disabled' : '')}
            />
          )}
        </>
        : <Textarea
          onFocus={onFocus ? onFocus.bind(this) : undefined}
          style={style}
          autoFocus={autoFocus}
          className="Editable__textarea"
          placeholder={formattedPlaceholder}
          value={value}
          disabled={this.props.disabled}
          onChange={this.onChange.bind(this)}
        />
      }
    </div>
  }

  onChange (event) {
    const value = (this.props.type === 'nobr') ? event.currentTarget.value.replace('\n', '') : event.currentTarget.value
    if (this.props.onChange) this.props.onChange(value)
  }

}

export default injectIntl(Editable)
