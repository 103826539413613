export function getDeleted (initialValues, editedValues) {
  return initialValues.filter(initialValue => {
    const found = editedValues.filter(editedValue => editedValue.id === initialValue.id)[0]
    return found == null
  })
}

export function getNew (initialValues, editedValues) {
  if (editedValues == null) return []
  return editedValues.filter(editedValue => {
    const found = initialValues.filter(initialValue => editedValue.id === initialValue.id)[0]
    return found == null
  })
}

export function getChanged (initialValues, editedValues, fields) {
  if (editedValues == null) return []
  return editedValues.filter(editedValue => {
    const changedRelation = initialValues.filter(initialValue => editedValue.id === initialValue.id)[0]
    if (changedRelation == null) return false
    const foundChange = fields.filter(field => {
      if (!editedValue[field] || typeof editedValue[field] === 'undefined') return false
      return editedValue[field].id !== changedRelation[field].id
    })[0]
    return foundChange
  })
}
