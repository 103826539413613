import React from 'react'
import AutoCompleteComponent from '../components/AutoCompleteComponent'
import { editField } from '../redux/actions/editingCard'
import jsonPointer from 'json-pointer'
import addData from '../utils/addData'

const AutoComplete = ({ label, placeholder, endpoint, endpointParams, fieldName, style, data, dispatch, tab, config }) => {
  if (!tab || !tab.currentData) return null

  const getObjectPointer = () => {
    const cardPointer = '/' + config.objectName + '/' + data.id
    return cardPointer + '/attributes/' + fieldName
  }

  const getValue = () => {
    const objectPointer = getObjectPointer()
    if (!objectPointer) return null
    if (!jsonPointer.has(tab.currentData, objectPointer)) return null
    return jsonPointer.get(tab.currentData, objectPointer)
  }

  const setValue = newValue => {
    const objectPointer = getObjectPointer()
    if (!objectPointer) return null
    dispatch(editField(tab.id, objectPointer, newValue))
  }

  return <div className="AutoComplete" style={style}>
    <AutoCompleteComponent
      endpoint={endpoint}
      endpointParams={endpointParams}
      value={getValue()}
      label={label}
      placeholder={placeholder}
      onChange={setValue}
    />
  </div>
}

export default addData(AutoComplete)
