import { combineReducers } from 'redux'
import data from './data'
import table from './table'
import tabs from './tabs'
import menu from './menu'
import user from './user'
import files from './files'
import settings from './settings'
import produce from 'immer'

const rootReducer = combineReducers({
  data,
  table,
  tabs,
  menu,
  user,
  files,
  settings
})

export default function (state, action) {
  switch (action.type) {
    case 'LOGOUT':
      return rootReducer(undefined, action)
    case 'DELETE_FILES':
      if (
        state &&
        state.data &&
        action.tabId &&
        action.filesIds &&
        action.objectName
      ) {
        return rootReducer(
          produce(state, draftState => {
            if (draftState.tabs.length > 0) {
              draftState.tabs.forEach(function (item, i, arr) {
                if (item.id === action.tabId) {
                  action.filesIds.forEach((fileId, keyIndex, keysArr) => {
                    if (!draftState.tabs[i].currentData.file[fileId]) {
                      draftState.tabs[i].currentData.file[fileId] = draftState.data.file[fileId]
                    }
                    draftState.tabs[i].currentData.file[fileId].attributes.deleted = true
                    draftState.data.file[fileId].attributes.deleted = true
                  })
                }
              })
            }
          }),
          action
        )
      } else {
        return rootReducer(state, action)
      }
    case 'SELECT_ALL_FILES':
      if (
        state &&
        state.data &&
        action.tabId &&
        action.filesIds &&
        action.objectName
      ) {
        return rootReducer(
          produce(state, draftState => {
            if (draftState.tabs.length > 0) {
              draftState.tabs.forEach(function (item, i, arr) {
                if (item.id === action.tabId) {
                  action.filesIds.forEach((fileId, keyIndex, keysArr) => {
                    if (!draftState.tabs[i].currentData.file[fileId]) {
                      draftState.tabs[i].currentData.file[fileId] = draftState.data.file[fileId]
                    }
                    draftState.tabs[i].currentData.file[fileId].attributes.selected = action.checked
                    draftState.data.file[fileId].attributes.selected = action.checked
                  })
                }
              })
            }
          }),
          action
        )
      } else {
        return rootReducer(state, action)
      }
    case 'SELECT_FILE':
      if (
        action.id &&
        state &&
        state.data &&
        state.data.file &&
        state.data.file[action.id] &&
        action.tabId &&
        action.dataId &&
        action.objectName
      ) {
        return rootReducer(
          produce(state, draftState => {
            if (draftState.tabs.length > 0) {
              draftState.tabs.forEach(function (item, i, arr) {
                if (item.id === action.tabId) {
                  if (!draftState.tabs[i].currentData.file[action.id]) {
                    draftState.tabs[i].currentData.file[action.id] = draftState.data.file[action.id]
                  }
                  draftState.tabs[i].currentData.file[action.id].attributes.selected = action.checked
                }
              })
            }

            draftState.data.file[action.id].attributes.selected = action.checked
          }),
          action
        )
      } else {
        return rootReducer(state, action)
      }
    case 'DELETE_FILE':
      if (
        action.id &&
        state &&
        state.data &&
        state.data.file &&
        state.data.file[action.id] &&
        action.tabId &&
        action.dataId &&
        action.objectName
      ) {
        return rootReducer(
          produce(state, draftState => {
            if (draftState.tabs.length > 0) {
              draftState.tabs.forEach(function (item, i, arr) {
                if (item.id === action.tabId) {
                  draftState.tabs[i].currentData[action.objectName][action.dataId].attributes.filesCount =
                    draftState.tabs[i].currentData[action.objectName][action.dataId].attributes.filesCount - 1
                  if (!draftState.tabs[i].currentData.file[action.id]) {
                    draftState.tabs[i].currentData.file[action.id] = draftState.data.file[action.id]
                  }
                  draftState.tabs[i].currentData.file[action.id].attributes.deleted = true
                }
              })
            }

            draftState.data.file[action.id].attributes.deleted = true
          }),
          action
        )
      } else {
        return rootReducer(state, action)
      }
    default:
      return rootReducer(state, action)
  }
}
