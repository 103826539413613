import React, {PureComponent} from 'react'
import { connect } from 'react-redux'
import LoginWithGoogle from 'react-google-login'
import { login } from '../redux/actions/userActions'
import { create } from '../redux/actions/apiActions'
import { FormattedMessage } from 'react-intl'
import queryString from 'query-string'
import Modal from 'antd/lib/modal'
import LoginWithCode from './LoginWithCode'
import Button from './Button'

if (!process.env.REACT_APP_GOOGLE_CLIENT_ID) {
  throw new Error('Please set REACT_APP_GOOGLE_CLIENT_ID in your .env file')
}

class Login extends PureComponent {

  constructor () {
    super()
    this.state = {}
    this.showLoginWithCode = this.showLoginWithCode.bind(this)
  }

  componentWillMount () {
    const url = window.location.href
    const params = queryString.parse(url.substring(url.indexOf('#') + 2))
    if (params && params.id_token) {
      this.props.dispatch(create('/signin', {id_token: params.id_token, provider: 'google'}))
    }
  }

  render () {
    return <Modal
      className="Login"
      style={{top: -2}}
      visible={!this.props.isLoggedIn}
      maskClosable={false}
      title={<FormattedMessage id="login.title" />}
      closable={false}
      footer={null}
    >
      {!this.state.isLoginWithCode && <LoginWithGoogle
        uxMode="redirect"
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        onSuccess={this.handleGoogleResponse.bind(this)}
        onFailure={this.handleGoogleError.bind(this)}
        render={renderProps => (
          <Button title="login.with-google" className="LoginWithGoogle" onClick={renderProps.onClick} disabled={renderProps.disabled} />
        )}
      />}
      <LoginWithCode onClick={this.showLoginWithCode} />
    </Modal>
  }

  showLoginWithCode (toggle) {
    this.setState({isLoginWithCode: toggle})
  }

  handleGoogleError (error) {
    console.error(error.details || error)
  }

  handleGoogleResponse (response) {
    if (!response) return
    // add username
    this.props.dispatch(login(response.profileObj))
    // make request
    this.props.dispatch(create('/signin', {id_token: response.tokenId, provider: 'google'}))
  }

}

function mapStateToProps (state, props) {
  return {isLoggedIn: !!state.user.access_token}
}

export default connect(mapStateToProps)(Login)
