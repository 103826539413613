import formatDate from '../utils/formatDate'

export default {
  componentType: 'search',
  objectName: 'series',
  sortLevel: 17,
  defaultSorting: '-created_at',
  url: '/search/?filter=series',
  title: 'book-series',
  getUrl: data => '/book-series/' + data.id,
  table: [
    {
      title: 'title',
      width: 800,
      fixed: true,
      sortBy: 'name',
      getValue: data => data.name
    },
    {
      title: 'createdAt',
      width: 100,
      sortBy: 'created_at',
      getValue: data => formatDate(data.createdAt)
    }
  ]
}
