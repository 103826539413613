import formatDate from '../utils/formatDate'

export default {
  componentType: 'search',
  objectName: 'branch',
  defaultSorting: '-created_at',
  url: '/search/?filter=branch',
  title: 'branches',
  getUrl: data => '/branch/' + data.id,
  table: [
    {
      title: 'institution',
      width: 180,
      fixed: true,
      sortBy: 'institution.code',
      filterBy: 'institution?response[type]=short',
      getValue: data => data.institution ? data.institution.code : null
    },
    {
      title: 'title',
      width: 450,
      fixed: true,
      sortBy: 'name',
      getValue: data => data.name
    },
    {
      title: 'titleEn',
      width: 250,
      fixed: false,
      sortBy: 'name_en',
      getValue: data => data.nameEn
    },
    {
      title: 'published',
      width: 150,
      sortBy: 'published_on_web.name',
      getValue: data => data.publishedOnWeb ? data.publishedOnWeb.name : null
    },
    {
      title: 'users.email',
      width: 200,
      sortBy: 'email',
      getValue: data => data.email
    },
    {
      title: 'institutions.phone',
      width: 200,
      sortBy: 'phone',
      getValue: data => data.phone
    },
    {
      title: 'createdAt',
      width: 120,
      sortBy: 'created_at',
      getValue: data => formatDate(data.createdAt)
    }
  ]
}
