import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Menu from 'antd/lib/menu'
import Dropdown from 'antd/lib/dropdown'
import Icon from 'antd/lib/icon'
import { logout } from '../redux/actions/userActions'
import { create } from '../redux/actions/apiActions'
import { injectIntl } from 'react-intl'
import Notification from './Notification'
import Raven from 'raven-js'
import uuid from 'uuid'

class UserMenu extends PureComponent {

  render () {
    const { user } = this.props

    if (user == null || user.email == null) return null

    Raven.setUserContext({
      name: user.name,
      email: user.email
    })

    const menu = (
      <Menu onClick={this.menuClicked.bind(this)}>
        <Menu.Item key="feedback">
          {this.props.intl.formatMessage({id: 'feedback'})}
        </Menu.Item>
        <Menu.Item key="logout">{this.props.intl.formatMessage({id: 'logout'})}</Menu.Item>
      </Menu>
    )

    const username = (user.name && user.name.trim() !== '')
      ? user.name
      : user.email.substr(0, user.email.indexOf('@'))

    return <span className="UserMenu">
      <Dropdown overlay={menu} placement="topLeft">
        <span className="UserMenu__dropdown" href="#">
          <Icon type="user" /> {username} <Icon type="down" />
        </span>
      </Dropdown>
    </span>
  }

  menuClicked ({item, key, keyPath}) {
    const intl = this.props.intl
    if (key === 'feedback') {
      Raven.captureException(`Sentry Feedback Error: ${uuid()}`)
      Raven.showReportDialog({
        lang: 'ru',
        title: intl.formatMessage({id: 'feedback.title'}),
        subtitle: '',
        subtitle2: '',
        labelName: intl.formatMessage({id: 'feedback.login'}),
        labelEmail: intl.formatMessage({id: 'feedback.email'}),
        labelComments: intl.formatMessage({id: 'feedback.comments'}),
        labelSubmit: intl.formatMessage({id: 'feedback.submit'}),
        labelClose: intl.formatMessage({id: 'feedback.close'}),
        successMessage: intl.formatMessage({id: 'feedback.success-message'}),
        errorFormEntry: intl.formatMessage({id: 'feedback.error-from-entry'}),
        errorGeneric: intl.formatMessage({id: 'feedback.error-generic'})
      })
    }

    if (key === 'logout') {
      this.props.dispatch(create('/signout'))
      this.props.dispatch(logout())
      Notification.success(this.props.intl.formatMessage({id: 'notification.logged-out'}))
    }
  }

}

function mapStateToProps (state, params) {
  return {user: state.user}
}

export default connect(mapStateToProps)(injectIntl(UserMenu))
