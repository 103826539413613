import React from 'react'
import { FormattedMessage } from 'react-intl'
import classnames from 'classnames'

const Button = ({ title, children, type, loading, className, ...rest }) => {
  return <button disabled={loading} className={classnames('Button', type, className)} {...rest}>
    {title ? <FormattedMessage id={title} /> : children}
  </button>
}

export default Button
