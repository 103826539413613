import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import Localize from './layouts/Localize'
import ClickCatcher from './layouts/ClickCatcher'
import KeyCatcher from './layouts/KeyCatcher'
import MainLayout from './layouts/MainLayout'
import configureStore from './redux/configureStore'
import './styles/generated.css'
import GlobalFileUploader from './components/GlobalFileUploader'

const store = configureStore()
export default store

ReactDOM.render(
  <Provider store={store}>
    <Localize>
      <Router>
        <ClickCatcher>
          <KeyCatcher>
            <GlobalFileUploader>
              <MainLayout />
            </GlobalFileUploader>
          </KeyCatcher>
        </ClickCatcher>
      </Router>
    </Localize>
  </Provider>,
  document.getElementById('root')
)
