export default {
  componentType: 'card',
  endpoint: '/user(/:guid)',
  objectName: 'user',
  url: '/users/:guid',
  getUrl: data => '/users/' + data.id,
  title: 'users',
  titleCreate: 'users.create',
  getValue: data => data.name,
  tabs: [
    {
      title: 'tab.basis',
      type: 'basis',
      children: [
        {
          title: 'user',
          icon: 'icons/individual.svg',
          required: true,
          components: [
            {name: 'nobr', fieldName: 'email', label: 'users.email', placeholder: 'users.email', style: {width: '100%'}, disableFilled: true},
            {name: 'nobr', fieldName: 'firstnameRu', label: 'users.firstname', placeholder: 'users.firstname', style: {width: '100%'}},
            {name: 'nobr', fieldName: 'lastnameRu', label: 'users.lastname', placeholder: 'users.lastname', style: {width: '100%'}},
            {name: 'Select', relationship: 'userRole', kind: 'user_role', label: 'users.role', style: {width: '100%'}},
            {name: 'Select', relationship: 'institution', label: 'institution', endpointParams: {'response[type]': 'short'}, style: {width: '100%'}, disableFilled: true}
          ],
          side: 'left'
        },
        {
          title: 'users.settings',
          icon: 'icons/select.svg',
          components: [
            {name: 'Select', relationship: 'fileAccessLevel', kind: 'file_access_level', allowClear: true, label: 'users.file-access-level', style: {width: '100%'}},
            {name: 'Bool', fieldName: 'exportStatistics', label: 'users.export-statistics'}
          ],
          side: 'right'
        },
        {
          title: 'users.data',
          icon: 'icons/biography-icon.svg',
          components: [
            {name: 'nobr', fieldName: 'name', label: 'users.name', placeholder: 'users.name', disabled: true, style: {width: '100%'}},
            {name: 'nobr', fieldName: 'lastUsedClientUpdatedAt', label: 'users.lastseen', placeholder: 'no-data', disabled: true, format: "datetime", style: {width: '100%'}},
            {name: 'nobr', fieldName: 'lastUsedClient', label: 'users.lastversion', placeholder: 'no-data', disabled: true, style: {width: '100%'}}
          ],
          side: 'right'
        }
      ]
    }
  ]
}
