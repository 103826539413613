import { CALL_API } from '../middleware/api'
import formatParams from '../../utils/formatParams'
// import util from 'util'
import snakeCase from 'lodash.snakecase'

export function get (endpoint, endpointParams, callback) {
  var requestUrl = endpoint
  if (endpointParams) {
    requestUrl += formatParams(endpointParams)
  }
  return {
    callback,
    [CALL_API]: {
      endpoint,
      endpointParams,
      requestUrl
    }
  }
}

export function create (endpoint, data, callback) {
  return {...request(endpoint, data, 'POST'), callback}
}

export function remove (endpoint, data, callback) {
  return {...request(endpoint, data, 'DELETE'), callback}
}

export function update (endpoint, data, callback) {
  // var cache = []
  // let body = JSON.stringify(data, function (key, value) {
  //   if (typeof value !== 'object' || value === null) return value
  //   if (cache.indexOf(value) !== -1) return
  //   cache.push(value)
  //   return value
  // })
  // console.log('body', JSON.parse(body))
  // console.log('util', util.inspect(data))
  // cache = null

  // const body = JSON.stringify(data)

  return {...request(endpoint, data, 'PATCH'), callback}
}

function request (endpoint, data, method) {
  const body = JSON.stringify(data, function (key, value) {
    if (value && typeof value === 'object') {
      var replacement = Array.isArray(value) ? [] : {}
      for (var k in value) {
        if (Object.hasOwnProperty.call(value, k)) {
          replacement[snakeCase(k)] = value[k]
        }
      }
      return replacement
    }
    return value
  })

  const options = {
    method,
    body
  }

  return {
    [CALL_API]: {
      options,
      endpoint,
      requestUrl: endpoint
    }
  }
}
