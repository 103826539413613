import formatDate from '../utils/formatDate'

export default {
  componentType: 'table',
  objectName: 'storageLocation',
  endpoint: '/storage_location',
  endpointParams: {
    'page[size]': 100,
    'page[number]': 1,
    'filter[relationships][institution][id]': 'all',
    'filter[group]': 'raan',
    'sort': 'institution_id'
  },
  url: '/storage-location',
  title: 'storage-locations',
  getUrl: data => '/storage-location/' + data.id,
  table: [
    {
      title: 'institution',
      width: 180,
      getValue: data => data.institution ? data.institution.code : null
    },
    {
      title: 'place',
      width: 200,
      fixed: true,
      getValue: data => data.place ? data.place.name : null
    },
    {
      title: 'title',
      width: 600,
      fixed: true,
      getValue: data => data.name
    },
    {
      title: 'createdAt',
      width: 100,
      getValue: data => formatDate(data.createdAt)
    },
    {
      title: 'individuals.table.createdBy',
      width: 120,
      getValue: data => data.createdBy
    }
  ]
}
