export default {
  componentType: 'card',
  endpoint: '/storage_location(/:guid)',
  objectName: 'storageLocation',
  url: '/storage-location/:guid',
  getUrl: data => '/storage-location/' + data.id,
  title: 'storage-location',
  titleCreate: 'storage-locations.create',
  getValue: data => data.name,
  tabs: [
    {
      title: 'tab.basis',
      type: 'basis',
      children: [
        {
          title: 'storage-location',
          icon: 'icons/location-icon.svg',
          required: true,
          components: [
            {name: 'Select', relationship: 'place', endpointParams: {'page[size]': '20'}, kind: 'place', label: 'place', style: {width: 'calc(70% - 20px)'}, createNew: true, createObjectName: 'place'},
            {name: 'nobr', fieldName: 'editableName', label: 'title', placeholder: 'title', style: {width: '100%'}},
            {name: 'nobr', fieldName: 'editableNameEn', label: 'storage-locations.nameEn', placeholder: 'storage-locations.nameEn', style: {width: '100%'}},
            {name: 'Select', relationship: 'institution', label: 'institution', endpointParams: {'response[type]': 'short'}, style: {width: '100%'}}
          ],
          side: 'left'
        }
      ]
    }
  ]
}
