import formatDate from '../utils/formatDate'

export default {
  componentType: 'search',
  objectName: 'place',
  sortLevel: 18,
  defaultSorting: 'name',
  url: '/search/?filter=place',
  title: 'places',
  getUrl: data => '/places/' + data.id,
  table: [
    {
      title: 'title',
      width: 250,
      fixed: true,
      sortBy: 'name',
      getValue: data => data.name
    },
    {
      title: 'places.nameEn',
      width: 250,
      fixed: true,
      sortBy: 'name_en',
      getValue: data => data.nameEn
    },
    {
      title: 'places.parent',
      width: 250,
      fixed: true,
      sortBy: 'parent.name',
      getValue: data => data.parent ? data.parent.name : null
    },
    {
      title: 'related-records-count',
      width: 150,
      sortBy: 'records_count',
      getValue: data => data.recordsCount
    },
    {
      title: 'createdAt',
      width: 100,
      sortBy: 'created_at',
      getValue: data => formatDate(data.createdAt)
    },
    {
      title: 'individuals.table.createdBy',
      width: 165,
      sortBy: 'created_by',
      filterBy: 'user?filter[created_type]=place',
      filterMode: 'multiple',
      getValue: data => data.createdBy
    }
  ]
}
