import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import build from 'redux-object'
import { get } from '../redux/actions/apiActions'
import { addInitialData } from '../redux/actions/editingCard'
import CardLayout from '../layouts/CardLayout'
import constructUrl from '../utils/constructUrl'
import handleError from '../utils/handleError'
import diff from 'deep-diff'
import { injectIntl } from 'react-intl'

const getCleanMatch = function (match) {
  let cleaned = {}
  Object.keys(match).forEach(key => {
    const indexOfQuery = match[key].indexOf('?')
    cleaned[key] = (indexOfQuery >= 0) ? match[key].substring(0, indexOfQuery) : match[key]
  })
  return cleaned
}

class CardContainer extends Component {

  componentWillMount () {
    this.fetchInitialData.bind(this)(this.props)
  }

  shouldComponentUpdate (nextProps) {
    const difference = (!nextProps && !this.props) ? [] : diff(nextProps, this.props) || []
    const isChanged = difference.filter(d => d.kind !== 'N' || d.path[d.path.length - 1].indexOf('__') !== 0).length > 0
    return isChanged
  }

  componentWillReceiveProps (nextProps) {
    // dont refetch for the same page
    if (getCleanMatch(nextProps.match).guid === getCleanMatch(this.props.match).guid) return
    this.fetchInitialData.bind(this)(nextProps)
  }

  render () {
    return <CardLayout {...this.props} />
  }

  fetchInitialData (props) {
    if (props.config.fetchInitialData === false) {
      return props.dispatch(addInitialData(props.tab.id, {}))
    }
    const url = constructUrl(props.config.endpoint, getCleanMatch(props.match))
    props.dispatch(get(url, null, this.handleInitialData.bind(this)))
  }

  handleInitialData (error, data, normalisedData) {
    if (error) return handleError(error, this.props.intl)
    this.props.dispatch(addInitialData(this.props.tab.id, normalisedData))
  }

}

CardContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
}

function isLoading (meta) {
  if (meta == null || meta[''] == null) return true
  return meta[''].loading
}

function mapStateToProps (state, props) {
  if (props.config.fetchInitialData === false) return {data: {}, loading: false}
  if (props.tab.currentData == null) return {data: {}, loading: true}
  if (!props.config.objectName || !getCleanMatch(props.match).guid) return {data: {}, loading: true}
  const data = build(props.tab.currentData, props.config.objectName, getCleanMatch(props.match).guid, {includeType: true})
  if (data == null) return {data: {}, loading: true}
  const url = constructUrl(props.config.endpoint, getCleanMatch(props.match))
  const loading = isLoading(state.data.meta[url])
  return {data, loading}
}

export default connect(mapStateToProps)(injectIntl(CardContainer))
