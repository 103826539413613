import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { openNewTab } from '../redux/actions/manipulateTabs'
import { create } from '../redux/actions/apiActions'

class InternalLink extends PureComponent {

  constructor (props) {
    super(props)
    this.state = {
      visited: false
    }
  }

  render () {
    if (!this.props.url) return null
    
    let className = ''
    
    if (this.state.visited || this.props.wasVisited) {
      className += ' InternalLink__visited'
    }

    return (
      <Link 
        to={this.props.url} 
        onClick={e => {
          e.preventDefault()

          this.props.dispatch(openNewTab(this.props.url, true))

          if (this.props.markVisited) {
            this.setState({visited: true})
          }

          if (this.props.logVisitEndpoint && !this.props.wasVisited) {
            this.props.dispatch(create(
              this.props.logVisitEndpoint, 
              {}, 
              (error, data, normalised) => {
                if (this.props.visitDataPath) {
                  this.props.dispatch({
                    type: 'DATA_UPDATE_STORE',
                    path: this.props.visitDataPath,
                    value: true
                  })
                }
              }
            ))
          }
        }}
        className={className}
      >
        {this.props.text ? this.props.text : null}
      </Link>
    )
  }

}

export default connect()(InternalLink)
