import moment from 'moment'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import Editable from '../components/Editable'
import Icon from 'antd/lib/icon'
import Popconfirm from 'antd/lib/popconfirm'
import Tooltip from 'antd/lib/tooltip'
import generateTooltip from '../utils/generateTooltip'
import {remove, get, update, create} from '../redux/actions/apiActions'
import { openActiveTabPopUp } from '../redux/actions/editingCard'
import {injectIntl} from 'react-intl'
import bytes from 'bytes'
import Notification from '../components/Notification'
import SelectComponent from '../components/SelectComponent'
import build from 'redux-object'
import SwitchStyled from '../fieldsStyled/SwitchStyled'
import Button from '../components/Button'

class ExistingFile extends Component {

  constructor () {
    super()
    this.state = {}
  }

  componentDidUpdate () {
    this.checkSelectFile()
  }

  render () {
    const {file, intl} = this.props
    if (file.deleted === true || Object.keys(file).length === 0) return null

    const size = file.size
      ? <span className="Files__size">({bytes(file.size)})</span>
      : null
    const coverOptions = [
      {id: true, name: intl.formatMessage({id: 'yes'})},
      {id: false, name: intl.formatMessage({id: 'no'})}
    ]
    const frameOptions = [
      {id: true, name: intl.formatMessage({id: 'yes'})},
      {id: false, name: intl.formatMessage({id: 'no'})}
    ]

    let selectedStyle = ''
    if (file.selected) {
      selectedStyle = 'Files__each-selected'
    }

    const getOptionTooltip = file => {
      if (!file.createdAt) return

      const createdAt = moment(file.createdAt).format('DD.MM.YYYY HH:mm')
      const createdBy = file.createdBy
      const md5 = file.md5 || '?'
      const size = file.size || '?'
      const key = file.key || '?'
      const lto = file.ltoName || '?'

      const tooltipDataWithCaptions = [[createdAt, 'files.created-at'], [createdBy, 'user'], [md5, 'files.md5'], [size, 'files.size'], [key, 'files.key'], [lto, 'files.lto.tape-number']]

      return tooltipDataWithCaptions.map(arr => intl.formatMessage({id: arr[1]}) + ': ' + arr[0]).join(", ")
    }
    const tooltip = generateTooltip(file, getOptionTooltip)

    const coldStorageFile = file.storageName === 'cold'
    const showFileUrl = !coldStorageFile || file.downloadAbilityStatus === 'available'
    const showRequestDownloadButton = file.downloadAbilityStatus === 'available_after_request'
    return <div className={"Files__each " + selectedStyle}>
      <div className="Files__header">
        <input 
          type="checkbox" 
          className="Files__checkbox" 
          checked={file.selected ? file.selected : false} 
          onClick={this.selectFile.bind(this)} 
        />
        {file.url && showFileUrl
          ? <a className="Files__download" href={file.url}><Icon type="download" /> {file.extension} {size}</a>
          : <span className="Files__download">{file.extension} {size}</span>
        }
        {tooltip &&
          <Tooltip placement="top" title={tooltip} overlayStyle={{ 'maxWidth': '400px'}}>
            <Icon className="Files__info" type="info-circle" />
          </Tooltip>
        }
        {
          file.couldBeCover && !coldStorageFile &&
          (
            <span className="Files__fullscreen" onClick={this.openPreview.bind(this)}>
              <Icon type="fullscreen" />
            </span>
          )
        }
        
        <Popconfirm
          placement="topRight"
          arrowPointAtCenter
          title={intl.formatMessage({id: 'notification.sure'})}
          onConfirm={this.deleteFile.bind(this)}
          okText={intl.formatMessage({id: 'notification.yes'})}
          cancelText={intl.formatMessage({id: 'notification.no'})}
        ><div className="Files__delete" /></Popconfirm>
      </div>
      <div className="Files__preview">
        <div className="Files__preview-background" />
        <div className="Files__preview-image" style={{backgroundImage: 'url(' + file.previewUrl + ')'}} />
      </div>
      <div className="Files__details">
        <Editable
          type="input"
          value={this.state.name || file.name}
          onChange={this.changeName.bind(this)}
          disabled={coldStorageFile}
        />
        {!coldStorageFile &&
          <SelectComponent
            endpoint="/file_access_level"
            value={file.fileAccessLevel}
            label="files.access-level"
            onChange={this.changeAccessLevel.bind(this)}
          />
        }
        {coldStorageFile &&
          <Editable
            type="input"
            label="files.lto.tape-number"
            value={file.ltoName}
            disabled
          />
        }
        {coldStorageFile &&
          <Editable
            type="input"
            label="files.download-ability-status"
            value={intl.formatMessage({id: 'files.download-ability-status-' + file.downloadAbilityStatus})}
            disabled
          />
        }
        {coldStorageFile && showRequestDownloadButton &&
          <Popconfirm
            placement="topRight"
            arrowPointAtCenter
            title={this.props.intl.formatMessage({ id: 'notification.sure' })}
            onConfirm={this.requestDownloadColdStorage.bind(this)}
            okText={this.props.intl.formatMessage({ id: 'notification.yes' })}
            cancelText={this.props.intl.formatMessage({ id: 'notification.no' })}
          >
            <Button
              title="files.request-download-cold-storage"
            />
          </Popconfirm>
        }
        {file.couldBeCover && !coldStorageFile &&
          <SwitchStyled
            label="files.is-cover"
            value={file.cover}
            options={coverOptions}
            onChange={this.handleCoverChange.bind(this)}
          />
        }
        {file.couldBeCover && !coldStorageFile &&
          <SwitchStyled
            label="files.show-in-frame"
            value={file.showWithFrame}
            options={frameOptions}
            onChange={this.handleFrameChange.bind(this)}
          />
        }
        {file.couldBeCopy &&
          <SelectComponent
            endpoint="/file_original_or_copy"
            value={file.fileOriginalOrCopy}
            label="files.original-or-copy"
            onChange={this.changeOriginalOrCopy.bind(this)}
          />
        }
      </div>
    </div>
  }

  /**
   * Check that file selected in 'tab' data or in 'data' data
   */
  checkSelectFile () {
    if (this.props.selected !== this.props.file.selected) {
      const {file, dispatch, dataId, objectName, tabId} = this.props
      dispatch({
        type: 'SELECT_FILE', 
        id: file.id, 
        dataId, 
        objectName, 
        tabId,
        checked: (this.props.selected || this.props.file.selected)
      })
    }
  }

  selectFile (event) {
    const {file, dispatch, dataId, objectName, tabId} = this.props
    dispatch({
      type: 'SELECT_FILE', 
      id: file.id, 
      dataId, 
      objectName, 
      tabId,
      checked: event.target.checked
    })
  }

  openPreview () {
    const {file} = this.props
    this.props.dispatch(openActiveTabPopUp(file.previewUrl))
  }

  deleteFile () {
    const {file, dispatch, intl, dataId, objectName, tabId} = this.props
    dispatch(remove('/file/' + file.id, null, (error, result) => {
      if(error) {
        const errorMessage = JSON.stringify(error)
        const errorStatusCode = error.errors && error.errors[0] && error.errors[0].status

        if (errorStatusCode !== 404 && errorMessage !== '{}') {
          const msg = intl.formatMessage({id: 'notification.server.error'})
          return Notification.error(msg + ': ' + errorMessage)
        }
      }
      const msg = intl.formatMessage({id: 'notification.file.deleted'})
      dispatch({type: 'DELETE_FILE', id: file.id, dataId, objectName, tabId})
      return Notification.success(msg)
    }))
  }

  handleCoverChange (e) {
    this.props.dispatch(update('/file/' + this.props.fileId, {
      data: {
        type: 'file',
        attributes: {
          cover: e.target.value
        }
      }
    }, this.handleSave.bind(this)))
  }

  handleFrameChange (e) {
    this.props.dispatch(update('/file', {
      data: [
        {
          type: 'file',
          id: this.props.fileId,
          attributes: {
            show_with_frame: e.target.value
          }
        }
      ]
    }, this.handleSave.bind(this)))
  }

  changeAccessLevel (newvalue) {
    this.props.dispatch(update('/file/' + this.props.fileId, {
      data: {
        type: 'file',
        relationships: {
          file_access_level: {
            data: {id: newvalue.id}
          }
        }
      }
    }, this.handleSave.bind(this)))
  }

  changeOriginalOrCopy (newvalue) {
    this.props.dispatch(update('/file/' + this.props.fileId, {
      data: {
        type: 'file',
        relationships: {
          file_original_or_copy: {
            data: {id: newvalue.id}
          }
        }
      }
    }, this.handleSave.bind(this)))
  }

  handleSave (error, result) {
    if (error && JSON.stringify(error) !== '{}') {
      const msg = this.props.intl.formatMessage({id: 'notification.server.error'})
      return Notification.error(msg + ': ' + JSON.stringify(error))
    }
    const msg = this.props.intl.formatMessage({id: 'notification.card.update.success'})
    this.props.dispatch(get('/file/' + this.props.fileId))
    return Notification.success(msg)
  }

  changeName (name) {
    this.setState({ name })
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(this.saveName.bind(this), 1000)
  }

  saveName () {
    this.props.dispatch(update('/file/' + this.props.fileId, {
      data: {
        type: 'file',
        attributes: {
          name: this.state.name
        }
      }
    }, this.handleSave.bind(this)))
  }

  requestDownloadColdStorage () {
    this.props.dispatch(create('/file/restore_request/' + this.props.fileId,
      {}, this.handleSave.bind(this)))
  }

}

const mapStateToProps = (state, props) => {
  if (!props.fileId) return {file: {}}
  if (
    state.data &&
    state.data.file &&
    state.data.file[props.fileId]
  ) {
    const file = build(state.data, 'file', props.fileId, {includeType: true})
    return {file}
  }
  const found = Object.keys(state.files)
    .map(udid => state.files[udid])
    .filter(file => file.id === props.fileId)[0]

  return {file: found || {}}
}

export default connect(mapStateToProps)(injectIntl(ExistingFile))
