import React, { PureComponent } from 'react'
import { CKEditor as CKEditor5 } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { editField } from '../redux/actions/editingCard'
import jsonPointer from 'json-pointer'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'

import addData from '../utils/addData'

let applicationDataId = ''
let userAccessToken = ''

class UploadAdapter {

  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file
      .then(file => new Promise((resolve, reject) => {
        this._initRequest();
        this._initListeners(resolve, reject, file);
        this._sendRequest(file);
    }));
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _initRequest() {
    const xhr = this.xhr = new XMLHttpRequest();
    xhr.open('POST', `${process.env.REACT_APP_UPLOAD_HOST}/file/storage`, true);
    xhr.setRequestHeader("authorization", 'Bearer ' + userAccessToken);
    xhr.responseType = 'json';
  }

  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${ file.name }.`;

    xhr.addEventListener('error', () => reject( genericErrorText ) );
    xhr.addEventListener('abort', () => reject() );
    xhr.addEventListener('load', () => {
      const response = xhr.response;

      if (!response || response.error) {
        return reject(response && response.error ? response.error.message : genericErrorText);
      }

      resolve({
        default: response.url
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener( 'progress', evt => {
        if ( evt.lengthComputable ) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  _sendRequest(file) {
    const data = new FormData();
    data.append('file', file);
    data.append('document_id', applicationDataId);
    data.append('upload_origin', 'CKEditor');
    this.xhr.send(data);
  }

}

function UploadAdapterPlugin( editor ) {
  editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
      return new UploadAdapter( loader );
  };
}

class CKEditor extends PureComponent {

  constructor (props) {
    super(props)
    this.getObject = this.getObject.bind(this)
    this.setValue = this.setValue.bind(this)
  }

  componentDidUpdate () {
    applicationDataId = this.props.applicationDataId
    userAccessToken = this.props.user.access_token
  }

  getObject ({data, relationship}) {
    if (!relationship) return data
    if (!data[relationship]) return {}
    return data[relationship]
  }

  setValue (newValue) {
    const { config, data, fieldName, tab, relationship, dispatch } = this.props
    const userPointer = '/' + config.objectName + '/' + data.id
    if (!relationship) {
      const pointer = userPointer + '/attributes/' + fieldName
      return dispatch(editField(tab.id, pointer, newValue))
    }
    const relationshipType = jsonPointer.get(tab.currentData, userPointer + '/relationships/' + relationship + '/data/type')
    const object = this.getObject({data, relationship})
    const pointer = '/' + relationshipType + '/' + object.id + '/attributes/' + fieldName
    return dispatch(editField(tab.id, pointer, newValue))
  }
  
  render () {
    const { style, data, relationship, fieldName, placeholder } = this.props
    
    const object = this.getObject({data, relationship})

    const formattedPlaceholder = placeholder ? this.props.intl.formatMessage({id: placeholder}) : ''

    const editorConfig = {
      toolbar: [ 'heading', 'bold', 'italic', 'imageUpload', 'link', 'blockQuote' ],
      heading: {
        options: [
            { 
              model: 'paragraph',
              title: this.props.intl.formatMessage({id: 'ckeditor.heading.paragraph'}), 
              class: 'ck-heading_paragraph' 
            },
            { 
              model: 'heading2',
              view: 'h2',
              title: this.props.intl.formatMessage({id: 'ckeditor.heading.heading_h2'}),
              class: 'ck-heading_heading2'
            }
        ]
      },
      image: {
        toolbar: [ 'imageStyle:alignLeft', 'imageStyle:full' ],
        styles: [
            'full',
            'alignLeft'
        ]
      },
      placeholder: formattedPlaceholder,
      extraPlugins: [ UploadAdapterPlugin ]
    }

    return (
      <div className="CommonField CKEditor" style={style}>
        <CKEditor5
          editor={ClassicEditor}
          config={editorConfig}
          data={object[fieldName] ? object[fieldName] : ''}
          onReady={editor => {
            editor.editing.view.change(writer => {
              writer.setStyle(
                "width",
                "100%",
                editor.editing.view.document.getRoot()
              );
            });
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            this.setValue(data)
          }}
        />
      </div>
    )
  }

}

function mapStateToProps (state, params) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(injectIntl(addData(CKEditor)))