import React from 'react'
import { FormattedMessage } from 'react-intl'
import { editField } from '../redux/actions/editingCard'
import jsonPointer from 'json-pointer'
import addData from '../utils/addData'
import Checkbox from 'antd/lib/checkbox'

const Bool = ({ type, fieldName, disabled, label, style, dispatch, tab, data, config }) => {
  if (!tab || !tab.currentData) return null

  const getObjectPointer = () => {
    return '/' + config.objectName + '/' + data.id + '/attributes/' + fieldName
  }

  const getValue = () => {
    const objectPointer = getObjectPointer()
    if (!objectPointer) return null
    if (!jsonPointer.has(tab.currentData, objectPointer)) return null
    return jsonPointer.get(tab.currentData, objectPointer)
  }

  const setValue = newValue => {
    const objectPointer = getObjectPointer()
    if (!objectPointer) return
    dispatch(editField(tab.id, objectPointer, newValue))
  }

  return <div className="CommonField" style={style}>
    <Checkbox checked={getValue()} disabled={disabled} onChange={e => setValue(e.target.checked)}><FormattedMessage id={label || 'error'} /></Checkbox>
  </div>
}

export default addData(Bool)
