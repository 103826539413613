import url from 'url'
import build from 'redux-object'

export default function getRequestDataByUrl (data, requestUrl) {
  const pathname = url.parse(requestUrl).pathname
  const query = (requestUrl.indexOf('?') > 0)
    ? requestUrl.substr(requestUrl.indexOf('?'))
    : ''
  if (
    !data.meta ||
    !data.meta[pathname] ||
    !data.meta[pathname][query] ||
    !data.meta[pathname][query].data
  ) return []
  const options = data.meta[pathname][query].data
  return options.map(option => {
    if (option.name) return option
    return build(data, option.type, option.id, {includeType: true})
  })
}
