import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { get } from '../redux/actions/apiActions'
import TableComponent from '../components/TableComponent'
import getRowsByRequestParams from '../redux/mapers/getRowsByRequestParams'
import { injectIntl } from 'react-intl'
import getPropsFromUrl from '../utils/getPropsFromUrl'
import handleError from '../utils/handleError'
import { withRouter } from 'react-router'
import { navigateTab } from '../redux/actions/manipulateTabs'
import generateParams from '../utils/generateSearchParams'
import formatParams from '../utils/formatParams'

const cleanPath = function (path) {
  const indexOfQuery = path.indexOf('?')
  return (indexOfQuery >= 0) ? path.substring(0, indexOfQuery) : path
}

class TableContainer extends PureComponent {
  
  componentWillMount () {
    this.path = this.props.tab.path
    this.requestNewPages(this.props)
  }
  
  componentWillReceiveProps (nextProps) {
    if (
      nextProps.tab.path === this.path &&
      nextProps.filters === this.props.filters
    ) {
      return
    }
    if (cleanPath(nextProps.tab.path) === cleanPath(this.path)) {
      this.path = nextProps.tab.path
      this.requestNewPages(nextProps)
    } else {
      this.path = nextProps.tab.path
      this.requestNewPages(nextProps, [1])
    }
  }

  render () {
    if (typeof this.props.config.getUrl === 'function') {
      for (let index in this.props.rows) {
        this.props.rows[index].url = this.props.config.getUrl(this.props.rows[index])
      }
    }
    const sortBy = this.getSortBy(this.props)
    return <TableComponent
      filter={this.props.filter}
      sortTable={this.sortTable.bind(this)}
      sortBy={sortBy}
      loading={this.props.loading}
      rows={this.props.rows}
      columnsConfig={this.props.config.table}
      total={this.props.total}
      requestNewPages={this.requestNewPages.bind(this, this.props)}
    />
  }

  getSortBy (props) {
    const urlSortParam = getPropsFromUrl(props.tab.path).sort
    if (urlSortParam) return urlSortParam
    return props.config.endpointParams ? props.config.endpointParams.sort : ''
  }

  sortTable (sortBy) {
    if (!sortBy) return

    const newSortBy = (sortBy === this.getSortBy(this.props)) ? '-' + sortBy : sortBy
    const indexOfQuery = this.props.tab.path.indexOf('?')
    const cleanPath = (indexOfQuery >= 0) ? this.props.tab.path.substring(0, indexOfQuery) : this.props.tab.path
    const urlParams = getPropsFromUrl(this.props.tab.path)
    const path = cleanPath + formatParams({...urlParams, sort: newSortBy})

    this.props.dispatch(navigateTab(path))
    this.props.history.replace(path)
  }

  requestNewPages (props, pageNumbers) {
    if (pageNumbers == null) {
      pageNumbers = this.pageNumbers || [1]
    } else {
      this.pageNumbers = pageNumbers
    }

    pageNumbers.forEach(pageNumber => {
      let params = {
        ...props.config.endpointParams,
        'page[number]': pageNumber,
        ...props.filters
      }
      const sortBy = this.getSortBy(props)
      if (sortBy) params.sort = sortBy
      this.props.dispatch(get(props.config.endpoint, params, this.handleInitialData.bind(this)))
    })
  }

  handleInitialData (error, data, normalisedData) {
    if (error) return handleError(error, this.props.intl)
  }

}

function mapStateToProps (state, { config, tab, filters }) {
  const urlSortParam = getPropsFromUrl(tab.path).sort
  //const params = urlSortParam ? {...config.endpointParams, sort: urlSortParam} : config.endpointParams
  const searchParams = generateParams(null, {sort: urlSortParam, ...filters, ...config.endpointParams})
  const ret = getRowsByRequestParams(state, {endpoint: config.endpoint, params: searchParams})
  ret.total = ret.meta.total
  return ret
}

export default connect(mapStateToProps)(withRouter(injectIntl(TableContainer)))
