import formatDate from '../utils/formatDate'

export default {
  componentType: 'table',
  objectName: 'kind',
  endpoint: '/kind',
  defaultSorting: 'id',
  endpointParams: {
    'page[size]': 100,
    'page[number]': 1
  },
  url: '/kind',
  title: 'kind.editing',
  getUrl: data => '/kind/' + data.id,
  table: [
    {
      title: 'kind.id',
      width: 400,
      fixed: true,
      sortBy: 'id',
      getValue: data => data.id
    },
    {
      title: 'title',
      width: 400,
      fixed: true,
      sortBy: 'name',
      getValue: data => data.name
    },
    {
      title: 'kind.name-en',
      width: 300,
      sortBy: 'name_en',
      getValue: data => data.nameEn
    },
    {
      title: 'kind.base',
      width: 250,
      sortBy: 'parent_id',
      filterBy: 'kind?view[type]=nested',
      filterMode: 'multiple',
      filterKind: 'id',
      getValue: data => data.parent ? data.parent.name : null
    },
    {
      title: 'kind.show-on-raan-website',
      width: 300,
      sortBy: 'show_on_raan_website_main_filter',
      getValue: data => data.showOnRaanWebsiteMainFilter ? 'Да' : '-'
    },
    {
      title: 'createdAt',
      width: 100,
      sortBy: 'created_at',
      getValue: data => formatDate(data.createdAt)
    },
    {
      title: 'individuals.table.createdBy',
      width: 120,
      sortBy: 'created_by',
      getValue: data => data.createdBy
    }
  ]
}
