import React from 'react'
import { FormattedMessage } from 'react-intl'

import formatDate from '../utils/formatDate'

export default {
  componentType: 'search',
  objectName: 'individual',
  objectType: 'person',
  sortLevel: 1,
  defaultSorting: '-created_at',
  url: '/search/?filter=individual',
  title: 'individualsTable',
  getUrl: data => '/individuals/' + data.id,
  table: [
    // {
    //   title: 'empty',
    //   width: 50,
    //   fixed: true,
    //   disableLink: true,
    //   getValue: data => {
    //     const validationWarnings = data.metadata && data.metadata.validationWarnings ? 
    //       data.metadata.validationWarnings : null

    //     return <ValidationWarning validationWarnings={validationWarnings} />
    //   }
    // },
    {
      title: 'individuals.table.surname',
      width: 200,
      fixed: true,
      sortBy: 'lastname',
      getValue: person => person.lastname
    },
    {
      title: 'individuals.table.name',
      width: 200,
      fixed: true,
      sortBy: 'firstname',
      getValue: person => {
        return (person.firstname || '') + ' ' + (person.midname || '')
      }
    },
    {
      title: 'code',
      width: 100,
      sortBy: 'slug',
      getValue: data => data.slug
    },
    {
      title: 'individuals.table.birthdate',
      width: 120,
      sortBy: 'date_of_birth',
      filterBy: 'date_from?filter[date_of_birth]=individual',
      filterMode: 'date',
      getValue: person => formatDate(person.dateOfBirth)
    },
    {
      title: 'individuals.table.birthplace',
      width: 120,
      sortBy: 'place_of_birth.name',
      getValue: person => person.placeOfBirth ? person.placeOfBirth.name : null
    },
    {
      title: 'events',
      width: 100,
      sortBy: 'rel_event_person_count',
      getValue: data => data.relEventPersonCount
    },
    {
      title: 'documents',
      width: 100,
      sortBy: 'rel_person_document_count',
      getValue: data => data.relPersonDocumentCount
    },
    {
      title: 'individuals.table.gnd',
      width: 100,
      sortBy: 'gnd',
      getValue: person => person.gnd
    },
    {
      title: 'individuals.table.biography',
      width: 100,
      sortBy: 'bio',
      getValue: person => person.bio
    },
    {
      title: 'individuals.table.foreign-agent',
      width: 100,
      sortBy: 'foreign_agent',
      filterBy: 'foreign_agent',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: person => person.foreignAgent ?
        <FormattedMessage id="yes" /> :
        <FormattedMessage id="no" />
    },
    {
      title: 'reviewedByEditor',
      width: 175,
      sortBy: 'reviewed_by_editor',
      filterBy: 'reviewed_by_editor',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: person => person.reviewedByEditor ?
        <FormattedMessage id="yes" /> :
        <FormattedMessage id="no" />
    },
    {
      title: 'needsVerificationByEditor',
      width: 175,
      sortBy: 'needs_verification_by_editor',
      filterBy: 'needs_verification_by_editor',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: person => person.needsVerificationByEditor ?
        <FormattedMessage id="yes" /> :
        <FormattedMessage id="no" />
    },
    {
      title: 'individuals.table.createdAt',
      width: 120,
      sortBy: 'created_at',
      getValue: person => formatDate(person.createdAt)
    },
    {
      title: 'individuals.table.createdBy',
      width: 165,
      sortBy: 'created_by',
      filterBy: 'user?filter[created_type]=individual',
      filterMode: 'multiple',
      getValue: data => data.createdBy
    }
  ]
}
