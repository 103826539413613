import React from 'react'
import LabelWrapper from '../components/LabelWrapper'

const Input = ({label, value, onChange, style, type, ...props}) => {
  return <LabelWrapper label={label}>
    <input
      className="Input"
      type={type || 'text'}
      value={value}
      onChange={onChange}
      style={style}
      {...props}
    />
  </LabelWrapper>
}

export default Input
