import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import diff from 'deep-diff'
import pointer from 'json-pointer'
import Route from 'route-parser'
import Button from '../components/Button'
import { update, get, remove } from '../redux/actions/apiActions'
import { cancelChanges, addInitialData } from '../redux/actions/editingCard'
import { injectIntl } from 'react-intl'
import Notification from './Notification'
import { call } from '../utils/pubSub'
import constructUrl from '../utils/constructUrl'
import Popconfirm from 'antd/lib/popconfirm'
import Icon from 'antd/lib/icon'
import Tooltip from 'antd/lib/tooltip'
import config from '../config'
import { navigateTab } from '../redux/actions/manipulateTabs'
import { withRouter } from 'react-router'
import formatDateTime from '../utils/formatDateTime'
import CardEditorControls from './CardEditorControls'

class CardControls extends PureComponent {

  render() {
    const { intl, data } = this.props

    const infoText = (
      <>
        {(data && data.createdAt) && (
          <div>
            {intl.formatMessage({ id: 'createdAt' })}:{' '}
            {formatDateTime(data.createdAt)}
          </div>
        )}

        {(data && data.createdBy) && (
          <div>
            {intl.formatMessage({ id: 'user' })}:{' '}
            {data.createdBy}
          </div>
        )}
      </>
    )

    return <div className="CardControls">
      <div className="CardControls__left">
        <Button title="save" onClick={this.saveAll.bind(this)} />
        <Button title="update" type="inline" onClick={this.updateCard.bind(this)} />
        <Button title="cancel" type="inline" onClick={this.cancelChanges.bind(this)} />
        <Popconfirm
          placement="topLeft"
          arrowPointAtCenter
          title={intl.formatMessage({ id: 'notification.sure' })}
          onConfirm={this.deleteCard.bind(this)}
          okText={intl.formatMessage({ id: 'notification.yes' })}
          cancelText={intl.formatMessage({ id: 'notification.no' })}
        ><Button title="delete" type="inline" /></Popconfirm>
      </div>
      <div className="CardControls__right">
        <CardEditorControls {...this.props} />

        <Tooltip placement="leftBottom" title={infoText} overlayStyle={{ 'maxWidth': '400px' }}>
          <Icon className="CardControls__info" type="info-circle" />
        </Tooltip>
      </div>
    </div>
  }

  saveAll(e) {
    this.saveCard.apply(this).then(() => {
      call(this.props.tab.id, 'save', () => {
        const route = new Route(this.props.config.url)
        const match = route.match(this.props.tab.path)
        const url = constructUrl(this.props.config.endpoint, match)
        this.props.dispatch(get(url, null, (error, data, normalisedData) => {
          this.props.dispatch(addInitialData(this.props.tab.id, normalisedData))
          Notification.success(this.props.intl.formatMessage({ id: 'notification.card.update.success' }))
        }))
      })
    })
  }

  updateCard(e) {
    const route = new Route(this.props.config.url)
    const match = route.match(this.props.tab.path)
    const url = constructUrl(this.props.config.endpoint, match)

    this.props.dispatch(get(url, null, (error, data, normalisedData) => {
      this.props.dispatch(addInitialData(this.props.tab.id, normalisedData))
      Notification.success(this.props.intl.formatMessage({ id: 'notification.card.update.success' }))
    }))
  }

  async saveCard() {
    if (this.props.tab.initialData == null || this.props.tab.currentData == null) {
      const errorMessage = this.props.intl.formatMessage({ id: 'notification.card.update.error' })
      return Notification.error(errorMessage, { duration: null })
    }
    const differences = diff(this.props.tab.initialData, this.props.tab.currentData)
    if (differences == null) return new Promise((resolve, reject) => resolve())
    // if (differences == null) {
    //   return Notification.warn(this.props.intl.formatMessage({id: 'notification.card.unchanged'}))
    // }
    // const initialData = build(this.props.tab.initialData, this.props.config.objectName, null, {eager: true})[0]

    let modifier = {}
    differences.forEach(eachDiff => {
      console.log('eachDiff', eachDiff, eachDiff.path[0], this.props.config.objectName, eachDiff.path.indexOf('relationships') < 0)
      if (eachDiff.path.indexOf('relationships') < 0) {
        pointer.set(modifier, '/data/type' + eachDiff.path[0])
        pointer.set(modifier, '/data/' + eachDiff.path.slice(2).join('/'), eachDiff.rhs)
      } else {
        const valuePath = '/' + eachDiff.path.join('/')
        if (pointer.has(this.props.tab.currentData, valuePath)) {
          const newValue = pointer.get(this.props.tab.currentData, valuePath)
          const modifierPath = '/data/' + eachDiff.path.slice(2).join('/')
          pointer.set(modifier, modifierPath, newValue)
        }
        if (eachDiff.kind === 'D') {
          const modifierPath = '/data/' + eachDiff.path.slice(2).join('/')
          pointer.set(modifier, modifierPath, null)
        }
        // for (let key in initialData) {
        //   console.log('initialData[key]', key, initialData[key], eachDiff)
        //   if (typeof initialData[key] !== 'object' || initialData[key] == null) continue
        //   if (initialData[key].id !== eachDiff.path[1]) continue
        //   const obj = pointer.get(this.props.tab.currentData, '/data/relationships/' + key + '/data/' + eachDiff.path.slice(2).join('/'))
        //   // pointer.set(modifier, '/data/relationships/' + key + '/data/type', eachDiff.path[0])
        //   pointer.set(modifier, '/data/relationships/' + key + '/data/' + eachDiff.path.slice(2).join('/'), obj)
        //   // break
        // }
      }
    })

    const route = new Route(this.props.config.endpoint)
    const endpoint = route.reverse({ guid: this.props.data.id })

    await this.props.dispatch(update(endpoint, modifier, (error, data, normalised) => {
      if (error) {
        const message = this.props.intl.formatMessage({ id: 'notification.card.update.error' })
        const errorText = error.errors && error.errors[0] ? error.errors[0].detail : JSON.stringify(error)
        return Notification.error(message + ': ' + errorText, { duration: null })
      }
    }))
  }

  cancelChanges(e) {
    const differences = diff(this.props.tab.initialData, this.props.tab.currentData)
    // PubSub.publish(this.props.tab.id, 'cancel')
    call(this.props.tab.id, 'cancel', function () {
      console.log('done cancel')
    })
    if (differences == null) {
      return Notification.warn(this.props.intl.formatMessage({ id: 'notification.card.unchanged' }))
    }
    this.props.dispatch(cancelChanges(this.props.tab.id))
    return Notification.success(this.props.intl.formatMessage({ id: 'notification.card.cancel.success' }))
  }

  deleteCard() {
    const route = new Route(this.props.config.endpoint)
    const endpoint = route.reverse({ guid: this.props.data.id })
    this.props.dispatch(remove(endpoint, null, error => {
      if (error && JSON.stringify(error) !== '{}') {
        const message = this.props.intl.formatMessage({ id: 'notification.card.deleted.error' })
        return Notification.error(message + ': ' + JSON.stringify(error), { duration: null })
      }
      Notification.success(this.props.intl.formatMessage({ id: 'notification.card.deleted.success' }))
      const pages = Object.keys(config.pages).map(key => config.pages[key])
      const table = pages.filter(page => {
        return (page.componentType === 'table' || page.componentType === 'search') && page.objectName === this.props.config.objectName
      })[0]
      this.props.dispatch(navigateTab(table.url))
      this.props.history.push(table.url)
    }))
  }

}

export default connect()(injectIntl(withRouter(CardControls)))
