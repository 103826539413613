import React from 'react'
import { FormattedMessage } from 'react-intl'

export default {
  componentType: 'card',
  endpoint: '/person(/:guid)',
  objectName: 'individual',
  objectType: 'person',
  url: '/individuals/:guid',
  getUrl: data => '/individuals/' + data.id,
  title: 'individualsCard',
  titleCreate: 'individuals.create',
  getValue: data => {
    if (!data) return null
    const langPostfix = (data.defaultNameLang === 'ru') ? 'Ru' : 'En'
    const lastname = data['lastname' + langPostfix]
    const firstname = data['firstname' + langPostfix]
    const midname = data['midname' + langPostfix]
    let name = lastname || ''
    if (name !== '' && firstname) name += ' '
    name += firstname || ''
    if (name !== '' && midname) name += ' '
    name += midname || ''
    return name
  },
  helpLink: 'https://app.raan.garagemca.org/docs/docs/howto#individual',
  showReviewedByEditor: true,
  showNeedsVerificationByEditor: true,
  tabs: [
    {
      title: 'tab.basis',
      type: 'basis',
      children: [
        {
          title: 'individuals.field.basis-name',
          icon: 'icons/names-icon.svg',
          components: {name: 'PersonName'},
          required: true,
          side: 'left'
        },
        {
          title: 'individuals.field.other-name',
          icon: 'icons/other-names-icon.svg',
          components: {name: 'PersonAlternativeName', pointer: '/alternateNames'},
          side: 'left'
        },
        {
          title: 'individuals.field.keywords',
          icon: 'icons/keywords-icon.svg',
          getValue: data => data.keywords,
          components: {name: 'keywords', pointer: '/keywords', placeholder: 'individuals.field.keywords.placeholder', style: {width: '100%'}},
          side: 'left'
        },
        {
          title: 'individuals.field.biography',
          icon: 'icons/biography-icon.svg',
          components: {name: 'text', fieldName: 'bio', placeholder: 'individuals.field.biography.placeholder', style: {width: '100%'}},
          side: 'left'
        },
        {
          title: 'external-services',
          icon: 'icons/media-archive.svg',
          components: [
            { name: 'Bool', fieldName: 'exportToEan', label: 'external-services.export-to-ean' }
          ],
          side: 'left'
        },
        {
          title: 'person-limitations.title',
          icon: 'icons/flag.svg',
          components: [
            { name: 'Bool', fieldName: 'foreignAgent', label: 'person-limitations.recognized-as-foreign-agent' },
            { name: 'nobr', fieldName: 'nameGenitiveCase', label: 'person-limitations.name-genitive-case', style: { width: '100%', marginTop: '15px' } },
            { name: 'nobr', fieldName: 'nameCreativeCase', label: 'person-limitations.name-creative-case', style: { width: '100%' } },
            { 
              name: 'StaticSwitch', 
              fieldName: 'sex', 
              label: 'person-limitations.sex',
              options: [
                {
                  id: 'male',
                  name: <FormattedMessage id="person-limitations.sex.male" />
                },
                {
                  id: 'female',
                  name: <FormattedMessage id="person-limitations.sex.female" />
                }
              ]
            }
          ],
          side: 'left'
        },
        {
          title: 'individuals.field.dates',
          icon: 'icons/calendar-icon.svg',
          getValue: data => data.dateOfBirth,
          components: [
            {name: 'FullDate', fieldName: 'dateOfBirth', label: 'individuals.field.dateOfBirth.placeholder', style: {width: '100%'}},
            {name: 'FullDate', fieldName: 'dateOfDeath', label: 'individuals.field.dateOfDeath.placeholder', style: {width: '100%'}}
          ],
          side: 'right'
        },
        {
          title: 'individuals.field.places',
          icon: 'icons/location-icon.svg',
          getValue: data => data.participations,
          components: [
            {name: 'Select', relationship: 'placeOfBirth', endpointParams: {'page[size]': '20'}, allowClear: true, label: 'individuals.field.places.birth', kind: 'place', style: {width: '50%', paddingRight: '20px'}, createNew: true, createObjectName: 'place'},
            {name: 'Select', relationship: 'placeOfDeath', endpointParams: {'page[size]': '20'}, allowClear: true, label: 'individuals.field.places.death', kind: 'place', style: {width: '50%'}, createNew: true, createObjectName: 'place'}
          ],
          side: 'right'
        },
        {
          title: 'individuals.field.identificators',
          icon: 'icons/identificators-icon.svg',
          getValue: data => data.gnd,
          components: [
            {name: 'input', fieldName: 'slug', label: 'internal-id', disabled: true, style: {width: '100%'}},
            {name: 'input', fieldName: 'gnd', label: 'individuals.field.identificators.gnd', placeholder: 'individuals.field.identificators.gnd', style: {width: 'calc(50%)', paddingRight: '20px'}, externalLink: 'http://d-nb.info/gnd/'},
            {name: 'input', fieldName: 'viaf', label: 'individuals.field.identificators.viaf', placeholder: 'individuals.field.identificators.viaf', style: {width: 'calc(50%)'}, externalLink: 'https://viaf.org/viaf/'}
          ],
          side: 'right'
        },
        {
          title: 'individuals.field.participations',
          icon: 'icons/groups-icon.svg',
          components: [
            {name: 'Relation', pointer: '/relPersonPerson', components: [
              {name: 'Select', relationship: 'person', endpoint: '/person', endpointParams: {'filter[type]': 'group'}, placeholder: 'groups.placeholder'},
              {name: 'Select', relationship: 'role', fixed: 'role.empty'}
            ]}
          ],
          side: 'right'
        },
        {
          title: 'individuals.field.comments-and-contacts',
          icon: 'icons/contacts-icon.svg',
          components: {name: 'text', fieldName: 'contact', placeholder: 'individuals.field.comments-and-contacts.placeholder', style: {width: '100%'}},
          side: 'right'
        },
        {
          title: 'comment',
          icon: 'icons/comment.svg',
          components: {name: 'text', fieldName: 'notes', placeholder: 'comment.placeholder', style: {width: '100%'}},
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.library',
      type: 'library',
      children: [
        {
          title: 'books',
          icon: 'icons/book.svg',
          components: {name: 'Relation', pointer: '/relPersonDocument', filter: {'/document/type': 'book'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'book', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'book,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'left'
        },
        {
          title: 'periodical',
          icon: 'icons/periodic.svg',
          components: {name: 'Relation', pointer: '/relPersonDocument', filter: {'/document/type': 'periodical'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'periodical', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'periodical,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'right'
        },
        {
          title: 'periodical-issues',
          icon: 'icons/periodic.svg',
          components: {name: 'Relation', pointer: '/relPersonDocument', filter: {'/document/type': 'issue'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'issue', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'issue,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'right'
        },
        {
          title: 'articles',
          icon: 'icons/article.svg',
          components: {name: 'Relation', pointer: '/relPersonDocument', filter: {'/document/type': 'article'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'article', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'article,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'left'
        }
      ]
    },
    {
      title: 'tab.archive',
      type: 'archive',
      children: [
        {
          title: 'documents',
          icon: 'icons/documents.svg',
          components: {name: 'Relation', pointer: '/relPersonDocument', filter: {'/document/type': 'archiveDocument'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'archive_document', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'archive_document,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'left'
        },
        {
          title: 'ephemeris',
          icon: 'icons/efemerida.svg',
          components: {name: 'Relation', pointer: '/relPersonDocument', filter: {'/document/type': 'ephemeris'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'ephemeris', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'ephemeris,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'right'
        },
        {
          title: 'images',
          icon: 'icons/photo.svg',
          components: {name: 'Relation', pointer: '/relPersonDocument', filter: {'/document/type': 'image'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'image', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'image,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'left'
        },
        {
          title: 'things',
          icon: 'icons/storage.svg',
          components: {name: 'Relation', pointer: '/relPersonDocument', filter: {'/document/type': 'thing'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'thing', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'thing,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.media',
      type: 'media',
      children: [
        {
          title: 'audios',
          icon: 'icons/audio.svg',
          components: {name: 'Relation', pointer: '/relPersonDocument', filter: {'/document/type': 'audio'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'audio', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'audio,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'left'
        },
        {
          title: 'videos',
          icon: 'icons/video.svg',
          components: {name: 'Relation', pointer: '/relPersonDocument', filter: {'/document/type': 'video'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'video', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'video,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.events',
      type: 'events',
      children: [
        {
          title: 'exhibitions',
          icon: 'icons/frame.svg',
          components: {name: 'Relation', pointer: '/relEventPerson', filter: {'/event/kind/id': /^event.exhibition/}, components: [
            {name: 'Select', relationship: 'event', endpointParams: {'filter[kind][prefix]': 'event.exhibition', 'search[fields]': 'name'}, placeholder: 'event.placeholder', createNew: true, createObjectName: 'event'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'event,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'left'
        },
        {
          title: 'other-events',
          icon: 'icons/event-icon.svg',
          components: {name: 'Relation', pointer: '/relEventPerson', filter: {'/event/kind/id': /^(?!event.exhibition).*/}, components: [
            {name: 'Select', relationship: 'event', endpointParams: {'filter[kind][not_prefix]': 'event.exhibition', 'search[fields]': 'name'}, placeholder: 'event.placeholder', createNew: true, createObjectName: 'event'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'event,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.organisations',
      type: 'organisations',
      children: [
        {
          title: 'organisations',
          icon: 'icons/organisation.svg',
          components: {name: 'Relation', pointer: '/relPersonOrganisation', components: [
            {name: 'Select', relationship: 'organisation', endpointParams: {'search[fields]': 'name'}, placeholder: 'organisations.placeholder', createNew: true, createObjectName: 'organisation'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'organisation,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'left'
        }
      ]
    },
    {
      title: 'tab.research',
      type: 'research',
      children: [
        {
          title: 'research',
          icon: 'icons/comment.svg',
          components: {name: 'Relation', pointer: '/relPersonResearch', components: [
            {name: 'Select', relationship: 'research', placeholder: 'research.placeholder'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'research,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'left'
        }
      ]
    }
  ]
}
