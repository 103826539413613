import React from 'react'
import Editable from '../components/Editable'
import { editField } from '../redux/actions/editingCard'
import jsonPointer from 'json-pointer'
import addData from '../utils/addData'

import {formatDatePlain} from '../utils/formatDateTime'

const CommonField = ({ 
  name,
  label,
  placeholder,
  relationship,
  attribute,
  fieldName,
  disabled,
  disableFilled,
  data,
  initialData,
  tab,
  style,
  dispatch,
  config,
  externalLink,
  format
}) => {
  const getObject = function ({data, relationship, attribute, fieldName}) {
    if (!relationship) return data
    if (!data[relationship]) return {}
    return data[relationship]
  }

  const object = getObject({data, relationship, attribute, fieldName})
  let value = object[fieldName]

  const initialObject = getObject({data: initialData, relationship, attribute, fieldName})
  let initialValue = initialObject[fieldName]

  if (format === "datetime") {
    value = formatDatePlain(value)
    initialValue = formatDatePlain(initialValue)
  }

  const setValue = newValue => {
    const userPointer = '/' + config.objectName + '/' + data.id
    if (!relationship) {
      const pointer = userPointer + '/attributes/' + fieldName
      return dispatch(editField(tab.id, pointer, newValue))
    }
    const relationshipType = jsonPointer.get(tab.currentData, userPointer + '/relationships/' + relationship + '/data/type')
    const pointer = '/' + relationshipType + '/' + object.id + '/attributes/' + fieldName
    return dispatch(editField(tab.id, pointer, newValue))
  }

  return <div className="CommonField" style={style}>
    <Editable
      disabled={
        disabled || 
        Boolean(disableFilled && initialValue)
      }
      label={label}
      type={name}
      placeholder={placeholder}
      value={value}
      onChange={setValue}
      externalLink={externalLink}
    />
  </div>
}

export default addData(CommonField)
