import getCardConfigByType from './getCardConfigByType'

const getCardURLByType = (data) => {
  if (
    typeof data !== 'object' ||
    !data.type
  ) return
  const cardConfig = getCardConfigByType(data.type)
  if (typeof cardConfig !== 'object') return
  if (typeof cardConfig.getUrl !== 'function') return
  return cardConfig.getUrl(data)
}

export default getCardURLByType
