import React from 'react'
import Switch from 'antd/lib/switch'

const Component = ({ loading, checked, disabled, onClick }) => {
  return <Switch
    className="Switch"
    loading={loading}
    checked={checked}
    disabled={disabled}
    onClick={onClick}
  />
}

export default Component
