export default {
  componentType: 'card',
  endpoint: '/institution(/:guid)',
  objectName: 'institution',
  url: '/institutions/:guid',
  getUrl: data => '/institutions/' + data.id,
  title: 'institutions',
  titleCreate: 'institutions.create',
  getValue: data => data.name,
  tabs: [
    {
      title: 'tab.basis',
      type: 'basis',
      children: [
        {
          title: 'institution',
          icon: 'icons/storage.svg',
          required: true,
          components: [
            {name: 'input', fieldName: 'code', label: 'code', placeholder: 'code', style: {width: '20%', marginRight: '20px'}, disableFilled: true},
            {name: 'nobr', fieldName: 'name', label: 'title', placeholder: 'title', style: {width: 'calc(60% - 20px)'}},
            {name: 'nobr', fieldName: 'nameEn', label: 'institutions.nameEn', placeholder: 'institutions.nameEn', style: {width: '80%'}}
          ],
          side: 'left'
        },
        {
          title: 'website-status',
          icon: 'icons/website-icon.svg',
          components: [
            {name: 'Switch', relationship: 'publishedOnWeb', kind: 'published_on_web', style: {float: 'none'}}
          ],
          side: 'right'
        },
        {
          title: 'institutions.leadRu',
          icon: 'icons/description-icon.svg',
          components: {name: 'text', fieldName: 'leadRu', style: {width: '100%'}},
          side: 'left'
        },
        {
          title: 'institutions.leadEn',
          icon: 'icons/description-icon.svg',
          components: {name: 'text', fieldName: 'leadEn', style: {width: '100%'}},
          side: 'left'
        },
        {
          title: 'institutions.websiteRu',
          icon: 'icons/description-icon.svg',
          components: {name: 'nobr', fieldName: 'websiteRu', style: {width: '100%'}},
          side: 'right'
        },
        {
          title: 'institutions.websiteEn',
          icon: 'icons/description-icon.svg',
          components: {name: 'nobr', fieldName: 'websiteEn', style: {width: '100%'}},
          side: 'right'
        },
        {
          title: 'institutions.email',
          icon: 'icons/description-icon.svg',
          components: {name: 'nobr', fieldName: 'email', style: {width: '100%'}},
          side: 'right'
        },
        {
          title: 'institutions.phone',
          icon: 'icons/description-icon.svg',
          components: {name: 'nobr', fieldName: 'phone', style: {width: '100%'}},
          side: 'right'
        },
        {
          title: 'institutions.addressRu',
          icon: 'icons/description-icon.svg',
          components: {name: 'nobr', fieldName: 'addressRu', style: {width: '100%'}},
          side: 'right'
        },
        {
          title: 'institutions.addressEn',
          icon: 'icons/description-icon.svg',
          components: {name: 'nobr', fieldName: 'addressEn', style: {width: '100%'}},
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.description-ru',
      type: 'description-ru',
      children: [
        {
          title: 'tab.description-ru',
          components: [
             {name: 'text', fieldName: 'descriptionRu', placeholder: 'description.placeholder', style: {width: '100%'}}
          ],
          side: 'both'
        }
      ]
    },
    {
      title: 'tab.description-en',
      type: 'description-en',
      children: [
        {
          title: 'tab.description-en',
          components: [
             {name: 'text', fieldName: 'descriptionEn', placeholder: 'collections.descriptionEn.placeholder', style: {width: '100%'}}
          ],
          side: 'both'
        }
      ]
    },
    {
      title: 'tab.branches',
      type: 'branches',
      children: [
        {
          title: 'branches',
          icon: 'icons/storage.svg',
          components: [
            { name: 'TextList', fieldName: 'branches', disabled: true, placeholder: 'branches', style: { width: '100%' }, listStyle: 'none' }
          ],
          side: 'left'
        }
      ]
    },
    {
      title: 'tab.foundations',
      type: 'foundations',
      children: [
        {
          title: 'foundations',
          icon: 'icons/storage.svg',
          components: [
            {name: 'TextList', fieldName: 'collections', disabled: true, placeholder: 'foundations', style: {width: '100%'}, listStyle: 'none'}
          ],
          side: 'left'
        }
      ]
    },
    {
      title: 'tab.storage-locations',
      type: 'storage-locations',
      children: [
        {
          title: 'storage-locations',
          icon: 'icons/storage.svg',
          components: [
            {name: 'TextList', fieldName: 'storageLocations', disabled: true, placeholder: 'storage-locations', style: {width: '100%'}, listStyle: 'none'}
          ],
          side: 'left'
        }
      ]
    },
    {
      title: 'tab.users',
      type: 'users',
      children: [
        {
          title: 'users',
          icon: 'icons/individual.svg',
          components: [
            {name: 'TextList', fieldName: 'users', disabled: true, placeholder: 'users', style: {width: '100%'}, listStyle: 'none'}
          ],
          side: 'left'
        }
      ]
    },
    {
      title: 'tab.files',
      type: 'files',
      children: [
        {
          title: 'tab.files',
          wrapper: false,
          icon: 'icons/media-archive.svg',
          components: [
            {name: 'Files', relationship: 'files'}
          ],
          side: 'both'
        }
      ]
    }
  ]
}

