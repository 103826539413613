export const openNewTab = (path, isActive, parentTabId) => {
  if (isActive !== false) isActive = true
  if (path != null && path.indexOf('#') >= 0) {
    path = path.split('#')[1]
  }
  return {
    type: 'OPEN_NEW_TAB',
    path,
    isActive,
    parentTabId
  }
}

export const closeTab = (id) => ({
  type: 'CLOSE_TAB',
  id
})

export const activateTab = (index) => ({
  type: 'ACTIVATE_TAB',
  index
})

export const navigateTab = (path) => ({
  type: 'NAVIGATE_TAB',
  path
})

export const reorderTab = (oldIndex, newIndex) => ({
  type: 'REORDER_TAB',
  oldIndex, newIndex
})

export const addDataToTab = (tabId, response) => {
  return {
    type: 'ADD_DATA_TO_TAB',
    data: response,
    tabId
  }
}
