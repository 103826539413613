import formatDate from '../utils/formatDate'

export default {
  componentType: 'search',
  objectName: 'issueDraft',
  defaultSorting: '-draft,-created_at',
  url: '/search/?filter=issue_draft',
  title: 'books-draft-issues',
  sortLevel: 23,
  getUrl: data => '/issues-draft/' + data.id,
  table: [
    {
      title: 'issues.periodical',
      width: 300,
      fixed: true,
      sortBy: 'periodical.name',
      getValue: data => data.periodical ? data.periodical.name : null
    },
    {
      title: 'inv',
      width: 100,
      sortBy: 'inv_int',
      getValue: data => data.inv
    },
    {
      title: 'issues.volume',
      width: 50,
      sortBy: 'volume',
      getValue: data => data.volumeStr
    },
    {
      title: 'issues.number',
      width: 100,
      sortBy: 'number',
      getValue: data => data.numberStr
    },
    {
      title: 'issues.date',
      width: 100,
      sortBy: 'date_from',
      filterBy: 'date_from?filter[have_public_records]=issue',
      getValue: data => formatDate(data.dateFrom)
    },
    {
      title: 'issues.createdAt',
      width: 100,
      sortBy: 'created_at',
      getValue: data => formatDate(data.createdAt)
    },
    {
      title: 'individuals.table.createdBy',
      width: 165,
      sortBy: 'created_by',
      filterBy: 'user?filter[created_type]=issue_draft',
      filterMode: 'multiple',
      getValue: data => data.createdBy
    }
  ]
}
