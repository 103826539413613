import React, { PureComponent } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { injectIntl } from 'react-intl'

const toolbar = {
  options: [
    'blockType',
    'link'
  ],
  blockType: {
    inDropdown: false,
    options: ['Normal', 'H2'],
    Normal: { icon: 'Normal', className: 'Normal' }
  },
  link: {
    inDropdown: false,
    showOpenOptionOnHover: true,
    defaultTargetOption: '_blank'
  }
}

class RichTextStyled extends PureComponent {

  render () {
    return <Editor
      wrapperClassName="RichTextStyled"
      editorClassName="RichTextStyled__editor"
      toolbar={toolbar}
      editorState={this.props.editorState}
      onEditorStateChange={this.props.onEditorStateChange}
    />
  }

}

export default injectIntl(RichTextStyled)
