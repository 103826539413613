import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { toggleSearch } from '../redux/actions/searchActions'
import { toggleMenuItem } from '../redux/actions/menuActions'
import config from '../config'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { Collapse } from 'react-collapse'
import LinkIcon from 'react-icons/lib/md/open-in-new'

const getActiveTab = function (tabs) {
  let activeTab = {}
  for (let index in tabs) {
    if (tabs[index].active !== true) continue
    activeTab = tabs[index]
    break
  }
  return activeTab
}

class SideBarMenu extends Component {

  render () {
    this.activeTab = getActiveTab(this.props.tabs)
    return <div className="SideBarMenu">
      <ul>
        {config.menu.map(this.renderChilden.bind(this))}
      </ul>
    </div>
  }

  renderChilden (element) {
    const to = (element.page && element.page.url) || 
      (element.externalLink && element.externalLink.url) ||
      (element.link && element.link.url)
    const text = <FormattedMessage id={element.title} />
    const onSearchClick = element.title === 'search'
      ? () => { this.props.dispatch(toggleSearch()) }
      : (element.children != null)
        ? () => { this.props.dispatch(toggleMenuItem(element.title)) }
        : () => {}
    const className = classNames('SideBarMenu__title', {
      active: (
        (this.activeTab.path.split('&')[0] === to && !this.activeTab.searchShown) || 
        (this.activeTab.searchShown && element.title === 'search')
      ),
      collapsable: (element.children != null)
    })
    const link = () => {
      if (
        (
          element.page != null && 
          !this.props.disabledItems[element.title]
        ) ||
        element.link
      ) {
        return <Link
          className={className}
          to={to}
        >{text}</Link>
      }
      if (element.externalLink != null) {
        return <a
          className={className}
          href={to}
          target="_blank"
          rel="noopener noreferrer"
        ><LinkIcon />{text}</a>
      }
    }
    const arrow = (element.children != null) ? <span className={this.props.hiddenItems[element.title] ? 'arrow isOpened' : 'arrow'}>ˇ</span> : ''
    const title = () => <div className={className} onClick={onSearchClick}>
      {text}
      {arrow}
    </div>
    return <li
      key={element.title}
      className={'SideBarMenu__type-' + element.title}
    >
      {to == null ? title() : link()}
      {element.children == null
        ? null
        : <Collapse isOpened={!this.props.hiddenItems[element.title]}><ul>{element.children.map(this.renderChilden.bind(this))}</ul></Collapse>
      }
    </li>
  }

}

function mapStateToProps (state, params) {
  let disabledItems = {}
  if(state.user && state.user.raan_app_settings && state.user.raan_app_settings.disabled_menu_items) {
    disabledItems = state.user.raan_app_settings.disabled_menu_items
  }
  return {tabs: state.tabs, hiddenItems: state.menu.hiddenItems || {}, disabledItems: disabledItems}
}

export default connect(mapStateToProps)(SideBarMenu)
