export const addFile = (udid, documentId, fileObject) => {
  return {
    type: 'ADD_FILE',
    udid, documentId, fileObject
  }
}

export const removeFile = (udid) => {
  return {
    type: 'REMOVE_FILE',
    udid
  }
}

export const abortFileLoading = (udid) => {
  return {
    type: 'ABORT_FILE_LOADING',
    udid
  }
}

export const updateProgress = (udid, progress) => {
  return {
    type: 'UPDATE_FILE_PROGRESS',
    udid, progress
  }
}

export const updateId = (udid, id) => {
  return {
    type: 'UPDATE_FILE_ID',
    udid, id
  }
}

export const updateFileData = (udid, data) => {
  return {
    type: 'UPDATE_FILE_DATA',
    udid, data
  }
}

export const setError = (udid) => {
  return {
    type: 'UPDATE_FILE_ERROR',
    udid
  }
}
