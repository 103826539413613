import React from 'react'
import Text from '../fields/CommonField'
import NameDefault from '../fields/NameDefault'

const PersonName = (props) => {
  return <div className="PersonName">
    <div className="PersonName__russian">
      <Text {...props} name="input" fieldName="lastnameRu" label="individuals.field.lastnameRu" placeholder="individuals.field.lastnameRu.placeholder" />
      <Text {...props} name="input" fieldName="firstnameRu" label="individuals.field.firstnameRu" placeholder="individuals.field.firstnameRu" />
      <Text {...props} name="input" fieldName="midnameRu" label="individuals.field.midnameRu" placeholder="individuals.field.midnameRu" />
    </div>
    <div className="PersonName__latin">
      <Text {...props} name="input" fieldName="lastnameEn" label="individuals.field.lastnameEn" placeholder="individuals.field.lastnameEn.placeholder" />
      <Text {...props} name="input" fieldName="firstnameEn" label="individuals.field.firstnameEn" placeholder="individuals.field.firstnameEn" />
      <Text {...props} name="input" fieldName="midnameEn" label="individuals.field.midnameEn" placeholder="individuals.field.midnameEn" />
    </div>
    <div className="PersonName__default">
      <NameDefault
        {...props}
        fieldName="defaultNameLang"
        label="individuals.basis-title"
      />
    </div>
  </div>
}

export default PersonName
