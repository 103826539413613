import React from 'react'
import { connect } from 'react-redux'
import NewTabIcon from 'react-icons/lib/fa/expand'
// import NewTabIcon from 'react-icons/lib/fa/external-link-square'
import { openNewTab } from '../redux/actions/manipulateTabs'

const NewTabLink = function ({ url, children, dispatch }) {
  if (!url) return children || null
  return <span
    className="NewTabLink"
    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
  >
    <div className="NewTabLink__children">{children}</div>
    <div
      className="NewTabLink__link"
      style={{ cursor: 'pointer', marginLeft: children && '5px' }}
      onClick={(e) => {
        e.stopPropagation()
        dispatch(openNewTab(url))
      }}
    >
      <NewTabIcon style={{ marginTop: '-1px' }} />
    </div>
  </span>
}

export default connect()(NewTabLink)
