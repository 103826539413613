import React, { PureComponent } from 'react'
import uuid from 'uuid'
import jsonPointer from 'json-pointer'
import { differenceBy } from 'lodash'
import { subscribe } from '../utils/pubSub'
import Editable from '../components/Editable'
import { create, remove } from '../redux/actions/apiActions'
import { storeFieldData } from '../redux/actions/editingCard'
import addData from '../utils/addData'
import produce from 'immer'

const Name = ({value, onChange, isDeletable, onDelete}) => {
  return <div className="PersonAlternativeName__each">
    {isDeletable
      ? <div className="PersonAlternativeName__delete" onClick={onDelete} />
      : null
    }
    <Editable
      type="input"
      value={value.lastname}
      label="individuals.field.lastnameRu"
      placeholder="individuals.field.lastnameRu.placeholder"
      onChange={e => onChange(e, 'lastname')}
    />
    <Editable
      type="input"
      value={value.firstname}
      label="individuals.field.firstnameRu"
      placeholder="individuals.field.firstnameRu"
      onChange={e => onChange(e, 'firstname')}
    />
    <Editable
      type="input"
      value={value.midname}
      label="individuals.field.midnameRu"
      placeholder="individuals.field.midnameRu"
      onChange={e => onChange(e, 'midname')}
    />
  </div>
}

class PersonAlternativeName extends PureComponent {

  componentWillMount () {
    subscribe(this.props.tab.id, this.props.pointer, (msg, done) => {
      if (msg === 'cancel') return this.cancelChanges.apply(this)
      if (msg === 'save') return this.saveChanges.apply(this)
    })
  }

  render () {
    if (Object.keys(this.props.data).length === 0) return null
    const currentValue = this.getValue.apply(this)
    const value = (currentValue == null || currentValue.length < 1)
      ? [{id: uuid()}]
      : produce(currentValue, draftValue => {
          const lastValue = draftValue[draftValue.length - 1]
          if (lastValue.lastname || lastValue.firstname || lastValue.midname) {
            draftValue.push({id: uuid()})
          }
        })
    return <div className="PersonAlternativeName">
      {value.map((name, index) => {
        return <Name
          key={name.id}
          value={name}
          onChange={this.editField.bind(this, name.id)}
          isDeletable={index + 1 !== value.length}
          onDelete={this.deleteField.bind(this, name.id)}
        />
      })}
    </div>
  }

  getInitialValue () {
    const { data, pointer } = this.props
    if (!data || !jsonPointer.has(data, pointer)) return null
    return jsonPointer.get(data, pointer)
  }

  getEditedValue () {
    const { tab, pointer } = this.props
    if (!tab.changes || !jsonPointer.has(tab.changes, pointer)) return null
    return jsonPointer.get(tab.changes, pointer)
  }

  getValue () {
    return this.getEditedValue.apply(this) || this.getInitialValue.apply(this)
  }

  deleteField (id) {
    let currentValue = this.getValue.apply(this)
    if (currentValue == null) return
    currentValue = currentValue.filter(each => each.id !== id)
    const { tab, pointer } = this.props
    this.props.dispatch(storeFieldData(tab.id, pointer, currentValue))
  }

  editField (id, newValue, fieldName) {
    const currentValue = this.getValue.apply(this)
    const valueToUpdate = produce(currentValue, draftValue => {
      if (draftValue == null) {
        draftValue = [{id, [fieldName]: newValue}]
      } else {
        let isEditedValueInArray = false
        for (let i in draftValue) {
          if (draftValue[i].id !== id) continue
          draftValue[i][fieldName] = newValue
          isEditedValueInArray = true
        }
        if (isEditedValueInArray === false) {
          draftValue.push({id, [fieldName]: newValue})
        }
      }
    })
    const { tab, pointer } = this.props
    this.props.dispatch(storeFieldData(tab.id, pointer, valueToUpdate))
  }

  cancelChanges () {
    const { tab, pointer } = this.props
    this.props.dispatch(storeFieldData(tab.id, pointer, null))
  }

  saveChanges () {
    const initialValue = this.getInitialValue.apply(this)
    const editedValue = this.getEditedValue.apply(this)
    if (editedValue == null) return
    const toDelete = differenceBy(initialValue, editedValue, 'id')
    let toAdd = []
    for (let i in editedValue) {
      if (
        initialValue[i] == null ||
        editedValue[i].lastname !== initialValue[i].lastname ||
        editedValue[i].firstname !== initialValue[i].firstname ||
        editedValue[i].midname !== initialValue[i].midname
      ) toAdd.push(editedValue[i])
    }
    // console.log('toDelete, toAdd', toDelete, toAdd)
    const convert = function (array) {
      return array.map(each => ({
        id: each.id,
        attributes: {...each},
        type: 'alternate_name'
      }))
    }
    if (toAdd.length > 0) {
      const data = convert(toAdd)
      this.props.dispatch(create('/person/' + this.props.data.id + '/relationships/alternate_names', {data}))
    }
    if (toDelete.length > 0) {
      const data = convert(toDelete)
      this.props.dispatch(remove('/person/' + this.props.data.id + '/relationships/alternate_names', {data}))
    }
  }

}

export default addData(PersonAlternativeName)
