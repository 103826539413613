export default {
  componentType: 'card',
  endpoint: '/series(/:guid)',
  objectName: 'series',
  url: '/book-series/:guid',
  getUrl: data => '/book-series/' + data.id,
  title: 'bookSeries',
  titleCreate: 'bookSeries.create',
  getValue: data => data.name,
  tabs: [
    {
      title: 'tab.basis',
      type: 'basis',
      children: [
        {
          title: 'bookSeries',
          icon: 'icons/book.svg',
          required: true,
          components: [
            {name: 'nobr', fieldName: 'name', label: 'title', placeholder: 'title', style: {width: '100%'}}
          ],
          side: 'left'
        }
      ]
    }
  ]
}
