export default {
  componentType: 'card',
  endpoint: '/event(/:guid)',
  objectName: 'event',
  objectType: 'event',
  url: '/events/:guid',
  getUrl: data => '/events/' + data.id,
  title: 'eventsCard',
  titleCreate: 'events.create',
  getValue: data => data.name,
  helpLink: 'https://app.raan.garagemca.org/docs/docs/howto#event',
  showReviewedByEditor: true,
  showNeedsVerificationByEditor: true,
  tabs: [
    {
      title: 'tab.basis',
      type: 'basis',
      children: [
        {
          title: 'events.field.event',
          icon: 'icons/event-icon.svg',
          components: [
            {name: 'Select', relationship: 'kind', endpointParams: {'filter[id][prefix]': 'event'}, label: 'events.table.kind', placeholder: 'events.table.kind', style: {width: 'calc(35% - 20px)', marginRight: '20px'}},
            {name: 'nobr', fieldName: 'name', label: 'events.table.title', placeholder: 'events.table.title', style: {width: '65%'}}
          ],
          required: true,
          side: 'left'
        },
        {
          title: 'events.field.nameEn',
          icon: 'icons/event-icon.svg',
          components: [
            {name: 'nobr', fieldName: 'nameEn', label: 'events.field.nameEn', placeholder: 'events.field.nameEn.placeholder', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'events.field.organisations',
          icon: 'icons/organisation-icon.svg',
          components: {name: 'Relation', pointer: '/relEventOrganisation', components: [
            {name: 'Select', relationship: 'organisation', endpointParams: {'search[fields]': 'name'}, placeholder: 'events.relEventOrganisation.placeholder', createNew: true, createObjectName: 'organisation'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'event,organisation'}, placeholder: 'role.placeholder'}
          ]},
          side: 'left'
        },
        {
          title: 'events.field.website',
          icon: 'icons/website-icon.svg',
          getValue: data => data.website,
          components: {name: 'url', pointer: '/website', title: 'events.website', placeholder: 'events.website.placeholder'},
          side: 'left'
        },
        {
          title: 'events.field.keywords',
          icon: 'icons/keywords-icon.svg',
          getValue: data => data.keywords,
          components: {name: 'keywords', pointer: '/keywords', placeholder: 'individuals.field.keywords.placeholder', style: {width: '100%'}},
          side: 'left'
        },
        {
          title: 'events.field.dates',
          icon: 'icons/calendar-icon.svg',
          getValue: data => data.dateFrom,
          components: [
            {name: 'FullDate', fieldName: 'dateFrom', label: 'event.dateFrom', style: {width: '100%'}},
            {name: 'FullDate', fieldName: 'dateTo', label: 'event.dateTo', style: {width: '100%'}}
          ],
          side: 'right'
        },
        {
          title: 'internal-id',
          icon: 'icons/storage.svg',
          components: [
            {name: 'input', fieldName: 'slug', disabled: true}
          ],
          side: 'right'
        },
        {
          title: 'events.field.address',
          icon: 'icons/location-icon.svg',
          getValue: data => data.participations,
          components: {name: 'Select', relationship: 'place', endpointParams: {'page[size]': '20'}, kind: 'place', allowClear: true, style: {width: '100%'}, createNew: true, createObjectName: 'place'},
          side: 'right'
        },
        {
          title: 'events.field.description',
          icon: 'icons/description-icon.svg',
          getValue: data => data.description,
          components: {name: 'text', fieldName: 'description', placeholder: 'events.field.description.placeholder', style: {width: '100%'}},
          side: 'right'
        },
        {
          title: 'events.field.comment',
          icon: 'icons/comment-icon.svg',
          components: {name: 'text', fieldName: 'notes', placeholder: 'events.field.comment.placeholder', style: {width: '100%'}},
          getValue: data => data.notes,
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.participants',
      type: 'participants',
      children: [
        {
          title: 'events.field.individuals',
          icon: 'icons/individual.svg',
          components: {name: 'Relation', pointer: '/relEventPerson', filter: {'/person/type': 'individual'}, components: [
            {name: 'Select', relationship: 'person', endpointParams: {'filter[type]': 'individual'}, placeholder: 'individuals.placeholder', createNew: true, createObjectName: 'individual'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'event,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'left'
        },
        {
          title: 'events.field.groups',
          icon: 'icons/group.svg',
          components: {name: 'Relation', pointer: '/relEventPerson', filter: {'/person/type': 'group'}, components: [
            {name: 'Select', relationship: 'person', endpointParams: {'filter[type]': 'group'}, placeholder: 'individuals.placeholder', createNew: true, createObjectName: 'group'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'event,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'right'
        }
      ]
    },
    {
      title: 'events.related',
      type: 'events',
      children: [
        {
          title: 'exhibitions',
          icon: 'icons/frame.svg',
          components: {name: 'Relation', pointer: '/relEventEvent', filter: {'/event/kind/id': /^event.exhibition/}, components: [
            {name: 'Select', relationship: 'event', endpointParams: {'filter[kind][prefix]': 'event.exhibition', 'search[fields]': 'name'}, placeholder: 'event.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty' }
          ]},
          side: 'left'
        },
        {
          title: 'other-events',
          icon: 'icons/event-icon.svg',
          components: {name: 'Relation', pointer: '/relEventEvent', filter: {'/event/kind/id': /^(?!event.exhibition).*/}, components: [
            {name: 'Select', relationship: 'event', endpointParams: {'filter[kind][not_prefix]': 'event.exhibition', 'search[fields]': 'name'}, placeholder: 'event.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty' }
          ]},
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.library',
      type: 'library',
      children: [
        {
          title: 'events.field.books',
          icon: 'icons/book.svg',
          components: {name: 'Relation', pointer: '/relEventDocument', filter: {'/document/type': 'book'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'book', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.default_event_document'}
          ]},
          side: 'left'
        },
        {
          title: 'events.field.articles',
          icon: 'icons/article.svg',
          components: {name: 'Relation', pointer: '/relEventDocument', filter: {'/document/type': 'article'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'article', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.default_event_document'}
          ]},
          side: 'left'
        },
        {
          title: 'articles.issues',
          icon: 'icons/periodic.svg',
          components: {name: 'Relation', pointer: '/relEventDocument', filter: {'/document/type': 'issue'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'issue', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.default_event_document'}
          ]},
          side: 'left'
        }
      ]
    },
    {
      title: 'tab.archive',
      type: 'archive',
      children: [
        {
          title: 'events.field.documents',
          icon: 'icons/documents.svg',
          components: {name: 'Relation', pointer: '/relEventDocument', filter: {'/document/type': 'archiveDocument'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'archive_document', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.default_event_document'}
          ]},
          side: 'left'
        },
        {
          title: 'events.field.ephemerises',
          icon: 'icons/efemerida.svg',
          components: {name: 'Relation', pointer: '/relEventDocument', filter: {'/document/type': 'ephemeris'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'ephemeris', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.default_event_document'}
          ]},
          side: 'right'
        },
        {
          title: 'events.field.images',
          icon: 'icons/photo.svg',
          components: {name: 'Relation', pointer: '/relEventDocument', filter: {'/document/type': 'image'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'image', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.default_event_document'}
          ]},
          side: 'left'
        },
        {
          title: 'things',
          icon: 'icons/storage.svg',
          components: {name: 'Relation', pointer: '/relEventDocument', filter: {'/document/type': 'thing'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'thing', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.default_event_document'}
          ]},
          side: 'left'
        }
      ]
    },
    {
      title: 'tab.media',
      type: 'media',
      children: [
        {
          title: 'events.field.audios',
          icon: 'icons/audio.svg',
          components: {name: 'Relation', pointer: '/relEventDocument', filter: {'/document/type': 'audio'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'audio', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.default_event_document'}
          ]},
          side: 'left'
        },
        {
          title: 'events.field.videos',
          icon: 'icons/video.svg',
          components: {name: 'Relation', pointer: '/relEventDocument', filter: {'/document/type': 'video'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'video', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.default_event_document'}
          ]},
          side: 'right'
        }
      ]
    }
  ]
}
