import Notification from '../components/Notification'
import store from '../index.js'

const handleError = (error, intl) => {
  if (error && error.errors && error.errors[0] && error.errors[0].status === 401) {
    return store.dispatch({type: 'LOGOUT'})
    // return Notification.info(intl.formatMessage({id: 'notification.server.relogin'}))
  }
  const message = intl.formatMessage({id: 'notification.server.error'})
  const errorText = error.errors ? error.errors[0].detail : JSON.stringify(error)
  Notification.error(message + ': ' + errorText, {duration: null})
}

export default handleError
