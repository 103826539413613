import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Route from 'route-parser'
import Button from './Button'
import Modal from 'antd/lib/modal'
import getCardConfigByType from '../utils/getCardConfigByType'
import getActiveTab from '../utils/getActiveTab'
import FieldsComponent from './FieldsComponent'
import { create } from '../redux/actions/apiActions'
import { openNewTab, closeTab, navigateTab } from '../redux/actions/manipulateTabs'
import Notification from './Notification'
import { injectIntl } from 'react-intl'
import camelCase from 'lodash/camelCase'
import HelpLink from '../components/HelpLink'

class CreateCard extends PureComponent {

  constructor () {
    super()
    this.state = {
      loading: false
    }
  }
  componentDidMount() {
    if (this.props.openModal) {
      this.showModal()
    }
  }
  render () {
    const { loading } = this.state
    const visible = !!this.props.hiddenTabId

    const cardConfig = getCardConfigByType(
      this.props.internalType ? this.props.internalType : this.props.type
    )
    if (cardConfig == null) return null

    const title = <FormattedMessage id={cardConfig.titleCreate || 'add-new'} />
    const titleContent = [title]
    if (cardConfig.helpLink) {
      const helpStyle = {position: "absolute", top: "15px", right: "50px"}
      titleContent.push(<HelpLink url={cardConfig.helpLink} style={helpStyle} />)
    }
    const buttonStyle = {display: !cardConfig.titleCreate || this.props.hideButton ? 'none' : null}
    const footerContent = [
      <Button key="cancel" type="inline" onClick={this.handleCancel.bind(this)}><FormattedMessage id="cancel" /></Button>,
      <Button key="create" loading={loading} onClick={this.handleCreate.bind(this)}><FormattedMessage id="create" /></Button>
    ]

    return <div className="CreateCard">
      <Button onClick={this.showModal.bind(this)} style={buttonStyle}>{title}</Button>
      { 
        this.props.tabId === this.props.activeTabId && (
          <Modal
            className="CreateCard__modal"
            visible={visible}
            title={titleContent}
            onOk={this.handleCreate.bind(this)}
            onCancel={this.handleCancel.bind(this)}
            footer={footerContent}
          >
            <FieldsComponent onlyRequired config={cardConfig} applicationTabId={this.props.hiddenTabId} />
          </Modal>
        ) 
      }
      
    </div>
  }
  showModal () {
    this.props.dispatch(openNewTab(null, false, this.props.activeTabId))
  }
  handleCreate () {
    if (!this.props.hiddenTab) return
    const currentData = this.props.hiddenTab.currentData
    const type = camelCase(this.props.type)
    if (!currentData || !currentData[type]) return
    const cardConfig = getCardConfigByType(
      this.props.internalType ? this.props.internalType : this.props.type
    )
    // console.log('handleCreate', cardConfig, getCardConfigByType(this.props.type))
    const cardData = currentData[type]['undefined']
    const modifier = {
      data: {
        type: this.props.type,
        ...cardData
      }
    }

    const route = new Route(cardConfig.endpoint)
    const endpoint = route.reverse()

    this.setState({loading: true})
    this.props.dispatch(create(endpoint, modifier, (error, data, normalised) => {
      this.setState({loading: false})
      if (error) {
        const errorType = this.props.intl.formatMessage({id: 'notification.card.update.error'})
        const errorText = (error.errors && error.errors[0] && error.errors[0].source)
          ? error.errors[0].source.field + ' ' + error.errors[0].detail
          : JSON.stringify(error)
        return Notification.error(errorType + ': ' + errorText)
      }
      if (this.props.createHandler) {
        this.props.createHandler(data)
      }
      this.handleCancel.call(this)
      if (this.props.hideButton) {
        this.props.dispatch(openNewTab(cardConfig.getUrl(data.data), false))
      } else {
        this.props.dispatch(navigateTab(cardConfig.getUrl(data.data)))
      }
      return Notification.success(this.props.intl.formatMessage({id: 'notification.card.create.success'}))
    }))
  }
  handleCancel () {
    if (this.props.closeModalHandler) {
      this.props.closeModalHandler()
    }
    this.props.dispatch(closeTab(this.props.hiddenTabId))
  }

}

const mapStateToProps = function (state, props) {
  const activeTabId = getActiveTab(state.tabs).id
  const hiddenTab = state.tabs.filter(tab => tab.parentTabId === activeTabId)[0]
  const hiddenTabId = (hiddenTab || {}).id
  return {activeTabId, hiddenTabId, hiddenTab}
}

export default connect(mapStateToProps)(injectIntl(CreateCard))
