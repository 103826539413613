export default {
  componentType: 'card',
  endpoint: '/place(/:guid)',
  objectName: 'place',
  url: '/places/:guid',
  getUrl: data => '/places/' + data.id,
  title: 'places',
  titleCreate: 'places.create',
  getValue: data => data.name,
  tabs: [
    {
      title: 'tab.basis',
      type: 'basis',
      children: [
        {
          title: 'places',
          icon: 'icons/location-icon.svg',
          required: true,
          components: [
            {name: 'nobr', fieldName: 'name', label: 'title', placeholder: 'title', style: {width: '100%'}},
            {name: 'nobr', fieldName: 'nameEn', label: 'places.nameEn', placeholder: 'places.nameEn.placeholder', style: {width: '100%'}},
            {name: 'nobr', fieldName: 'nameDe', label: 'places.nameDe', placeholder: 'places.nameDe.placeholder', style: {width: '100%'}},
            {name: 'Select', relationship: 'parent', kind: 'place', label: 'places.parent', style: {width: '100%'}, endpointParams: {'filter[kind][prefix]': 'place.country'}}
          ],
          side: 'left'
        },
        {
          title: 'code',
          icon: 'icons/storage.svg',
          components: [
            {name: 'input', fieldName: 'slug', disabled: true}
          ],
          side: 'right'
        }
      ]
    }
  ]
}
