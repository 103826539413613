import MergeControls from '../components/MergeControls'

export default {
  componentType: 'card',
  endpoint: '/merge_event',
  fetchInitialData: false,
  objectType: 'merge_event',
  url: '/merge-event',
  getUrl: data => '/merge-event',
  title: 'merge-event',
  customControls: MergeControls,
  tabs: [
    {
      title: 'empty',
      type: 'basis',
      children: [
        {
          title: 'merge.keep',
          icon: 'icons/event-icon.svg',
          components: [
            {name: 'Select', relationship: 'keep', endpointParams: {'search[fields]': 'name'}, placeholder: 'event.placeholder', kind: 'event', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'merge.delete',
          icon: 'icons/event-icon.svg',
          components: [
            {name: 'Select', relationship: 'delete', endpointParams: {'search[fields]': 'name'}, placeholder: 'event.placeholder', kind: 'event', style: {width: '100%'}}
          ],
          side: 'right'
        }
      ]
    }
  ]
}
