export default {
  componentType: 'card',
  endpoint: '/document_draft(/:guid)',
  objectName: 'issue',
  internalObjectName: 'issueDraft', // for internal entity extensions
  createFromCard: true,
  objectType: 'document',
  url: '/issues-draft/:guid',
  getUrl: data => '/issues-draft/' + data.id,
  title: 'issuesDraftCard',
  titleCreate: 'issuesDraftCard.create',
  getValue: data => data.name,
  tabs: [
    {
      title: 'tab.basis',
      type: 'basis',
      children: [
        {
          title: 'issuesDraftCard.issue',
          icon: 'icons/issue.svg',
          required: true,
          components: [
            {name: 'Select', relationship: 'periodical', kind: 'document', endpointParams: {'filter[type]': 'periodical', 'search[fields]': 'name'}, label: 'issues.periodical', style: {width: '100%'}},
            {name: 'input', fieldName: 'numberStr', label: 'number', placeholder: 'number', style: {width: '30%'}},
            {name: 'input', fieldName: 'globalNumber', label: 'global-number', placeholder: 'global-number.placeholder', style: {width: 'calc(35% - 20px)', marginLeft: '20px'}},
            {name: 'input', fieldName: 'volumeStr', label: 'volume', placeholder: 'volume.placeholder', style: {width: 'calc(35% - 20px)', marginLeft: '20px'}}
          ],
          side: 'left'
        },
        {
          title: 'issues.dates',
          icon: 'icons/calendar-icon.svg',
          required: true,
          components: [
            {name: 'FullDate', label: 'issues.dateFrom', fieldName: 'dateFrom', style: {width: '100%'}},
            {name: 'FullDate', label: 'issues.dateTo', fieldName: 'dateTo', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'issues.storage-details',
          icon: 'icons/storage.svg',
          required: true,
          components: [
            {name: 'AutoComplete', fieldName: 'source', label:'source.placeholder', endpoint: '/field_source', endpointParams: {'filter[type]': 'book'}, placeholder: 'source.placeholder', style: {width: '100%'}},
            {name: 'Select', relationship: 'storageLocation', kind: 'storage_location', allowClear: true, endpointParams: {'filter[group]': 'raan'}, label: 'storage-location', style: {width: '100%'}}
          ],
          side: 'right'
        },
        {
          title: 'collection',
          icon: 'icons/documents.svg',
          required: true,
          components: [
            {name: 'Select', relationship: 'collection', kind: 'collection', label: 'collection', style: {width: '100%'}}
          ],
          side: 'right'
        },
        {
          title: 'accessType',
          icon: 'icons/shelf.svg',
          required: true,
          components: [
            {name: 'Select', relationship: 'accessType', kind: 'access_type', allowClear: true, label: 'accessType', style: {width: '100%'}}
          ],
          side: 'right'
        },
        {
          title: 'inv',
          icon: 'icons/storage.svg',
          components: [
            {name: 'input', fieldName: 'inv', disabled: true, style: {width: '100%'}}
          ],
          side: 'right'
        }
      ]
    }
  ]
}
