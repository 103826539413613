import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import { MdErrorOutline, MdCheckCircle } from 'react-icons/lib/md'
import Popover from 'antd/lib/popover'
import Tooltip from 'antd/lib/tooltip'

class ValidationWarning extends PureComponent {

  render() {
    const { validationWarnings } = this.props
    const className = this.props.className ? this.props.className : ''

    if (validationWarnings && validationWarnings.length > 0) {
      return (
        <Popover content={
          <ul>
            {validationWarnings.map(item => <li key={item.id}>- {item.message}</li>)}
          </ul>
        }>
          <MdErrorOutline className={'ValidationWarning ' + className} />
        </Popover>
      )
    } else if (validationWarnings && validationWarnings.length === 0) {
      return (
        <Tooltip 
          placement="top" 
          title={this.props.intl.formatMessage({id: 'validation-warning.ok'})}
        >
          <MdCheckCircle className={'ValidationWarning ValidationWarning__ok ' + className} />
        </Tooltip>
      )
    } else {
      return null
    }
  }

}

export default injectIntl(ValidationWarning)