import merge from 'lodash/merge'
import { API_DATA_REQUEST, API_DATA_SUCCESS, API_DATA_FAILURE } from '../middleware/api'
import formatParams from '../../utils/formatParams'
import normalize from 'json-api-normalizer'
import pointer from 'json-pointer'

const initialState = {
  meta: {}
}

export default function (state = initialState, action) {
  const now = Date.now()
  switch (action.type) {
    case 'DATA_UPDATE_STORE':
      let changes = {}
      pointer.set(changes, action.path, action.value)
      return merge({}, state, changes)

    case API_DATA_SUCCESS:
      const normalizedResponse = normalize(action.response, {
        endpoint: action.endpoint + formatParams(action.endpointParams),
        filterEndpoint: false
      })

      if (typeof action.callback === 'function') {
        setTimeout(() => {
          action.callback(null, action.response, normalizedResponse)
        }, 1)
      }

      return merge(
        {},
        state,
        normalizedResponse,
        {
          meta: {
            [action.endpoint]: {
              [formatParams(action.endpointParams)]: {loading: false, error: null, updatedAt: now}
            }
          }
        }
      )
    case API_DATA_FAILURE:
      if (typeof action.callback === 'function') {
        setTimeout(() => {
          action.callback(action.error)
        }, 1)
      }
      return merge(
        {},
        state,
        {
          meta: {
            [action.endpoint]: {
              [formatParams(action.endpointParams)]: {loading: false, error: action.error, updatedAt: now}
            }
          }
        }
      )
    case API_DATA_REQUEST:
      return merge(
        {},
        state,
        {
          meta: {
            [action.endpoint]: {
              [formatParams(action.endpointParams)]: {loading: true, error: null, requestedAt: now, updatedAt: now}
            }
          }
        }
      )
    case 'REFRESH_PAGE':
      return initialState
    default:
      return state
  }
}
