import React, {PureComponent} from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { create } from '../redux/actions/apiActions'
import Button from './Button'
import Input from '../fieldsStyled/Input'
import { injectIntl } from 'react-intl'

class LoginWithCode extends PureComponent {

  constructor () {
    super()
    this.state = {}
    this.start = this.start.bind(this)
    this.cancel = this.cancel.bind(this)
    this.submitEmail = this.submitEmail.bind(this)
    this.submitCode = this.submitCode.bind(this)
    this.storeEmail = this.storeEmail.bind(this)
    this.storeCode = this.storeCode.bind(this)
    this.checkError = this.checkError.bind(this)
    this.setError = this.setError.bind(this)
  }

  render () {
    if (this.state.mode == null) {
      return <div className="LoginWithCode">
        <Button onClick={this.start}>
          <FormattedMessage id="login.with-code" />
        </Button>
      </div>
    }

    const cancelCross = <span
      onClick={this.cancel}
      className="LoginWithCode__cancel"
    />

    const submitText = this.props.intl.formatMessage({id: 'login.submit'})

    if (this.state.mode === 'email') {
      return <div className="LoginWithCode">
        {cancelCross}
        <FormattedMessage id="login.enter-email" />
        <form onSubmit={this.submitEmail}>
          <Input autoFocus key="email" onChange={this.storeEmail} />
          <input className="Button" type="submit" value={submitText} />
        </form>
        <span className="LoginWithCode__error">{this.state.error}</span>
      </div>
    }

    if (this.state.mode === 'code') {
      return <div className="LoginWithCode">
        {cancelCross}
        <FormattedMessage id="login.enter-code" values={{email: this.state.email}} />
        <form onSubmit={this.submitCode}>
          <Input autoFocus key="code" onChange={this.storeCode} />
          <input className="Button" type="submit" value={submitText} />
        </form>
        <span className="LoginWithCode__error">{this.state.error}</span>
      </div>
    }
  }

  start () {
    if (this.props.onClick) this.props.onClick(true)
    this.setState({mode: 'email'})
  }

  cancel () {
    this.setState({mode: null})
    if (this.props.onClick) this.props.onClick(false)
  }

  async submitEmail (e) {
    e.preventDefault()
    if (this.state.email == null) return
    const data = {
      provider: 'password',
      email: this.state.email
    }
    const resp = await this.props.dispatch(create('/pre-signin', data))
    if (!resp || resp.error) {
      return this.checkError(resp)
    }
    this.setState({mode: 'code'})
  }

  async submitCode (e) {
    e.preventDefault()
    if (this.state.code == null) return
    const data = {
      provider: 'password',
      email: this.state.email,
      password: this.state.code
    }
    const resp = await this.props.dispatch(create('/signin', data))
    if (!resp || resp.error) {
      return this.checkError(resp)
    }
    this.setState({email: null, code: null, mode: null})
    this.cancel()
  }

  storeEmail (e) {
    this.setState({email: e.target.value})
  }

  storeCode (e) {
    this.setState({code: e.target.value})
  }

  checkError (resp) {
    console.error('setError', resp)
    if (
      !resp ||
      !resp.error ||
      !resp.error.errors ||
      !resp.error.errors[0] ||
      !resp.error.errors[0].detail
    ) {
      return this.setError('no error')
    }
    this.setError(resp.error.errors[0].detail)
  }

  setError (errorText) {
    if (this.timeout) clearTimeout(this.timeout)
    this.setState({error: errorText})
    this.timeout = setTimeout(() => {
      this.setState({error: null})
    }, 3000)
  }

}


function mapStateToProps (state, props) {
  return {}
}

export default connect(mapStateToProps)(injectIntl(LoginWithCode))
