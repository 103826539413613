import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { toggleSearch, setSearchTerm } from '../redux/actions/searchActions'
import { navigateTab } from '../redux/actions/manipulateTabs'
import Editable from '../components/Editable'
import SearchResults from '../components/SearchResults'

class SearchBar extends PureComponent {

  render () {
    if (this.props.isShown !== true) return null
    return <div className="SearchBar">
      <div className="SearchBar__pane">
        <div className="SearchBar__pane-close" onClick={this.hideSeach.bind(this)}>✕</div>
        <div className="SearchBar__pane-input">
          <form onSubmit={this.openFullSearch.bind(this)}>
            <Editable type="input" value={this.props.term} onChange={this.onType.bind(this)} autoFocus />
          </form>
        </div>
        <div className="SearchBar__pane-content">
          <SearchResults term={this.props.term} />
        </div>
      </div>
      <div className="SearchBar__rest" onClick={this.hideSeach.bind(this)} />
    </div>
  }

  hideSeach () {
    this.props.dispatch(toggleSearch(false))
  }

  onType (term) {
    term = term.replace('%', '')
    this.props.dispatch(setSearchTerm(term))
  }

  openFullSearch (e) {
    e.preventDefault()
    const path = '/search/' + (this.props.term || '')
    this.props.dispatch(navigateTab(path, true))
    this.props.history.push(path)
  }

}

function mapStateToProps (state, props) {
  if (props.tabId == null) return {}
  let tab = state.tabs.filter(tab => tab.id === props.tabId)[0] || {}
  return {isShown: tab.searchShown, term: tab.searchTerm}
}

export default connect(mapStateToProps)(withRouter(SearchBar))
