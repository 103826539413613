import React, { PureComponent } from 'react'
import Route from 'route-parser'
import { injectIntl } from 'react-intl'
import { storeFieldData, editField } from '../redux/actions/editingCard'
import { update } from '../redux/actions/apiActions'
import jsonPointer from 'json-pointer'
import addData from '../utils/addData'
import { subscribe } from '../utils/pubSub'
import Input from '../fieldsStyled/Input'
import Label from '../components/Label'
import Notification from '../components/Notification'

class FullDate extends PureComponent {

  constructor () {
    super()
    this.getInitialValue = this.getInitialValue.bind(this)
    this.getEditedValue = this.getEditedValue.bind(this)
    this.getDay = this.getDay.bind(this)
    this.getMonth = this.getMonth.bind(this)
    this.getYear = this.getYear.bind(this)
    this.setValue = this.setValue.bind(this)
    this.cancelChanges = this.cancelChanges.bind(this)
    this.saveChanges = this.saveChanges.bind(this)
    this.getFullValue = this.getFullValue.bind(this)
  }

  componentWillMount () {
    const { tab, fieldName } = this.props
    subscribe(tab.id, '/' + fieldName, (type) => {
      if (type === 'cancel') return this.cancelChanges()
      if (type === 'save') return this.saveChanges()
    })
  }

  render () {
    const { style, tab, label, intl } = this.props
    if (!tab || !tab.currentData) return null

    return <div className="FullDate" style={style}>
      <div className="FullDate__day">
        {label && <Label>{intl.formatMessage({id: label})}</Label>}
        {!label && <Label>{intl.formatMessage({id: 'day'})}</Label>}
        <Input placeholder={intl.formatMessage({id: 'day'})} value={this.getDay()} onChange={e => this.setValue(e, 'day')} />
      </div>
      <div className="FullDate__month">
        {!label ? <Label>{intl.formatMessage({id: 'month'})}</Label> : <Label>&nbsp;</Label>}
        <Input placeholder={intl.formatMessage({id: 'month'})} value={this.getMonth()} onChange={e => this.setValue(e, 'month')} />
      </div>
      <div className="FullDate__year">
        {!label ? <Label>{intl.formatMessage({id: 'year'})}</Label> : <Label>&nbsp;</Label>}
        <Input placeholder={intl.formatMessage({id: 'year'})} value={this.getYear()} onChange={e => this.setValue(e, 'year')} />
      </div>
    </div>
  }

  getInitialValue () {
    const { tab, config, data, fieldName } = this.props
    const objectPointer = '/' + config.objectName + '/' + data.id + '/attributes/' + fieldName
    if (!jsonPointer.has(tab.currentData, objectPointer)) return ''
    return jsonPointer.get(tab.currentData, objectPointer) || ''
  }

  getEditedValue (field) {
    const { tab, fieldName } = this.props
    if (!tab.changes || !jsonPointer.has(tab.changes, '/' + fieldName + '/' + field)) return null
    return jsonPointer.get(tab.changes, '/' + fieldName + '/' + field)
  }

  getDay () {
    if (this.getEditedValue('day') !== null) return this.getEditedValue('day')
    const initialValue = this.getInitialValue()
    if (initialValue.length === 10) return initialValue.split('-')[2]
    return ''
  }

  getMonth () {
    if (this.getEditedValue('month') !== null) return this.getEditedValue('month')
    const initialValue = this.getInitialValue()
    if (initialValue.length === 7) return initialValue.split('-')[1]
    if (initialValue.length === 10) return initialValue.split('-')[1]
    return ''
  }

  getYear () {
    if (this.getEditedValue('year') !== null) return this.getEditedValue('year')
    const initialValue = this.getInitialValue()
    if (initialValue.length === 4) return initialValue
    if (initialValue.length === 7) return initialValue.split('-')[0]
    if (initialValue.length === 10) return initialValue.split('-')[0]
    return ''
  }

  getFullValue(field, newValue) {
    let fullNewValue
    
    if (field === 'month' && newValue && newValue > 12) newValue = 12
    if (field === 'day' && newValue && newValue > 31) newValue = 31

    const day = field === 'day' ? newValue : this.getDay()
    const month = field === 'month' ? newValue : this.getMonth()
    const year = field === 'year' ? newValue : this.getYear()
    if (day && month && year) {
      fullNewValue = year + '-' + month + '-' + day
    } else if (month && year) {
      fullNewValue = year + '-' + month
    } else if (year) {
      fullNewValue = year
    }

    return fullNewValue ? fullNewValue : ''
  }

  setValue (event, field) {
    const { tab, fieldName, dispatch, data, config } = this.props
    const pointer = '/' + config.objectName + '/' + data.id + '/attributes/' + fieldName
    let newValue = event.currentTarget.value
    
    if (!newValue || typeof newValue !== 'string') {
      if (!data.id || data.id === 'undefined') {
        // for creating card
        dispatch(editField(tab.id, pointer, this.getFullValue(field, newValue)))
      }
      return dispatch(storeFieldData(tab.id, '/' + fieldName + '/' + field, ''))
    }
    
    newValue = newValue.toString().replace(/\D/g, '')

    if (field === 'month' && newValue && newValue > 12) newValue = 12
    if (field === 'day' && newValue && newValue > 31) newValue = 31
    
    if (!data.id || data.id === 'undefined') {
      // for creating card
      dispatch(editField(tab.id, pointer, this.getFullValue(field, newValue)))
    }
    dispatch(storeFieldData(tab.id, '/' + fieldName + '/' + field, newValue))
  }

  cancelChanges (cb) {
    const { tab, fieldName, dispatch, data, config } = this.props
    const pointer = '/' + config.objectName + '/' + data.id + '/attributes/' + fieldName
    
    if (!data.id || data.id === 'undefined') {
      // for creating card
      dispatch(editField(tab.id, pointer, null))
    }
    dispatch(storeFieldData(tab.id, '/' + fieldName, null))
    if (typeof cb === 'function') cb()
  }

  async saveChanges () {
    const { data, fieldName, config, dispatch } = this.props
    // const initialValue = this.getInitialValue()
    if (
      this.getEditedValue('day') == null && 
      this.getEditedValue('month') == null && 
      this.getEditedValue('year') == null
    ) {
      return
    }
    const day = this.getDay()
    const month = this.getMonth()
    const year = this.getYear()
    let newValue
    if (day && month && year) {
      newValue = year + '-' + month + '-' + day
    } else if (month && year) {
      newValue = year + '-' + month
    } else if (year) {
      newValue = year
    }
    const route = new Route(config.endpoint)
    const endpoint = route.reverse({guid: data.id})
    await dispatch(update(endpoint, {data: {attributes: {[fieldName]: newValue || ''}}}, (error, data, normalised) => {
      if (error) {
        const message = this.props.intl.formatMessage({id: 'notification.card.update.error'})
        console.log(message)
        const errorText = error.errors && error.errors[0] ? error.errors[0].detail : JSON.stringify(error)
        return Notification.error(message + ': ' + errorText, {duration: null})
      }
    }))
    await dispatch(update(endpoint, {data: {attributes: {[fieldName]: newValue || ''}}}))
  }

}

export default addData(injectIntl(FullDate))
