import React, { PureComponent } from 'react'
import uuid from 'uuid'
import jsonPointer from 'json-pointer'
import { differenceBy } from 'lodash'
import { subscribe } from '../utils/pubSub'
import Editable from '../components/Editable'
import { create, remove } from '../redux/actions/apiActions'
import { storeFieldData } from '../redux/actions/editingCard'
import addData from '../utils/addData'

const Name = ({value, onChange, isDeletable, onDelete}) => {
  return <div className="GroupAlternativeTitle__each">
    <Editable
      type="input"
      value={value}
      // label="groups.nameAlternative"
      placeholder="groups.nameAlternative.placeholder"
      onChange={onChange}
    />
    {isDeletable
      ? <div className="GroupAlternativeTitle__delete" onClick={onDelete} />
      : null
    }
  </div>
}

class GroupAlternativeTitle extends PureComponent {

  componentWillMount () {
    subscribe(this.props.tab.id, this.props.pointer, (msg, done) => {
      if (msg === 'cancel') return this.cancelChanges.apply(this)
      if (msg === 'save') return this.saveChanges.apply(this)
    })
  }

  render () {
    let value = this.getValue.apply(this)
    if (value == null || value.length < 1) {
      value = [{id: uuid()}]
    } else {
      const lastValue = value[value.length - 1].name
      if (lastValue != null) {
        value.push({id: uuid()})
      }
    }
    return <div className="GroupAlternativeTitle">
      {value.map((name, index) => {
        return <Name
          key={name.id}
          value={name.name}
          onChange={this.editField.bind(this, name.id)}
          isDeletable={index + 1 !== value.length}
          onDelete={this.deleteField.bind(this, name.id)}
        />
      })}
    </div>
  }

  getInitialValue () {
    const { data, pointer } = this.props
    if (!data || !jsonPointer.has(data, pointer)) return null
    return jsonPointer.get(data, pointer)
  }

  getEditedValue () {
    const { tab, pointer } = this.props
    if (!tab.changes || !jsonPointer.has(tab.changes, pointer)) return null
    return jsonPointer.get(tab.changes, pointer)
  }

  getValue () {
    return this.getEditedValue.apply(this) || this.getInitialValue.apply(this)
  }

  deleteField (id) {
    let currentValue = this.getValue.apply(this)
    if (currentValue == null) return
    currentValue = currentValue.filter(each => each.id !== id)
    const { tab, pointer } = this.props
    this.props.dispatch(storeFieldData(tab.id, pointer, currentValue))
  }

  editField (id, newValue) {
    let currentValue = this.getValue.apply(this)
    if (currentValue == null) {
      currentValue = [{id, name: newValue}]
    } else {
      let isEditedValueInArray = false
      for (let i in currentValue) {
        if (currentValue[i].id !== id) continue
        currentValue[i].name = newValue
        isEditedValueInArray = true
      }
      if (isEditedValueInArray === false) {
        currentValue.push({id, name: newValue})
      }
    }
    const { tab, pointer } = this.props
    this.props.dispatch(storeFieldData(tab.id, pointer, currentValue))
  }

  cancelChanges () {
    const { tab, pointer } = this.props
    this.props.dispatch(storeFieldData(tab.id, pointer, null))
  }

  saveChanges () {
    const initialValue = this.getInitialValue.apply(this)
    const editedValue = this.getEditedValue.apply(this)
    if (editedValue == null) return
    const toDelete = differenceBy(initialValue, editedValue, 'id')
    const toAdd = differenceBy(editedValue, initialValue, 'name')
    // console.log('toDelete, toAdd', toDelete, toAdd)
    const convert = function (array) {
      return array.map(each => ({
        id: each.id,
        attributes: {name: each.name},
        type: 'alternate_name'
      }))
    }
    if (toAdd.length > 0) {
      const data = convert(toAdd)
      this.props.dispatch(create('/person/' + this.props.data.id + '/relationships/alternate_names', {data}))
    }
    if (toDelete.length > 0) {
      const data = convert(toDelete)
      this.props.dispatch(remove('/person/' + this.props.data.id + '/relationships/alternate_names', {data}))
    }
  }

}

export default addData(GroupAlternativeTitle)
