import React from 'react'
import formatDate from './formatDate'

const generateTooltip = (option, getOptionTooltip = null) => {
  if (typeof getOptionTooltip === 'function') {
    return getOptionTooltip(option)
  }
  let ret = []
  const add = (value) => {
    if (value) ret.push(value)
    if (ret.length <= 0) return null
    return ret.map((e, i) => (i === ret.length - 1) ? e : <span key={e}>{e}, </span>)
  }
  if (typeof option.kind === 'object' && option.kind && typeof option.kind.name === 'string') add(option.kind.name)
  if (typeof option.place === 'object' && option.place && typeof option.place.name === 'string') add(option.place.name)
  if (typeof option.institution === 'object' && option.institution && typeof option.institution.name === 'string') add(option.institution.name)
  if (typeof option.dateFrom === 'string') add(formatDate(option.dateFrom, {shifted: false}))
  //if (typeof option.midname === 'string') add(option.midname)
  if (typeof option.dateOfBirth === 'string') add(formatDate(option.dateOfBirth, {shifted: false}))
  if (typeof option.slug === 'string' && option.type && option.type !== 'collection') add(option.slug)
  if (typeof option.fullCode === 'string') return add(option.fullCode)
  if (typeof option.code === 'string') return add(option.code)
  if (typeof option.inv === 'string') add(option.inv)
  return add()
}

export default generateTooltip