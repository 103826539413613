import build from 'redux-object'

export default function getCardData (state, props) {
  const tab = state.tabs.filter(tab => tab.id === props.applicationTabId)[0]
  
  if (
    !tab || 
    !tab.currentData || 
    !tab.currentData[props.config.objectName]
  ) {
    return {tab, data: {}, initialData: {}}
  }
  
  const objectId = Object.keys(tab.currentData[props.config.objectName])[0]

  if (objectId == null) {
    return {tab, data: {}, initialData: {}}
  }

  let initialData = {}
  if (tab.initialData && tab.initialData[props.config.objectName]) {
    try {
      initialData = build(tab.initialData, props.config.objectName, objectId, {includeType: true})
    } catch (e) {}
  }

  try {
    const data = build(tab.currentData, props.config.objectName, objectId, {includeType: true})
    return {tab, data, initialData}
  } catch (e) {
    return {tab, data: {}, initialData}
  }
}
