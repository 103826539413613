import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { setSearchTerm } from '../redux/actions/searchActions'
import Editable from '../components/Editable'
import SearchResultsFull from '../components/SearchResultsFull'
import SearchTabs from '../components/SearchTabs'
import getPropsFromUrl from '../utils/getPropsFromUrl'
import { navigateTab } from '../redux/actions/manipulateTabs'
import formatParams from '../utils/formatParams'
import CreateCard from '../components/CreateCard'
import FiltersForm from '../components/FiltersForm'
import ColumnsSettings from '../components/ColumnsSettings'
import Button from '../components/Button'
import getSearchTermFromUrl from '../utils/getSearchTermFromUrl'
import debounce from 'lodash/debounce'
import { FormattedMessage } from 'react-intl'
import ExportIcon from 'react-icons/lib/fa/table'
import generateSearchParams from '../utils/generateSearchParams'
import { create } from '../redux/actions/apiActions'
import Notification from '../components/Notification'
import { injectIntl } from 'react-intl'
import mapSeachResultsFull from '../redux/mapers/mapSeachResultsFull'
import Popconfirm from 'antd/lib/popconfirm'

import getCardConfigByType from '../utils/getCardConfigByType'
import getTableConfigByType from '../utils/getTableConfigByType'
import getSearchTabs from '../utils/getSearchTabs'
import camelCase from 'camel-case'

class SearchLayout extends Component {

  constructor (props) {
    super(props)
    this.state = {term: getSearchTermFromUrl(this.props.tab.path)}
    this.setSearchDebounced = debounce(this.setSearch.bind(this), 500)
    this.resetFilters = this.resetFilters.bind(this)
    this.onFilter = this.onFilter.bind(this)
  }

  componentWillMount () {
    const newTerm = this.props.match.term || ''
    this.props.dispatch(setSearchTerm(newTerm))
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.match.term === this.props.match.term) return
    this.props.dispatch(setSearchTerm(nextProps.match.term))
  }

  render () {
    const { intl } = this.props
    const filter = getPropsFromUrl(this.props.tab.path).filter
    const term = getSearchTermFromUrl(this.props.tab.path)
    let sort = getPropsFromUrl(this.props.tab.path).sort
    if (sort == null && this.state.term == null) {
      const tableDefaultSort = filter ? getTableConfigByType(camelCase(filter)).defaultSorting : null
      if (tableDefaultSort) {
        sort = tableDefaultSort
      } else {
        sort = this.props.config.defaultSorting
      }
    }
    let otherParams = getPropsFromUrl(this.props.tab.path)
    if (otherParams.filter) delete otherParams.filter
    if (otherParams.sort) delete otherParams.sort
    const isActiveFilters = otherParams && Object.keys(otherParams).length > 0

    const meta = mapSeachResultsFull(
      this.props.state, 
      {term, filter, sort, filters: otherParams}
    ).meta
    const tabs = getSearchTabs(meta, filter)
    const activeTab = filter || 'basis'
    const currentTab = tabs.filter(item => item.type === activeTab)[0]

    return <div className="SearchLayout">
      <div className="SearchLayout__topPanel">
        <div className="SearchLayout__input">
          <form onSubmit={this.submitSearch.bind(this)}>
            <Editable type="input" value={this.state.term} onChange={this.onType.bind(this)} autoFocus />
          </form>
          <div 
            className="SearchLayout__reset-filters" 
            onClick={this.resetFilters}
            style={{ display: this.state.term || isActiveFilters ? 'block' : 'none' }}
          >✕</div>
        </div>
        <div className="SearchLayout__filters-icon-inner">
          <FiltersForm 
            isActiveFilters={isActiveFilters}
            filters={otherParams}
            onFilter={this.onFilter}
            filter={filter}
          />
        </div>
        <div className="SearchLayout__settings-icon-inner">
          <ColumnsSettings filter={filter} />
        </div>
        <div className="SearchLayout__export">
          <Popconfirm
            placement="top"
            arrowPointAtCenter
            title={
              (
                currentTab.amount ? 
                  currentTab.amount + ' ' + intl.formatMessage({id: 'export.confirm-1_1'}) : 
                  intl.formatMessage({id: 'export.confirm-1_2'})
              ) + ' ' +
              intl.formatMessage({id: currentTab.title}) + ' ' +
              intl.formatMessage({id: 'export.confirm-2'})
            }
            onConfirm={this.exportData.bind(this)}
            okText={intl.formatMessage({id: 'notification.yes'})}
            cancelText={intl.formatMessage({id: 'notification.no'})}
          >
            <Button type="dashed">
              <ExportIcon /> <FormattedMessage id="export" />
            </Button>
          </Popconfirm>
        </div>
        <CreateCard 
          type={filter ? getCardConfigByType(filter).objectName : null} 
          tabId={this.props.tab.tabId} 
          internalType={filter}
        />
      </div>
      <div className="SearchLayout__tabs">
        <SearchTabs term={term} filter={filter} sort={sort} filters={otherParams} onClick={this.selectFilter.bind(this)} />
      </div>
      <div className="SearchLayout__content">
        <SearchResultsFull 
          term={term} 
          filter={filter} 
          filters={otherParams} 
          sort={sort} 
          onSort={this.onSort.bind(this)} 
        />
      </div>
    </div>
  }

  exportData () {
    const urlParams = getPropsFromUrl(this.props.tab.path)
    const term = getSearchTermFromUrl(this.props.tab.path)
    let exportParams = generateSearchParams(term, urlParams)
    exportParams["page[size]"] = 'all'
    const exportParamsStr = formatParams(exportParams).substring(1)

    this.props.dispatch(create(
      '/export', 
      {
        data: {
          type: 'export',
          attributes: {
            target: 'google_sheet',
            search_params: exportParamsStr
          }
        }
      },
      (error) => {
        if (error.errors) {
          const errorText = error.errors && error.errors[0] ? error.errors[0].detail : JSON.stringify(error)
          return Notification.error(errorText)
        }
        return Notification.success(this.props.intl.formatMessage({
          id: 'export.success'
        }))
      }
    ))
  }

  resetFilters () {
    const indexOfQuery = this.props.tab.path.indexOf('?')
    let cleanPath = (indexOfQuery >= 0) ? this.props.tab.path.substring(0, indexOfQuery) : this.props.tab.path
    cleanPath = cleanPath.replace(this.state.term, '')
    this.setState({term: ''})
    const urlParams = getPropsFromUrl(this.props.tab.path)

    const path = cleanPath + formatParams({
      filter: urlParams.filter, 
      sort: urlParams.sort ? urlParams.sort : this.props.config.defaultSorting
    })
    this.props.dispatch(navigateTab(path))
    this.props.history.replace(path)
  }

  onFilter (params) {
    const indexOfQuery = this.props.tab.path.indexOf('?')
    const cleanPath = (indexOfQuery >= 0) ? this.props.tab.path.substring(0, indexOfQuery) : this.props.tab.path
    const urlParams = getPropsFromUrl(this.props.tab.path)
    const path = cleanPath + formatParams({...urlParams, ...params})
    this.props.dispatch(navigateTab(path))
    this.props.history.replace(path)
  }

  onType (term) {
    this.setState({'term': term})
    this.setSearchDebounced(term)
  }

  setSearch (term) {
    if (term == null) return
    term = term.replace('%', '')
    const indexOfQuery = this.props.tab.path.indexOf('?')
    const query = (indexOfQuery >= 0) ? this.props.tab.path.substring(indexOfQuery) : ''
    const path = '/search/' + term + query
    this.props.dispatch(navigateTab(path))
    this.props.history.replace(path)
  }

  submitSearch (e) {
    e.preventDefault()
  }

  selectFilter (type) {
    const indexOfQuery = this.props.tab.path.indexOf('?')
    const cleanPath = (indexOfQuery >= 0) ? this.props.tab.path.substring(0, indexOfQuery) : this.props.tab.path
    const path = (type === 'basis')
      ? cleanPath
      : cleanPath + '?filter=' + type
    this.props.dispatch(navigateTab(path))
    this.props.history.replace(path)
  }

  onSort (sort) {
    const indexOfQuery = this.props.tab.path.indexOf('?')
    const cleanPath = (indexOfQuery >= 0) ? this.props.tab.path.substring(0, indexOfQuery) : this.props.tab.path
    const urlParams = getPropsFromUrl(this.props.tab.path)
    const path = cleanPath + formatParams({...urlParams, sort})
    this.props.dispatch(navigateTab(path))
    this.props.history.replace(path)
  }

}

function mapStateToProps (state) {
  return {
    state
  }
}

export default connect(mapStateToProps)(withRouter(injectIntl(SearchLayout)))
