import formatDate from '../utils/formatDate'

export default {
  componentType: 'search',
  objectName: 'bookDraft',
  sortLevel: 22,
  defaultSorting: '-draft,-created_at',
  url: '/search/?filter=book_draft',
  title: 'books-draft',
  getUrl: data => '/books-draft/' + data.id,
  table: [
    {
      title: 'books.inv',
      width: 100,
      sortBy: 'inv_int',
      getValue: data => data.inv
    },
    {
      title: 'books.title',
      width: 400,
      fixed: true,
      sortBy: 'name',
      getValue: data => data.name
    },
    {
      title: 'books.publisher',
      width: 150,
      sortBy: 'publishers.name',
      getValue: data => {
        if (!data.publishers || !Array.isArray(data.publishers)) return null
        return data.publishers.map(publisher => publisher.name).join(', ')
      }
    },
    {
      title: 'books.year',
      width: 60,
      sortBy: 'date_from',
      filterBy: 'date_from?filter[have_public_records]=book',
      getValue: data => data.dateFrom
    },
    {
      title: 'books.isbn',
      width: 150,
      sortBy: 'isbn',
      getValue: data => data.isbn
    },
    {
      title: 'books.createdAt',
      width: 120,
      sortBy: 'created_at',
      getValue: data => formatDate(data.createdAt)
    },
    {
      title: 'individuals.table.createdBy',
      width: 165,
      sortBy: 'created_by',
      filterBy: 'user?filter[created_type]=book_draft',
      filterMode: 'multiple',
      getValue: data => data.createdBy
    }
  ]
}
