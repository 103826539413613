import config from '../config'

const getTableConfig = function (type) {
  let tableConfig = {}
  for (let key in config.pages) {
    if (
      config.pages[key].componentType !== 'table' &&
      config.pages[key].componentType !== 'search'
    ) continue
    if (config.pages[key].objectName !== type) continue
    tableConfig = config.pages[key]
    break
  }
  return tableConfig
}

export default getTableConfig
