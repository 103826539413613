export default {
  componentType: 'card',
  endpoint: '/document_draft(/:guid)',
  objectName: 'book',
  internalObjectName: 'bookDraft', // for internal entity extensions
  createFromCard: true,
  objectType: 'document',
  url: '/books-draft/:guid',
  getUrl: data => '/books-draft/' + data.id,
  title: 'booksDraftCard',
  titleCreate: 'booksDraftCard.create',
  getValue: data => data.name,
  tabs: [
    {
      title: 'tab.basis',
      type: 'basis',
      children: [
        // left
        {
          title: 'booksDraftCard.book',
          icon: 'icons/book.svg',
          required: true,
          components: [
            {name: 'nobr', fieldName: 'name', label: 'events.table.title', placeholder: 'events.table.title', style: {width: 'calc(70% - 20px)'}},
            {name: 'input', fieldName: 'isbn', label: 'books.isbn', placeholder: 'books.isbn.placeholder', style: {width: '30%', marginLeft: '20px'}}
          ],
          side: 'left'
        },
        /*
        {
          title: 'books.publishers',
          icon: 'icons/organisation.svg',
          components: [
            {name: 'Relation', pointer: '/relDocumentOrganisation', components: [
              {name: 'Select', relationship: 'organisation', placeholder: 'books.organisations.placeholder'},
              {name: 'Select', relationship: 'role', fixed: 'role.book_publisher'}
            ]}
          ],
          side: 'left'
        },
        */
        {
          title: 'books.publishers',
          icon: 'icons/organisation.svg',
          required: true,
          components: [
            {name: 'MultiSelect', relationship: 'organisations', kind: 'organisation', placeholder: 'books.publishers', style: {width: '100%'}}
          ],
          side: 'left'
        },
        // right
        {
          title: 'books.storage-details',
          icon: 'icons/storage.svg',
          required: true,
          components: [
            {name: 'Select', relationship: 'storageLocation', kind: 'storage_location', allowClear: true, endpointParams: {'filter[group]': 'raan'}, label: 'storage-location', style: {width: '100%'}},
            {name: 'AutoComplete', fieldName: 'source', label:'source.placeholder', endpoint: '/field_source', endpointParams: {'filter[type]': 'book'}, placeholder: 'source.placeholder', style: {width: 'calc(70% - 20px)'}},
            {name: 'input', fieldName: 'dateFrom', label: 'books.date', style: {width: '30%', marginLeft: '20px'}},
            {name: 'Select', relationship: 'subjectHeading', kind: 'subject_heading', allowClear: true, getOptionTitle: option => option.id + ' ' + option.name, endpointParams: {'view[type]': 'app'}, label: 'books.subject-heading', style: {width: 'calc(70% - 20px)'}},
            {name: 'input', fieldName: 'authorSign', label: 'books.author-sign', style: {width: '30%', marginLeft: '20px'}}
          ],
          side: 'right'
        },
        {
          title: 'collection',
          icon: 'icons/documents.svg',
          required: true,
          components: [
            {name: 'Select', relationship: 'collection', kind: 'collection', label: 'collection', style: {width: '100%'}}
          ],
          side: 'right'
        },
        {
          title: 'accessType',
          icon: 'icons/shelf.svg',
          required: true,
          components: [
            {name: 'Select', relationship: 'accessType', kind: 'access_type', allowClear: true, label: 'accessType', style: {width: '100%'}}
          ],
          side: 'right'
        },
        {
          title: 'books.inv',
          icon: 'icons/storage.svg',
          components: [
            {name: 'input', fieldName: 'inv', disabled: true, style: {width: '100%'}}
          ],
          side: 'right'
        },
        {
          title: 'booksDraftCard.comment',
          icon: 'icons/organisation.svg',
          components: [
            {name: 'text', fieldName: 'draftNotes', placeholder: 'booksDraftCard.comment', style: {width: '100%'}}
          ],
          side: 'right'
        }
      ]
    }
  ]
}
