import React, { PureComponent } from 'react'
import Button from '../components/Button'
import { connect } from 'react-redux'
import Popconfirm from 'antd/lib/popconfirm'
import { injectIntl } from 'react-intl'
import { remove, get, update, create } from '../redux/actions/apiActions'
import Notification from '../components/Notification'
import SelectComponent from '../components/SelectComponent'

class FilesSelectionToolbar extends PureComponent {
  
  constructor(props) {
    super(props)
    this.isFileSelected = this.isFileSelected.bind(this)
    this.isFileDeleted = this.isFileDeleted.bind(this)
  }

  render() {
    const { files, objectName } = this.props
    let atLeastOneSelected = false
    let numSelected = 0
    files.forEach((item, i, arr) => {
      if (this.isFileSelected(item)) {
        atLeastOneSelected = true
        numSelected++
      }
    })
    const mediaItemCard = objectName === "mediaItem"
    const numFiles = (files.filter(item => !this.isFileDeleted(item))).length

    if (numFiles > 0) {
      return (
        <div className="Files__selectionToolbar">
          {!atLeastOneSelected &&
            <div className="Files__selectionRow">
              <Button title="select-all" onClick={this.selectAll.bind(this, true)} />
            </div>
          }
          {atLeastOneSelected &&
            (
              <div className="Files__selectionRow">
                <Button title="remove-selection" onClick={this.selectAll.bind(this, false)} />
                <div className="Files__numSelected">Выделено {numSelected} из {numFiles}</div>
                {!mediaItemCard &&
                  <div className="Files__accessLevelSelect">
                    <SelectComponent
                      endpoint="/file_access_level"
                      placeholder="files.access-level"
                      onChange={this.changeAccessLevel.bind(this)}
                    />
                  </div>
                }
                {mediaItemCard &&
                  <Popconfirm
                    placement="topRight"
                    arrowPointAtCenter
                    title={this.props.intl.formatMessage({ id: 'notification.sure' })}
                    onConfirm={this.requestDownloadColdStorage.bind(this)}
                    okText={this.props.intl.formatMessage({ id: 'notification.yes' })}
                    cancelText={this.props.intl.formatMessage({ id: 'notification.no' })}
                  >
                    <Button
                      title="files.request-download-cold-storage"
                      style={{'margin-right': '15px'}}
                    />
                  </Popconfirm>
                }
                <Popconfirm
                  placement="topRight"
                  arrowPointAtCenter
                  title={this.props.intl.formatMessage({ id: 'notification.sure' })}
                  onConfirm={this.deleteFiles.bind(this)}
                  okText={this.props.intl.formatMessage({ id: 'notification.yes' })}
                  cancelText={this.props.intl.formatMessage({ id: 'notification.no' })}
                >
                  <Button title="delete" />
                </Popconfirm>
              </div>
            )
          }
        </div>
      )
    } else {
      return null
    }
  }

  changeAccessLevel (newvalue) {
    const { dispatch, files } = this.props
    let data = []
    let filesIds = []
    files.forEach((item, i, arr) => {
      if (this.isFileSelected(item)) {
        data.push({
          type: 'file',
          id: item.id,
          relationships: {
            file_access_level: {
              data: {id: newvalue.id}
            }
          }
        })
        filesIds.push(item.id)
      }
    })

    dispatch(update('/file', {
        data
      }, 
      (error, result) => {
        const { dispatch, intl } = this.props
        if (error && JSON.stringify(error) !== '{}') {
          const msg = intl.formatMessage({id: 'notification.server.error'})
          return Notification.error(msg + ': ' + JSON.stringify(error))
        }
        const msg = intl.formatMessage({id: 'notification.card.update.success'})
        filesIds.forEach(fileId => {
          dispatch(get('/file/' + fileId))
        })
        return Notification.success(msg)
      }
    ))
  }

  isFileDeleted(file) {
    const { filesFromData } = this.props
    if (
      file.deleted ||
      (
        filesFromData &&
        filesFromData[file.id] &&
        filesFromData[file.id].attributes.deleted
      )
    ) {
      return true
    } else {
      return false
    }
  }

  isFileSelected(file) {
    const { filesFromData } = this.props
    if (
      (
        file.selected ||
        (
          filesFromData &&
          filesFromData[file.id] &&
          filesFromData[file.id].attributes.selected
        )
      ) &&
      !this.isFileDeleted(file)
    ) {
      return true
    } else {
      return false
    }
  }

  deleteFiles() {
    const { dispatch, intl, objectName, tabId, files } = this.props
    let data = []
    let filesIds = []
    files.forEach((item, i, arr) => {
      if (this.isFileSelected(item)) {
        data.push({
          type: 'file',
          id: item.id
        })
        filesIds.push(item.id)
      }
    })

    dispatch(remove(
      '/file',
      {
        data
      },
      (error, result) => {
        if (error && JSON.stringify(error) !== '{}') {
          const msg = intl.formatMessage({ id: 'notification.server.error' })
          return Notification.error(msg + ': ' + JSON.stringify(error))
        }
        const msg = intl.formatMessage({ id: 'notification.files.deleted' })
        dispatch({ type: 'DELETE_FILES', filesIds, objectName, tabId })
        return Notification.success(msg)
      }
    ))
  }

  requestDownloadColdStorage() {
    console.log('requestDownloadColdStorage')
    const { dispatch, files } = this.props
    let data = []
    let filesIds = []
    files.forEach((item, i, arr) => {
      if (this.isFileSelected(item)) {
        data.push({
          type: 'file',
          id: item.id
        })
        filesIds.push(item.id)
      }
    })

    dispatch(create('/file/restore_request', {
        data
      }, 
      (error, result) => {
        const { dispatch, intl } = this.props
        if (error && JSON.stringify(error) !== '{}') {
          const msg = intl.formatMessage({id: 'notification.server.error'})
          return Notification.error(msg + ': ' + JSON.stringify(error))
        }
        const msg = intl.formatMessage({id: 'notification.card.update.success'})
        filesIds.forEach(fileId => {
          dispatch(get('/file/' + fileId))
        })
        return Notification.success(msg)
      }
    ))
  }

  selectAll(checked) {
    const { objectName, tabId, files } = this.props
    const filesIds = files.map(item => item.id)
    this.props.dispatch({
      type: 'SELECT_ALL_FILES',
      objectName,
      tabId,
      filesIds,
      checked
    })
  }
  
}

const mapStateToProps = (state, props) => {
  return {
    filesFromData: state.data.file
  }
}

export default connect(mapStateToProps)(injectIntl(FilesSelectionToolbar))