import React from 'react'
import { FormattedMessage } from 'react-intl'

import formatDate from '../utils/formatDate'

export default {
  componentType: 'search',
  objectName: 'mediaItem',
  sortLevel: 14,
  defaultSorting: '-created_at',
  url: '/search/?filter=media_item',
  title: 'media-carriers',
  getUrl: data => '/media-items/' + data.id,
  table: [
    {
      title: 'kind',
      width: 200,
      fixed: true,
      sortBy: 'kind.name',
      filterBy: 'kind?filter[id][prefix]=document.media_item',
      filterMode: 'multiple',
      getValue: data => data.kind ? data.kind.name : null
    },
    {
      title: 'title',
      width: 250,
      fixed: true,
      sortBy: 'name',
      getValue: data => data.name
    },
    {
      title: 'code-inv',
      width: 200,
      sortBy: 'code',
      getValue: data => data.code || data.inv
    },
    {
      title: 'collection',
      width: 200,
      sortBy: 'collection.name',
      filterBy: 'collection?page[size]=all&filter[relationships][institution][id]=all&sort=-documents_count_including_unpublished',
      filterMode: 'multiple',
      getValue: data => data.collection ? data.collection.name : null
    },
    {
      title: 'media-items.videos',
      width: 100,
      sortBy: 'videos_count',
      getValue: data => data.videosCount
    },
    {
      title: 'files',
      width: 100,
      sortBy: 'files_count',
      getValue: data => data.filesCount
    },
    {
      title: 'media-items.lto',
      width: 220,
      sortBy: 'lto_status',
      getValue: data => data.ltoStatus ? data.ltoStatus : '-'
    },
    {
      title: 'reviewedByEditor',
      width: 175,
      sortBy: 'reviewed_by_editor',
      filterBy: 'reviewed_by_editor',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: data => data.reviewedByEditor ?
        <FormattedMessage id="yes" /> :
        <FormattedMessage id="no" />
    },
    {
      title: 'needsVerificationByEditor',
      width: 175,
      sortBy: 'needs_verification_by_editor',
      filterBy: 'needs_verification_by_editor',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: data => data.needsVerificationByEditor ?
        <FormattedMessage id="yes" /> :
        <FormattedMessage id="no" />
    },
    {
      title: 'createdAt',
      width: 120,
      sortBy: 'created_at',
      getValue: data => formatDate(data.createdAt)
    },
    {
      title: 'individuals.table.createdBy',
      width: 165,
      sortBy: 'created_by',
      filterBy: 'user?filter[created_type]=media_item',
      filterMode: 'multiple',
      getValue: data => data.createdBy
    }
  ]
}
