export default {
  componentType: 'card',
  endpoint: '/kind(/:guid)',
  objectName: 'kind',
  url: '/kind/:guid',
  getUrl: data => '/kind/' + data.id,
  title: 'kind.editing',
  titleCreate: 'kinds.create',
  getValue: data => data.name,
  helpLink: 'https://app.raan.garagemca.org/docs/docs/howto#kind-editing',
  tabs: [
    {
      title: 'tab.basis',
      type: 'basis',
      children: [
        {
          title: 'kind.editing',
          icon: 'icons/frame.svg',
          required: true,
          components: [
            {name: 'Select', relationship: 'parent', kind: 'kind', label: 'kind.base', style: {width: '100%'}, endpointParams: {'view[type]': 'nested'}},
            {name: 'nobr', fieldName: 'name', label: 'title', placeholder: 'title', style: {width: '100%'}},
            {name: 'nobr', fieldName: 'nameEn', label: 'places.nameEn', placeholder: 'places.nameEn.placeholder', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'kind.web-settings',
          icon: 'icons/comment-icon.svg',
          components: [
            {name: 'Bool', fieldName: 'showOnRaanWebsiteMainFilter', label: 'kind.show-on-raan-website-card', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'internal-id',
          icon: 'icons/storage.svg',
          components: [
            {name: 'input', fieldName: 'id', disabled: true, style: {width: '100%'}}
          ],
          side: 'right'
        }
      ]
    }
  ]
}
