import React, { PureComponent } from 'react'
import Select from '../fieldsStyled/SelectStyled'
import range from 'lodash/range'
import { injectIntl } from 'react-intl'
import Input from '../fieldsStyled/Input'

const Option = Select.Option

class FullDateSelector extends PureComponent {

  constructor() {
    super()
    this.state = {
      ge: {
        day: undefined
      },
      le: {
        day: undefined
      }
    }
    this.setValue = this.setValue.bind(this)
  }

  getDateObjFromStr(str) {
    let dateObj = {
      year: undefined,
      month: undefined,
      day: undefined
    }

    if (str) {
      str += ''
      const dateArr = str.split('-')

      switch (dateArr.length) {
        case 3:
          dateObj = {
            year: parseInt(dateArr[0]),
            month: parseInt(dateArr[1]),
            day: parseInt(dateArr[2])
          }
          break
        case 2:
          dateObj.year = parseInt(dateArr[0])
          dateObj.month = parseInt(dateArr[1])
          break
        case 1:
          dateObj.year = parseInt(str)
          break
        default:
          break
      }
    }

    return dateObj
  }

  getDateStrFromObj(obj) {
    let str = undefined

    if (obj.year) {
      str = obj.year

      if (obj.month) {
        let month = parseInt(obj.month)
        month = month < 10 ? '0' + month : month
        str += '-' + month

        if (obj.day) {
          let day = parseInt(obj.day)
          day = day < 10 ? '0' + day : day
          str += '-' + day
        }
      }
    }

    return str
  }

  setValue(type, field, value) {
    const { minYear, maxYear } = this.props
    const years = {
      ge: minYear == null ? 0 : minYear,
      le: maxYear == null ? (new Date()).getFullYear() : maxYear
    }

    const { minValue, maxValue } = this.props
    let dateObjects = {
      ge: this.getDateObjFromStr(minValue),
      le: this.getDateObjFromStr(maxValue)
    }
    dateObjects[type].day = this.state[type].day ? this.state[type].day : dateObjects[type].day

    value = value.toString().replace(/\D/g, '')

    if (field === 'month' && value && value > 12) value = 12
    if (field === 'day' && value && value > 31) value = 31

    dateObjects[type][field] = value

    if (!dateObjects[type].year) {
      dateObjects[type].year = years[type]
    }

    const dates = {
      ge: this.getDateStrFromObj(dateObjects.ge),
      le: this.getDateStrFromObj(dateObjects.le)
    }

    if (field === 'day') {
      this.setState({
        [type]: {
          day: !dateObjects[type].month ? value : undefined
        }
      })
    }

    if (dates[type]) {
      this.props.onDateChange(dates)
    }
  }

  render() {
    let { minYear, maxYear } = this.props
    const { minValue, maxValue, intl } = this.props

    if (minYear == null) minYear = 0
    if (maxYear == null) maxYear = (new Date()).getFullYear()

    const minDate = this.getDateObjFromStr(minValue)
    const maxDate = this.getDateObjFromStr(maxValue)

    return <div className="FullDateSelector">
      <div className="FullDateSelector__day">
        <Input
          placeholder={intl.formatMessage({ id: 'day' })}
          value={this.state.ge.day || minDate.day || ''}
          onChange={e => this.setValue('ge', 'day', e.currentTarget.value)}
        />
      </div>
      <div className="FullDateSelector__month">
        <Input
          placeholder={intl.formatMessage({ id: 'month' })}
          value={minDate.month || ''}
          onChange={e => this.setValue('ge', 'month', e.currentTarget.value)}
        />
      </div>
      <div>
        <Select
          hideSelectIcon
          style={{ 'width': '100px' }}
          placeholder={intl.formatMessage({ id: 'year' })}
          value={minDate.year || minYear}
          onChange={value => this.setValue('ge', 'year', value)}
        >
          {range(minYear, maxYear + 1).map(year => {
            return <Option key={year} value={year}>{year}</Option>
          })}
        </Select>
      </div>

      <div className="FullDateSelector__separator">
        ~
      </div>

      <div className="FullDateSelector__day">
        <Input
          placeholder={intl.formatMessage({ id: 'day' })}
          value={this.state.le.day || maxDate.day || ''}
          onChange={e => this.setValue('le', 'day', e.currentTarget.value)}
        />
      </div>
      <div className="FullDateSelector__month">
        <Input
          placeholder={intl.formatMessage({ id: 'month' })}
          value={maxDate.month || ''}
          onChange={e => this.setValue('le', 'month', e.currentTarget.value)}
        />
      </div>
      <div>
        <Select
          hideSelectIcon
          style={{ 'width': '100px' }}
          placeholder={intl.formatMessage({ id: 'year' })}
          value={maxDate.year || maxYear}
          onChange={value => this.setValue('le', 'year', value)}
        >
          {range(minYear, maxYear + 1).map(year => {
            return <Option key={year} value={year}>{year}</Option>
          })}
        </Select>
      </div>
    </div>
  }

}

export default injectIntl(FullDateSelector)