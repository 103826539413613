import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import formatParams from '../utils/formatParams'
import getSearchTabs from '../utils/getSearchTabs'
import build from 'redux-object'
import config from '../config'
import { Link } from 'react-router-dom'
import { get } from '../redux/actions/apiActions'
import constructUrl from '../utils/constructUrl'
import debounce from 'lodash/debounce'
import uuid from 'uuid'
import Tooltip from 'antd/lib/tooltip'

var requestParams = {'page[size]': 5, 'page[number]': 1}

const getCardConfig = function (type) {
  let cardConfig = {}
  for (let key in config.pages) {
    if (config.pages[key].componentType !== 'card') continue
    
    if (config.pages[key].internalObjectName) {
      if (config.pages[key].internalObjectName === type) {
        cardConfig = config.pages[key]
        break
      }
      continue
    }

    if (config.pages[key].objectName !== type) continue
    cardConfig = config.pages[key]
    break
  }
  return cardConfig
}

class SearchResults extends PureComponent {

  constructor () {
    super()
    this.requestResults = debounce(this.requestResultsRaw.bind(this), 300)
  }

  componentWillMount () {
    this.requestResults(this.props.term)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.term === this.props.term) return
    this.requestResults(nextProps.term)
  }

  render () {
    const tabs = getSearchTabs(this.props.meta).filter(tab => tab.amount > 0)
    return <div className="SearchResults">
      {this.props.meta.total > 0
        ? this.props.rows.map((row, index) => this.renderChildren(row, index)).concat(
          this.props.rows.filter(row => Object.keys(getCardConfig(row.type + 'Draft')).length > 0)
            .map((row, index) => this.renderChildren({...row, type: row.type + 'Draft'}, index, uuid()))
        )
        : this.props.meta.total == null
          ? ''
          : <FormattedMessage id="search.no-results" />
      }
      <div className="SearchResults__amounts">
        {this.props.meta.total > 5
          ? tabs.map(tab => {
              const filter = tab.type === 'basis' ? '' : '?filter=' + tab.type
              return <Link key={tab.title} to={'/search/' + this.props.term + filter}>
                <div><FormattedMessage id={tab.title} />: {tab.amount}</div>
              </Link>
            })
          : null
        }
      </div>
    </div>
  }

  renderChildren (row, index, key = null) {
    const config = getCardConfig(row.type) || {}
    if (!config.title) return <div
      key={row.id}
      className="SearchResults__each"
    >
      <div className="SearchResults__each-type"><FormattedMessage id="error.undefined-type" /></div>
    </div>
    const title = config.getValue ? config.getValue(row) : null
    const url = config.url ? constructUrl(config.url, {guid: row.id}) : ''
    return (
      <Tooltip 
        placement="left" 
        title={row.slug ? row.slug : null} 
        key={!key ? row.id : key}
      >
        <Link
          to={url}
          className="SearchResults__each"
        >
          <div className="SearchResults__each-title">{title}</div>
          <div className="SearchResults__each-type"><FormattedMessage id={config.title || 'error.undefined-type'} /></div>
        </Link>
      </Tooltip>
    )
  }

  requestResultsRaw (term) {
    if (term == null || term.trim() === '') return
    this.props.dispatch(get('/search', {...requestParams, 'search[query]': term}))
  }

}

function mapStateToProps (state, props) {
  if (
    props.term == null ||
    state.data == null ||
    state.data.meta == null ||
    state.data.meta['/search'] == null
  ) return {rows: [], meta: {}}
  const requestString = formatParams({...requestParams, 'search[query]': props.term})
  let rows = []
  const results = state.data.meta['/search'][requestString]
  if (results == null || results.data == null) return {rows: [], meta: {}}
  results.data.forEach((object, index) => {
    let row = build(state.data, object.type, object.id, {includeType: true})
    row.type = object.type
    rows.push(row)
  })
  return {
    rows,
    meta: results.meta,
    loading: results.loading
  }
}

export default connect(mapStateToProps)(SearchResults)
