import notification from 'antd/lib/notification'
import Raven from 'raven-js'

const open = (text, props = {}) => {
  const type = props.type || 'open'
  notification[type]({
    className: 'Notification Notification__' + type,
    message: '',
    description: text || '',
    duration: (typeof props.duration === 'undefined') ? 4.5 : props.duration
  })
}

const Notification = {
  open: open,
  info: (text, props = {}) => { props.type = 'info'; open(text, props) },
  success: (text, props = {}) => { props.type = 'success'; open(text, props) },
  warning: (text, props = {}) => { props.type = 'warning'; open(text, props) },
  warn: (text, props = {}) => { props.type = 'warning'; open(text, props) },
  error: (text, props = {}) => {
    Raven.captureException(text)
    props.type = 'error'
    return open(text, props)
  }
}

export default Notification
