import formatDate from '../utils/formatDate'

export default {
  componentType: 'search',
  objectName: 'research',
  defaultSorting: '-created_at',
  url: '/search/?filter=research',
  title: 'researchTable',
  getUrl: data => '/research/' + data.id,
  table: [
    {
      title: 'research.name',
      width: 450,
      fixed: true,
      sortBy: 'name',
      getValue: data => data.name
    },
    {
      title: 'research.name-en',
      width: 250,
      fixed: true,
      sortBy: 'name_en',
      getValue: data => data.nameEn
    },
    {
      title: 'books.published',
      width: 150,
      sortBy: 'published_on_web.name',
      getValue: data => data.publishedOnWeb ? data.publishedOnWeb.name : null
    },
    {
      title: 'research.publishedAt',
      width: 120,
      sortBy: 'published_at',
      getValue: data => formatDate(data.publishedAt)
    },
    {
      title: 'individuals.table.createdAt',
      width: 120,
      sortBy: 'created_at',
      getValue: data => formatDate(data.createdAt)
    },
    {
      title: 'individuals.table.createdBy',
      width: 165,
      sortBy: 'created_by',
      filterBy: 'user?filter[created_type]=research',
      filterMode: 'multiple',
      getValue: data => data.createdBy
    }
  ]
}
