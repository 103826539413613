import React from 'react'
import jsonPointer from 'json-pointer'

import SwitchStyled from '../fieldsStyled/SwitchStyled'

import { editField } from '../redux/actions/editingCard'
import addData from '../utils/addData'

const StaticSwitch = ({
  label,
  fieldName,
  data,
  tab,
  dispatch,
  config,
  options
}) => {
  if (!tab || !tab.currentData) return null

  const getObjectPointer = () => {
    return '/' + config.objectName + '/' + data.id + '/attributes/' + fieldName
  }

  const getValue = () => {
    const objectPointer = getObjectPointer()
    if (!objectPointer) return null
    if (!jsonPointer.has(tab.currentData, objectPointer)) return null
    return jsonPointer.get(tab.currentData, objectPointer)
  }

  const setValue = e => {
    const objectPointer = getObjectPointer()
    if (!objectPointer) return
    dispatch(editField(tab.id, objectPointer, e.target.value))
  }

  return <SwitchStyled
    label={label}
    value={getValue()}
    options={options}
    style={{ float: 'left', marginBottom: '15px' }}
    onChange={setValue}
  />
}

export default addData(StaticSwitch)