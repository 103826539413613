import React from 'react'
import moment from 'moment'

export function formatDatePlain(date) {
  if (!date) return null
  return moment(date).format('DD.MM.YYYY HH:mm')
}

export default function (date) {
  if (!date) return null
  return <span>{formatDatePlain(date)}</span>
}
